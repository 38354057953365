import { FC } from 'react'
import { getCurrency } from '../../util'
import { useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { BaseChart } from '../../../components/BaseChart'

export const RevenueBySourceWidget: FC<{
  totalOrderValueByOrderSource: any
}> = (props) => {
  const { formatMessage, formatNumber } = useIntl()
  const currency = getCurrency()
  const title = formatMessage({ id: 'dashboard_revenueBySource_title' })
  const subtitle = formatMessage({ id: 'dashboard_revenueBySource_subtitle' })
  const helpText = formatMessage({ id: 'dashboard_revenueBySource_helpText' })

  const formatLabelAsMoney = (tooltipItem: any) => {
    return formatNumber(tooltipItem.parsed, {
      currency: currency,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  const foramtTickAsMoney = (tickValue: any) => {
    return formatNumber(+tickValue, {
      currency: currency,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <BaseChart
        options={{
          plugins: {
            legend: {
              position: 'right',
              align: 'start',
            },
          },
          responsive: true,
          scales: {
            x: { title: { text: 'Revenue' } },
            y: { type: 'linear', ticks: { callback: foramtTickAsMoney } },
          },
        }}
        data={{
          labels: ['Revenue'],
          datasets: props.totalOrderValueByOrderSource?.buckets.map(
            (bucket: any) => ({
              label: bucket.key,
              data: [bucket.sum.value / 100],
            })
          ),
        }}
        height={80}
        type="bar"
      ></BaseChart>
    </GenericCard>
  )
}
