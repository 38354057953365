import { Cancel, Edit } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  CardActions,
  CardActionsProps,
} from '@mui/material'
import {
  QueryObserverSuccessResult,
  UseMutationResult,
} from 'react-query/types'
import { DeliveryCancelDialog } from './DeliveryCancelDialog'
import { useState } from 'react'

/**
 *
 */
export interface DeliveryCardActionsProps extends CardActionsProps {
  delivery: QueryObserverSuccessResult<any, Error>
  cancel: UseMutationResult<any, unknown, any, unknown>
}

/**
 * Delivery Card Actions
 */
export const DeliveryCardActions: React.FC<DeliveryCardActionsProps> = (
  props
) => {
  const { orderId, dispatched } = props.delivery.data.data
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  /**
   * Determine the states of the delivery
   */
  const isDeliveryInCompletedState =
    props.delivery.data.data.status === 'COMPLETED' ||
    props.delivery.data.data.status === 'CANCELLED' ||
    props.delivery.data.data.status === 'RETURNED'

  return (
    <>
      {isCancelDialogOpen && (
        <DeliveryCancelDialog
          open
          onClose={() => setIsCancelDialogOpen(false)}
          delivery={props.delivery}
          cancel={props.cancel}
        />
      )}

      <CardActions>
        <ButtonGroup fullWidth>
          {!isDeliveryInCompletedState && (
            <Button
              color="error"
              startIcon={<Cancel />}
              onClick={() => setIsCancelDialogOpen(true)}
            >
              Cancel
            </Button>
          )}
          {dispatched.length === 0 && (
            <Button color="warning" startIcon={<Edit />}>
              Edit
            </Button>
          )}
          {orderId && <Button color="success">View Order</Button>}
        </ButtonGroup>
      </CardActions>
    </>
  )
}
