import { useQuery } from 'react-query'
import { useAuthStore } from './useAuthStore'
import { AuthToken } from './useAccessTokenRotate'
import { api } from '../services/api'

/**
 * Loght weight function that parses the data from a JWT token
 */
export const parseJwt = (token: string) => {
  return JSON.parse(atob(token.split('.')[1]))
}

/**
 * Helper to get the current time in seconds
 */
const now = () => new Date().getTime() / 1000

/**
 * @name useAccessToken
 * @description A React Query hook that proxies the access token from the store and refreshes it if required
 */
export const useAccessToken = () => {
  /**
   * Get the current token from the store
   */
  const [token, setAuth] = useAuthStore((t) => [t.token, t.setAuth])

  /**
   * Extract the contents of the JWT token
   */
  const jwt = token?.access_token ? parseJwt(token?.access_token) : undefined

  /**
   * Get the remaining seconds until the token expires
   */
  const remainingSeconds = jwt ? jwt.exp - now() : undefined

  /**
   * Create the query
   */
  const query = useQuery<AuthToken | undefined>({
    /**
     * Query key keeps track of the query so that we can auto invalid≠ate the query
     */
    queryKey: ['refresh_token', token?.access_token],

    /**
     * The initial state of the query should be whatever the local token state is, so we just proxy the result
     * and return the token even when the query is not enabled
     */
    initialData: token,

    /**
     * We only want to actually make the request if the token is expired
     */
    enabled: remainingSeconds !== undefined && remainingSeconds < 60,

    /**
     * Set the state time of the query to be 60 seconds before the token expires,
     * this way we will ensure that the first token set in the inital data is always
     * returned until closer to the time of expiration
     */
    staleTime:
      remainingSeconds !== undefined ? remainingSeconds - 60 : undefined,

    /**
     * The queryFn is the function that will be called when the query is enabled
     */
    queryFn: () =>
      api
        .post('oauth2/token', {
          client_id: 'ee17da2c-06ef-4f48-8c11-96d3350ea177',
          grant_type: 'refresh_token',
          refresh_token: token?.refresh_token,
        })
        .then((result) => result.data),
    /**
     * If we have a succesfull response where the value of the access token is different to the one we have persisted then we set that to the auth table
     */
    onSuccess: (data) =>
      data && data.access_token !== token?.access_token ? setAuth(data) : null,
  })

  /**
   * Return the proxied token from the query hook so that other hooks can depend on its state
   */
  return query.data
}
