import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { FC, SyntheticEvent, useState } from 'react'
import {
  DateTimePicker,
  GoogleAddressPicker,
  LocationsPicker,
} from '../Pickers'
import { MoneyTextField } from '../'
import { useNavigate, useParams } from 'react-router-dom'
import { MuiTelInput } from 'mui-tel-input'
import { useIntl } from 'react-intl'
import { AutoAwesome, Camera, KeyboardReturn } from '@mui/icons-material'
import { UseInfiniteQueryResult, UseMutationResult } from 'react-query/types'
import { HookArgs } from 'use-places-autocomplete'
import { LocationEntity } from '../../types'

export interface DeliveryCreateDialogProps extends DialogProps {
  onLocationsSearch: (query: string) => void
  locations: UseInfiniteQueryResult<any, unknown>
  createDelivery: UseMutationResult<any, unknown, any, unknown>
}

/**
 *
 */
export const DeliveryCreateDialog: FC<DeliveryCreateDialogProps> = (props) => {
  const intl = useIntl()
  const { resellerId } = useParams()
  const navigate = useNavigate()
  console.log('resellerId', resellerId)
  const maxAllowedDate = new Date(
    new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24 * 14
  )
  const [pickupLocation, setPickupLocation] = useState<
    LocationEntity | undefined
  >()
  const [dropoffLocation, setDropoffLocation] = useState<
    google.maps.places.PlaceResult | undefined
  >()
  const [deliveryMode, setDeliveryMode] = useState<'ASAP' | 'SCHEDULED'>('ASAP')
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)
  const [customerFirstname, setCustomerFirstname] = useState('')
  const [customerLastname, setCustomerLastname] = useState('')
  const [customerContactNumber, setCustomerContactNumber] = useState('')
  const [dropoffInstructions, setDropoffInstructions] = useState('')
  const [totalValue, setTotalValue] = useState<number | null>(0.01)
  const [packageSize, setPackageSize] = useState<string>('SMALL')
  const [proofRequired, setProofRequired] = useState(true)
  const [returnPackage, setReturnPackage] = useState(false)
  const [autoDispatchEnabled, setAutoDispatchEnabled] = useState(false)
  const [dropoffAddressComponents, setDropoffAddressComponents] = useState<
    any | undefined
  >(undefined)

  const updateAddressComponent = (key: string, value: string) => {
    setDropoffAddressComponents({
      ...dropoffAddressComponents,
      [key]: value,
    })
  }

  /**
   * Derived States
   */
  const isPickupLocationSelected = !!pickupLocation
  const isDropoffDetailsFilled =
    !!customerFirstname && !!customerLastname && !!customerContactNumber

  // Final state to progress to the next step
  const isCreateButtonDisabled = !pickupLocation || !isDropoffDetailsFilled

  /**
   * Extract some of the fields from the location record
   */
  const defaultPickupAutoCompleteOptions: HookArgs = {
    debounce: 250,
    // Each location get's it's own cache as results are location specific
    cacheKey: `pickup-location-${pickupLocation?.id}`,
    requestOptions: {
      ...(pickupLocation?.address?.latitude &&
        pickupLocation?.address?.longitude && {
          origin: new google.maps.LatLng(
            pickupLocation?.address?.latitude,
            pickupLocation?.address?.longitude
          ),
          locationBias: new google.maps.Circle({
            center: new google.maps.LatLng(
              pickupLocation?.address?.latitude,
              pickupLocation?.address?.longitude
            ),
            // 50km in meters
            radius: 100_000,
          }),
        }),
    },
  }

  /**
   * Handle the place selected event from the google address picker
   */
  const handleOnDropoffPlaceSelected = (
    place: google.maps.places.PlaceResult
  ) => {
    setDropoffLocation(place)
    setDropoffAddressComponents(getAddressComponentsFromPlace(place))
  }

  const onPickupLocationChange = (e: SyntheticEvent, value: LocationEntity) => {
    console.log(value.deliveryAutoDispatchEnabled)
    setAutoDispatchEnabled(value!.deliveryAutoDispatchEnabled!)
    setProofRequired(value!.deliveryDefaultProofRequired ?? false)
    setReturnPackage(value!.deliveryDefaultFailureAction === 'RETURN')
    setPickupLocation(value)
  }

  console.log({ autoDispatchEnabled, proofRequired, returnPackage })

  /**
   * Handle the create delivery action
   */
  const handleCreateDelivery = () => {
    props.createDelivery.mutate(
      {
        locationId: pickupLocation?.id,
        accountId: pickupLocation?.accountId,
        failureAction: returnPackage ? 'RETURN' : 'DISPOSE',
        proofRequired: proofRequired,
        autoDispatchEnabled: autoDispatchEnabled,
        deliveryMode,
        package: {
          totalValue: totalValue ? totalValue * 100 : 1,
          packageSize,
        },
        pickup: {
          address: pickupLocation?.address,
          contactName: pickupLocation?.name,
          contactPhone: pickupLocation?.phoneNumber,
          // @todo add instructions to the location record
          instructions: '',
        },
        dropoff: {
          address: {
            address: dropoffLocation?.formatted_address,
            googlePlaceId: dropoffLocation?.place_id,
            latitude: dropoffLocation?.geometry?.location?.lat(),
            longitude: dropoffLocation?.geometry?.location?.lng(),
            addressComponents: dropoffAddressComponents,
            // {
            //   city: dropoffLocation?.address_components?.find((c: any) =>
            //     c.types.includes('locality')
            //   )?.long_name,
            //   state: dropoffLocation?.address_components?.find((c: any) =>
            //     c.types.includes('administrative_area_level_1')
            //   )?.long_name,
            //   country: dropoffLocation?.address_components?.find((c: any) =>
            //     c.types.includes('country')
            //   )?.long_name,
            //   postalCode: dropoffLocation?.address_components?.find((c: any) =>
            //     c.types.includes('postal_code')
            //   )?.long_name,
            // },
          },
          contactName: `${customerFirstname} ${customerLastname}`,
          contactPhone: customerContactNumber,
          instructions: dropoffInstructions,
        },
        estimatedPickupTime:
          deliveryMode === 'SCHEDULED'
            ? scheduledDate?.toISOString()
            : undefined,
      },
      {
        onSuccess: (result) => {
          /**
           * Redirect to the delivery details page
           */
          navigate(result.data.id)
        },
      }
    )
  }

  return (
    <Dialog fullWidth scroll="paper" {...props}>
      <DialogTitle>Create Delivery</DialogTitle>
      <DialogContent>
        {/* Form Layout with Location Picker */}
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12}>
            <Divider>Pickup Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <LocationsPicker
                query={props.locations}
                onSearch={(query) => props.onLocationsSearch(query)}
                multiple={false}
                placeholder=""
                label=""
                onChange={onPickupLocationChange}
              />
              <FormHelperText>
                Address and Contact Information will be taken from the locaton
                record
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* Delivery Mode, ASAP vs Scheduled */}
          <Grid item xs={12}>
            <ButtonGroup fullWidth disabled={!isPickupLocationSelected}>
              <Button
                onClick={() => setDeliveryMode('ASAP')}
                variant={deliveryMode === 'ASAP' ? 'contained' : 'outlined'}
              >
                ASAP
              </Button>
              <Button
                onClick={() => setDeliveryMode('SCHEDULED')}
                variant={
                  deliveryMode === 'SCHEDULED' ? 'contained' : 'outlined'
                }
              >
                SCHEDULED
              </Button>
            </ButtonGroup>
          </Grid>
          {/* Scheduled Delivery Date */}
          {deliveryMode === 'SCHEDULED' && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DateTimePicker
                  label="Expected Pickup Time"
                  maxDate={maxAllowedDate}
                  closeOnSelect={false}
                  timezone={pickupLocation?.timezone ?? 'UTC'}
                  onChange={(value) => setScheduledDate(value)}
                  value={scheduledDate}
                  ampm={false}
                  disablePast
                  disabled={!isPickupLocationSelected}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <FormHelperText>
                  {pickupLocation?.timezone ?? 'UTC'} Timezone
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider>Dropoff Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    size="small"
                    value={customerFirstname}
                    disabled={!isPickupLocationSelected}
                    onChange={(e) => setCustomerFirstname(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    size="small"
                    value={customerLastname}
                    disabled={!isPickupLocationSelected}
                    onChange={(e) => setCustomerLastname(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <MuiTelInput
                    label={intl.formatMessage({ id: 'label_phone_number' })}
                    placeholder="e.g. +1 123-456-7890"
                    disabled={!isPickupLocationSelected}
                    value={customerContactNumber}
                    size="small"
                    onChange={(value: any) => setCustomerContactNumber(value)}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <GoogleAddressPicker
                  onPlaceSelected={handleOnDropoffPlaceSelected}
                  disabled={!isPickupLocationSelected}
                  autoCompleteProps={{
                    ...defaultPickupAutoCompleteOptions,
                  }}
                />
              </Grid>
              {/** Pickup Address Components */}
              {dropoffAddressComponents && (
                // Grid layout for the address components, which includes
                // all the google place components which include:
                // street address, apt, city, state, country, postal code
                <>
                  {/* House Number */}
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="House Number"
                        size="small"
                        value={dropoffAddressComponents.houseNo}
                        onChange={(e) =>
                          updateAddressComponent('houseNo', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  {/* Flat No */}
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Apt, Flat, Suite"
                        size="small"
                        value={dropoffAddressComponents.flatNo}
                        onChange={(e) =>
                          updateAddressComponent('flatNo', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Address Line 1"
                        size="small"
                        value={dropoffAddressComponents.addressLine1}
                        onChange={(e) =>
                          updateAddressComponent('addressLine1', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Address Line 2"
                        size="small"
                        value={dropoffAddressComponents.addressLine2}
                        onChange={(e) =>
                          updateAddressComponent('addressLine2', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="City"
                        size="small"
                        value={dropoffAddressComponents.city}
                        onChange={(e) =>
                          updateAddressComponent('city', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="State"
                        size="small"
                        value={dropoffAddressComponents.state}
                        onChange={(e) =>
                          updateAddressComponent('state', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Country"
                        size="small"
                        value={dropoffAddressComponents.country}
                        onChange={(e) =>
                          updateAddressComponent('country', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Postal Code"
                        size="small"
                        value={dropoffAddressComponents.postalCode}
                        onChange={(e) =>
                          updateAddressComponent('postalCode', e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {/* Driver Instructions */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Dropoff Instructions"
                    placeholder='These notes are typically for the driver, e.g. "Ring the doorbell"'
                    size="small"
                    disabled={!isPickupLocationSelected}
                    multiline
                    rows={2}
                    value={dropoffInstructions}
                    onChange={(e) => setDropoffInstructions(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Package Details */}
          <Grid item xs={12}>
            <Divider>Package Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <MoneyTextField
                label="Package Value"
                size="small"
                value={totalValue}
                onChange={(e) => setTotalValue(Number(e.target.value))}
                disabled={!isPickupLocationSelected}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth disabled={!isPickupLocationSelected}>
              <InputLabel>Package size</InputLabel>
              <Select
                label="Package Size"
                value={packageSize}
                onChange={(e) => setPackageSize(e.target.value)}
              >
                <MenuItem value="SMALL">Small</MenuItem>
                <MenuItem value="MEDIUM">Medium</MenuItem>
                <MenuItem value="LARGE">Large</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* List of available options */}
          <List
            sx={{ width: '100%' }}
            subheader={<ListSubheader>Options</ListSubheader>}
          >
            <ListItem disabled={!isPickupLocationSelected}>
              <ListItemIcon>
                <Camera />
              </ListItemIcon>
              <ListItemText
                primary="Delivery Proof"
                secondary="Require delivery proof from the delivery partner"
              />
              <Switch
                disabled={!isPickupLocationSelected}
                checked={proofRequired}
                onChange={(e, checked) => setProofRequired(checked)}
              />
            </ListItem>
            <ListItem disabled={!isPickupLocationSelected}>
              <ListItemIcon>
                <KeyboardReturn />
              </ListItemIcon>
              <ListItemText
                primary="Return Package"
                secondary="Request the package to be returned if the delivery fails, this may incur additional charges"
              />
              <Switch
                disabled={!isPickupLocationSelected}
                checked={returnPackage}
                onChange={(e, checked) => setReturnPackage(checked)}
              />
            </ListItem>
            {/* AutoDispatch */}
            <ListItem disabled={!isPickupLocationSelected}>
              <ListItemIcon>
                <AutoAwesome />
              </ListItemIcon>
              <ListItemText
                primary="Auto Dispatch"
                secondary="Automatically dispatch the best quote that matches the locations delivery settings"
              />
              <Switch
                disabled={!isPickupLocationSelected}
                checked={autoDispatchEnabled}
                onChange={(e, checked) => setAutoDispatchEnabled(checked)}
              />
            </ListItem>
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={props.createDelivery.isLoading}
          onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}
        >
          Cancel
        </Button>
        <Button
          color="success"
          disabled={isCreateButtonDisabled || props.createDelivery.isLoading}
          onClick={handleCreateDelivery}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getAddressComponentsFromPlace = (
  place: google.maps.places.PlaceResult
) => {
  const components = place.address_components || []

  const getAddressComponent = (type: string): string => {
    const component = components.find((c) => c.types.includes(type))
    return component ? component.long_name : ''
  }

  return {
    area:
      getAddressComponent('sublocality') || getAddressComponent('neighborhood'),
    country: getAddressComponent('country'),
    flatNo: getAddressComponent('subpremise'),
    city: getAddressComponent('locality'),
    postalCode: getAddressComponent('postal_code'),
    houseNo: getAddressComponent('street_number'),
    addressLine1: getAddressComponent('route'),
    addressLine2: getAddressComponent('premise'),
    state: getAddressComponent('administrative_area_level_1'),
  }
}
