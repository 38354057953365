import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { useAuthStore } from './useAuthStore'
import { useAuditOptions } from './useLocation'

export interface Application {
  id: string
  name: string
  description: string
  redirectUris: string[]
  grantTypes: string[]
  scopes: string[]
  image?: string
  category?: string
}

export interface UseApplicationByIdResult {
  data: Application
}

export const useAccountLocationApplications = (
  accountId: string,
  locationId: string,
  options: any = {}
) => {
  const token = useAccessToken()
  return useInfiniteQuery(
    ['accounts', accountId, 'locations', locationId, 'applications'],
    ({ signal }) =>
      api
        .get(`v1/accounts/${accountId}/locations/${locationId}/applications`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      enabled: !!token?.access_token,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      ...options,
    }
  )
}
export const useDeleteApplicationConnectionById = () => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const client = useQueryClient()
  return useMutation({
    mutationFn: (options: { locationId: string; appId: string }) => {
      return api
        .delete(
          `v1/accounts/${accountId}/locations/${options.locationId}/applications/${options.appId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then((res) => res.data)
    },
    onSuccess: (data, variables) => {
      void client.refetchQueries([
        'accounts',
        accountId,
        'locations',
        variables.locationId,
        'applications',
      ])
    },
  })
}

export const useApplicationById = (id: any, options?: any) => {
  const token = useAccessToken()
  return useQuery<UseApplicationByIdResult>(
    ['v1', 'applications', id],
    ({ signal }) =>
      api
        .get(`v1/applications/${id}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      enabled: !!token?.access_token,
      ...options,
    }
  )
}

/**
 *
 */
export const useUserApplications = () => {
  const token = useAccessToken()
  return useQuery(
    ['v1', 'users', 'me', 'applications'],
    ({ signal }) =>
      api
        .get(`v1/users/me/applications`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      enabled: !!token?.access_token,
    }
  )
}

export const useUserApplicationById = (id: any, options?: any) => {
  const token = useAccessToken()
  return useQuery(
    ['v1', 'users', 'me', 'applications', id],
    ({ signal }) =>
      api
        .get(`v1/users/me/applications/${id}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      enabled: !!token?.access_token,
      ...options,
    }
  )
}

export const useUserApplicationAudits = (
  id: any,
  options?: any,
  filter?: useAuditOptions
) => {
  const token = useAccessToken()
  return useInfiniteQuery(
    ['v1', 'users', 'me', 'applications', id, 'audits', filter],
    ({ signal, pageParam }) =>
      api
        .get(`v1/users/me/applications/${id}/audits`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
          params: { nextPageKey: pageParam },
        })
        .then(({ data }) => data),
    {
      enabled: !!token?.access_token,
      getNextPageParam: (lastPage) => lastPage.data?.nextPageKey,
      ...options,
    }
  )
}
