import { FC, useState } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Avatar,
  Chip,
  Box,
  Tooltip,
} from '@mui/material'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { FormattedDate } from 'react-intl'
import { CheckCircleOutline, PendingOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export interface UserApplicationsTableProps {
  applications: UseInfiniteQueryResult<any, any>
  onRowClick?: (delivery: any) => void
}

export const UserApplicationsTable: FC<UserApplicationsTableProps> = (
  props
) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  /**
   * Get the current page of deliveries
   */
  const totalCount = props.applications.data?.pages.reduce(
    (acc, page) => acc + page.data.length,
    0
  )
  const lastPageidx = (props.applications.data?.pages.length ?? 1) - 1
  const hasNextPage =
    props.applications.data?.pages[lastPageidx]?.hasNextPage ?? false
  const applications: any[] = props.applications.data?.pages[page]?.data ?? []

  const onPagePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    targetPage: number
  ) => {
    // If the page is not in the cache, fetch it
    if (!props.applications.data?.pages[targetPage]) {
      void props.applications.fetchNextPage().then(() => {
        setPage(targetPage)
      })
    } else {
      setPage(targetPage)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '5vw' }}>Logo</TableCell>
            <TableCell style={{ width: '5vw' }}>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell style={{ width: '5vw' }}>Enabled Countries</TableCell>
            <TableCell style={{ width: '5vw' }}>Category</TableCell>
            <TableCell style={{ width: '10vw' }}>Last Updated</TableCell>
            <TableCell style={{ width: '5vw' }}>Published</TableCell>
            {/* <TableCell style={{ width: '5vw' }}>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((app: any, index: number) => {
            return (
              <TableRow onClick={() => navigate(app.id)}>
                <TableCell>
                  {(app.image ?? app.imageUrl) && (
                    <Avatar
                      src={app.image ?? app.imageUrl}
                      variant="square"
                      title={app.name}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <CopyToClipboard value={app.id} title={`${app.id}`}>
                    {app.id.slice(0, 8)}
                  </CopyToClipboard>
                </TableCell>
                <TableCell>{app.name}</TableCell>
                <TableCell>
                  <Box sx={{ pr: 2, fontWeight: 'bold', display: 'flex' }}>
                    {app.allowedCountries?.map((country: string) => (
                      <Chip label={country} sx={{ mr: 1 }} />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Chip label={app.category} sx={{ width: '100%' }} />
                </TableCell>
                <TableCell>
                  <FormattedDate
                    value={new Date(app.updatedAt || app.createdAt)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                </TableCell>
                <TableCell align="center">
                  {app.isPublic ? (
                    <Tooltip title="The application is available to all users">
                      <CheckCircleOutline color="success" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="The application is only available under accounts and locations owned by you">
                      <PendingOutlined />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        {/* If there is more than one page show the pagination */}
        {props.applications.data?.pages[0].data.length >= rowsPerPage && (
          <TableFooter>
            <TableRow>
              <TablePagination
                component={'td'}
                colSpan={6}
                page={page}
                rowsPerPage={rowsPerPage}
                count={hasNextPage ? totalCount + rowsPerPage : totalCount}
                rowsPerPageOptions={[25]}
                onPageChange={onPagePage}
                onRowsPerPageChange={(event) =>
                  setRowsPerPage(+event.target.value)
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
