import { useEffect, useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  PageNavigationView,
  MenuSyncDialog,
  ItemsWarningDialog,
  MenuSwitch,
} from '../../../../../components'
import { MenuItems } from './Items'
import { MenuCategory } from './Category'
import { MenuModifiers } from './Modifiers'
import { MenuModifierGroups } from './ModifierGroups'
import { useIntl } from 'react-intl'
import { getLocationQueryById, useMenuById } from '../../../../../hooks'
import { ArrowBack } from '@mui/icons-material'

export function MappingMenu() {
  const intl = useIntl()
  const [currentTab, setCurrentTab] = useState(0)
  const { locationId, menuId, data } = useParams()
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const menus = useMenuById(locationId!, menuId!)
  const menuName = menus.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    { label: menuName, path: `` },
  ]
  const [syncDialogId, setSyncDialogId] = useState<string | undefined>(
    undefined
  )
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (data === 'categories') {
      setCurrentTab(0)
    } else if (data === 'items') {
      setCurrentTab(1)
    } else if (data === 'modifierGroups') {
      setCurrentTab(2)
    } else {
      setCurrentTab(3)
    }
  }, [data])
  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 && setShowWarningDialog(true)
    setSyncDialogId(undefined)
  }
  const onCancelItemsWarning = () => {
    setShowWarningDialog(false)
    setDataAfterPublish(undefined)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageNavigationView navigationPages={navigationPages} />
        <MenuSwitch version="v1" />
      </Box>
      <Tabs value={currentTab}>
        <Tab
          value={'back'}
          icon={<ArrowBack />}
          sx={{ textTransform: 'none', minWidth: 0 }}
          onClick={() => navigate(`/app/locations/${locationId}/menus`)}
        />
        <Tab
          label="Categories"
          value={0}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/categories`, {
              replace: true,
            })
          }
        />
        <Tab
          label="Items"
          value={1}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/items`, {
              replace: true,
            })
          }
        />
        <Tab
          label="Modifier Groups"
          value={2}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(
              `/app/locations/${locationId}/menu/${menuId}/modifierGroups`,
              { replace: true }
            )
          }
        />
        <Tab
          label="Modifiers"
          value={3}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`, {
              replace: true,
            })
          }
        />
        <Tab
          label="Publish"
          value={'publish'}
          sx={{ textTransform: 'none', marginLeft: 'auto' }}
          onClick={() => setSyncDialogId(menuId)}
        />
      </Tabs>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {currentTab === 0 && <MenuCategory />}
        {currentTab === 1 && <MenuItems />}
        {currentTab === 2 && <MenuModifierGroups />}
        {currentTab === 3 && <MenuModifiers />}
      </Box>
      {syncDialogId && (
        <MenuSyncDialog
          menuId={syncDialogId}
          onClose={handleOnClosePublishDialog}
        />
      )}
      {showWarningDialog && (
        <ItemsWarningDialog
          dataAfterPublish={dataAfterPublish}
          onCancelItemsWarning={onCancelItemsWarning}
        />
      )}
    </>
  )
}
