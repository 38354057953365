import { FC, useState } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { FormattedRelativeTime } from '../FormattedRelativeTime'
import {
  Typography,
  Tooltip,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Theme,
  useTheme,
  AvatarGroup,
  TableFooter,
  TablePagination,
  Paper,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { DeliveryStatusLabel } from './DeliveryStatusLabel'
import { BorderLinearProgress } from './BorderLinearProgress'
import { FormattedMoney } from '../FormattedMoney'
import { DeliveryDispatchedAvatar } from './DeliveryDispatchedAvatar'

/**
 * Helper function to return the status color based on the delivery status and theme
 */
const getStatusColorFromDelivery = (theme: Theme, delivery: any) => {
  switch (delivery.status) {
    case 'CREATED':
      return theme.palette.grey[300]
    case 'IN_PROGRESS':
      return theme.palette.success.main
    case 'COMPLETED':
      return theme.palette.success.main
    case 'RETURNED':
      return theme.palette.error.main
    case 'CANCELLED':
      return theme.palette.error.main
    default:
      return theme.palette.warning.main
  }
}

export const getProgressFromDelivery = (delivery: any) => {
  const deliveryDriverStatusToPercentageMap: Record<string, number> = {
    UNASSIGNED: 0,
    ASSIGNED: 10,
    ON_ROUTE_TO_PICKUP: 20,
    AT_PICKUP: 40,
    ON_ROUTE_TO_DROP_OFF: 60,
    AT_DROP_OFF: 80,
    RETURNING: 90,
    COMPLETED: 100,
    CANCELLED: 100,
    RETURNED: 100,
  }

  /**
   * Find the highest percentage from the dispatched deliveries
   */
  const progress = delivery.dispatched.reduce(
    (acc: number, dispatched: any) => {
      return Math.max(
        acc,
        deliveryDriverStatusToPercentageMap[dispatched.driverStatus]
      )
    },
    0
  )

  return progress
}

export interface DeliveriesTableProps {
  deliveries: UseInfiniteQueryResult<any, Error>
  onRowClick?: (delivery: any) => void
}

export const DeliveriesTable: FC<DeliveriesTableProps> = (props) => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  /**
   * Get the current page of deliveries
   */
  const totalCount = props.deliveries.data?.pages.reduce(
    (acc, page) => acc + page.data.length,
    0
  )
  const lastPageidx = (props.deliveries.data?.pages.length ?? 1) - 1
  const hasNextPage =
    props.deliveries.data?.pages[lastPageidx]?.hasNextPage ?? false
  const deliveries: any[] = props.deliveries.data?.pages[page]?.data ?? []

  const onPagePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    targetPage: number
  ) => {
    // If the page is not in the cache, fetch it
    if (!props.deliveries.data?.pages[targetPage]) {
      void props.deliveries.fetchNextPage().then(() => {
        setPage(targetPage)
      })
    } else {
      setPage(targetPage)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Pickup / Dropoff</TableCell>
            <TableCell>Schedule</TableCell>
            <TableCell>Package Value</TableCell>
            <TableCell>Dispatched</TableCell>
            <TableCell>Dispatch Progress</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deliveries.map((delivery) => (
            <TableRow
              key={delivery.id}
              onClick={() => props.onRowClick && props.onRowClick(delivery)}
              sx={{}}
            >
              <TableCell
                sx={{
                  // The left side of this cell should have a green broder indicating the status
                  border: 0,
                  borderLeft: '4px solid',
                  borderColor: getStatusColorFromDelivery(theme, delivery),
                }}
              >
                <DeliveryStatusLabel status={delivery.status} />
              </TableCell>
              <TableCell>
                <Box sx={{ lineHeight: 1.5 }}>
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <LocationOnIcon fontSize="small" />
                    <strong>Pickup: </strong>
                    <Tooltip title={delivery.pickup.address.address}>
                      <Typography variant="caption" noWrap>
                        {delivery.pickup.address.address}
                      </Typography>
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      mt: 0.5,
                    }}
                  >
                    <LocationOnIcon fontSize="small" />
                    <strong>Dropoff: </strong>
                    <Tooltip title={delivery.dropoff.address.address}>
                      <Typography variant="caption" noWrap>
                        {delivery.dropoff.address.address}
                      </Typography>
                    </Tooltip>
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ lineHeight: 1.5 }}></Box>
                <Typography variant="body2">
                  {delivery.deliveryMode === 'SCHEDULED' ? (
                    <>
                      {delivery.estimatedPickupTime && (
                        <Typography
                          variant="body2"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <strong>Pickup in: </strong>
                          <Tooltip title={delivery.estimatedPickupTime}>
                            <Typography variant="caption" noWrap>
                              <FormattedRelativeTime
                                value={delivery.estimatedPickupTime}
                              />
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                      {delivery.estimatedDropoffTime && (
                        <Typography
                          variant="body2"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            mt: 0.5,
                          }}
                        >
                          <strong>Dropoff in: </strong>
                          <Tooltip title={delivery.estimatedDropoffTime}>
                            <Typography variant="caption" noWrap>
                              <FormattedRelativeTime
                                value={delivery.estimatedDropoffTime}
                              />
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                    </>
                  ) : (
                    'ASAP'
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <FormattedMoney
                    value={delivery.package.totalValue / 100}
                    currency={delivery.currency ?? 'USD'}
                    decimalValue={true}
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <AvatarGroup max={3} variant="circular">
                  {delivery.dispatched.map((dispatched: any) => (
                    <DeliveryDispatchedAvatar
                      delivery={delivery}
                      key={dispatched.dispatchedId}
                      dispatched={dispatched}
                    />
                  ))}
                </AvatarGroup>
              </TableCell>
              <TableCell>
                <BorderLinearProgress
                  value={getProgressFromDelivery(delivery)}
                  variant="determinate"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* If there is more than one page show the pagination */}
        {props.deliveries.data?.pages[0].data.length >= rowsPerPage && (
          <TableFooter>
            <TableRow>
              <TablePagination
                component={'td'}
                colSpan={6}
                page={page}
                rowsPerPage={rowsPerPage}
                count={hasNextPage ? totalCount + rowsPerPage : totalCount}
                rowsPerPageOptions={[25]}
                onPageChange={onPagePage}
                onRowsPerPageChange={(event) =>
                  setRowsPerPage(+event.target.value)
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
