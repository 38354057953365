import { Box } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  MenuModifierCreateEntity,
  MenuModifierPatchEntity,
} from '../../../../../../../types'
import {
  getLocationQueryById,
  useMenuById,
  useMenuModifierById,
  useMenuModifierUpdate,
} from '../../../../../../../hooks'
import { PageNavigationView } from '../../../../../../../components'
import { defaultMenuModifierState } from '../../../utils'
import { ModifierEditForm } from '../../../../../../../components/ItemEditForm/ModifierEditForm'

export function MenuModifierUpsert() {
  const navigate = useNavigate()
  const { locationId, menuId, itemId, modifierGroupId, modifierId } =
    useParams()
  const isMenuV2 = window.location.pathname.includes('menus')
  const modifierUpdate = useMenuModifierUpdate(
    locationId!,
    menuId!,
    modifierId!
  )
  const modifier = useMenuModifierById(locationId!, menuId!, modifierId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.data) {
        let existingData = data.data
        existingData['price'] = data.data?.price! / 100
        existingData['inStorePrice'] = data.data?.inStorePrice! / 100
        setMenuModifierState(existingData)
      }
    },
  })
  const [modifierState, setMenuModifierState] = useState<
    MenuModifierCreateEntity | MenuModifierPatchEntity
  >(modifier?.data?.data ?? defaultMenuModifierState)
  const handleNavigation = () => {
    itemId && modifierGroupId
      ? navigate(
          `/app/locations/${locationId}/menu/${menuId}/item/${itemId}/modifier-groups/${modifierGroupId}`
        )
      : navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`)
  }
  const handleSubmitChange = async (data: any) => {
    const updatedState = { ...data }

    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = Math.round(parseFloat(data.price) * 100)
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = Math.round(
        parseFloat(data.inStorePrice) * 100
      )
    }
    modifierUpdate.mutate(updatedState, {
      onSuccess: () => {
        handleNavigation()
      },
    })
  }

  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  // fetch Menu data
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const menuName = menuData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}`
        : `/app/locations/${locationId}/menu/${menuId}/modifiers`,
    },
    { label: modifierState?.name, path: `` },
  ]

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <Box sx={{ p: 3 }}>
        <ModifierEditForm
          modifierState={modifierState}
          handleModifierUpdate={handleSubmitChange}
          handleNavigation={handleNavigation}
        />
      </Box>
    </>
  )
}
