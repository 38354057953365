import {
  AccessTime,
  ExpandLess,
  ExpandMore,
  LocationOn,
  Refresh,
  Store,
} from '@mui/icons-material'
import {
  Box,
  CardContent,
  CardContentProps,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  QueryObserverSuccessResult,
  UseMutationResult,
} from 'react-query/types'
import { DeliveryDispatchedList } from './DeliveryDispatchedList'
import { DeliveryQuoteList } from './DeliveryQuoteList'
import { useEffect, useState } from 'react'

/**
 *
 */
export interface DeliveryCardContentProps extends CardContentProps {
  delivery: QueryObserverSuccessResult<any, Error>
  cancel: UseMutationResult<any, unknown, any, unknown>
  refresh: UseMutationResult<any, unknown, any, unknown>
  openQuoteDispatchDialog: (quote: any) => void
}

/**
 * Delivery Card Actions
 */
export const DeliveryCardContent: React.FC<DeliveryCardContentProps> = (
  props
) => {
  const [isQuotesExpanded, setQuoteExpended] = useState(true)
  const [isDispatchedExpanded, setDispatchedExpended] = useState(true)
  const {
    pickup,
    dropoff,
    dispatched,
    estimatedPickupTime,
    estimatedDropoffTime,
  } = props.delivery.data.data

  // close the quotes when the delivery is dispatched
  useEffect(() => {
    if (dispatched.length > 0) {
      setQuoteExpended(false)
    }
  }, [dispatched])

  const handleRefreshQuotes = () => {
    props.refresh.mutate({}, { onSuccess: () => props.delivery.refetch() })
  }

  return (
    <CardContent>
      {/* Pickup & Dropoff Location */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          >
            <Store sx={{ mr: 1 }} />
            {pickup.contactName}
          </Typography>
          <List disablePadding>
            <ListItem>
              <ListItemText
                primary={pickup.address.address}
                secondary={pickup.contactPhone}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          >
            <LocationOn sx={{ mr: 1 }} />
            {dropoff.contactName}
          </Typography>
          <List disablePadding>
            <ListItem>
              <ListItemText
                primary={dropoff.address.address}
                secondary={dropoff.contactPhone}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {dispatched?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Divider variant="middle">
            Dispatched
            <IconButton
              onClick={() => setDispatchedExpended(!isDispatchedExpanded)}
            >
              {isDispatchedExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Divider>
          <Collapse in={isDispatchedExpanded} unmountOnExit>
            <DeliveryDispatchedList
              dense
              delivery={props.delivery}
              disabled={props.delivery.isLoading}
            />
          </Collapse>
        </Box>
      )}

      <Box>
        <Divider variant="middle">
          Qoutes
          <IconButton onClick={() => setQuoteExpended(!isQuotesExpanded)}>
            {isQuotesExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Divider>
        <Collapse in={isQuotesExpanded} unmountOnExit>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <IconButton onClick={handleRefreshQuotes}>
                <Refresh />
              </IconButton>
            </Grid>
          </Grid>
          <DeliveryQuoteList
            dense
            delivery={props.delivery}
            disabled={props.refresh.isLoading}
            onQuoteSelect={props.openQuoteDispatchDialog}
          />
        </Collapse>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          Delivery Window
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemAvatar sx={{ minWidth: 40 }}>
              <AccessTime />
            </ListItemAvatar>
            <ListItemText
              primary={'Est Pickup Time'}
              secondary={
                estimatedPickupTime
                  ? new Date(estimatedPickupTime).toLocaleString()
                  : '-'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar sx={{ minWidth: 40 }}>
              <AccessTime />
            </ListItemAvatar>
            <ListItemText
              primary={'Est Delivery Time'}
              secondary={
                estimatedDropoffTime
                  ? new Date(estimatedDropoffTime).toLocaleString()
                  : '-'
              }
            />
          </ListItem>
        </List>
      </Box>
    </CardContent>
  )
}
