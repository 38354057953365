import { Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import {
  MenuCategoryCreateEntity,
  MenuCategoryPatchEntity,
} from '../../../../../../../types'
import {
  getLocationQueryById,
  useCategoryById,
  useCategoryCreate,
  useCategoryUpdate,
  useMenuById,
} from '../../../../../../../hooks'
import {
  AppLoader,
  CommonTextField,
  PageNavigationView,
} from '../../../../../../../components'
import { useIntl } from 'react-intl'
import {
  defaultCategoryState,
  extractCategoryCreatePayload,
  extractCategoryPatchPayload,
  validateCategoryEntity,
} from '../../../utils'
import { toast } from 'react-toastify'

export function MenuCategoryUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, menuId, categoryId } = useParams()
  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const menus = useMenuById(locationId!, menuId!)
  const menuName = menus.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: `/app/locations/${locationId}/menu/${menuId}/categories`,
    },
    { label: 'Categories', path: `` },
  ]
  const [menuCategoryState, setMenuCategoryState] = useState<
    MenuCategoryCreateEntity | MenuCategoryPatchEntity
  >(defaultCategoryState)
  const isValid = useMemo(
    () => validateCategoryEntity(menuCategoryState),
    [menuCategoryState]
  )
  const categoryCreate = useCategoryCreate(locationId!, menuId!)
  const categoryUpdate = useCategoryUpdate(locationId!, menuId!, categoryId!)
  const category = useCategoryById(locationId, menuId, categoryId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setMenuCategoryState(data.data)
    },
  })
  const handleNameChange = (event: any) => {
    setMenuCategoryState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleShowOnlineChange = (event: any) => {
    setMenuCategoryState((state) => ({
      ...state,
      showOnline: event.target.checked,
    }))
  }
  const handleDescriptionChange = (event: any) => {
    setMenuCategoryState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleSubmitChange = async () => {
    categoryId
      ? categoryUpdate.mutate(extractCategoryPatchPayload(menuCategoryState), {
          onSuccess: () => {
            navigate(`/app/locations/${locationId}/menu/${menuId}/categories`)
          },
        })
      : categoryCreate.mutate(extractCategoryCreatePayload(menuCategoryState), {
          onSuccess: () => {
            navigate(`/app/locations/${locationId}/menu/${menuId}/categories`)
            toast.success('Category Created')
          },
        })
  }
  if (
    categoryCreate.isLoading ||
    categoryUpdate.isLoading ||
    category.isFetching ||
    category.isLoading
  ) {
    return <AppLoader />
  }
  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {categoryId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {intl.formatMessage({ id: 'label_category' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() =>
                navigate(
                  `/app/locations/${locationId}/menu/${menuId}/categories`
                )
              }
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              disabled={!isValid}
              onClick={handleSubmitChange}
              color="success"
              sx={{ ml: 2 }}
            >
              {categoryId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={12} sm={12} lg={10}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={menuCategoryState.name || ''}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={2}>
            <Checkbox
              checked={menuCategoryState.showOnline!}
              onChange={handleShowOnlineChange}
            />
            showOnline
          </Grid>
          <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={menuCategoryState.description || ''}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
