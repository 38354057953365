import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TabNavigation, PageNavigationView } from '../../../components'
import { Box } from '@mui/material'
import { LocationOverview } from './Overview'
import { MappingCatalogLocation } from '../CardSelect/CatalogMapping'
import { Menus } from '../CardSelect/Menu'
import { ApplicationsPage } from '../Apps'
import { AnalyticsMapping } from '../Analytics'
import { getLocationQueryById } from '../../../hooks'
import { useIntl } from 'react-intl'
import { AuditLogs } from '../Audits'
import { useResellerAccountsById } from '../../../hooks/useResellerAccounts'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export function MappingLocation() {
  const intl = useIntl()
  /**
   * Tabs for location view
   */
  const tabs = [
    intl.formatMessage({ id: 'label_analytics' }),
    intl.formatMessage({ id: 'label_catalog' }),
    intl.formatMessage({ id: 'label_menus' }),
    intl.formatMessage({ id: 'label_apps' }),
    intl.formatMessage({ id: 'label_settings' }),
    intl.formatMessage({ id: 'label_audits' }),
  ] as const

  const [currentTab, setCurrentTab] = useState(0)
  const { resellerId, accountId, locationId, data } = useParams()
  const selectedLocation = getLocationQueryById(locationId!, {
    refetchOnMount: 'always',
  })
  const navigate = useNavigate()
  // fetching the location by id
  const locationName = selectedLocation.data?.data?.name
  const accountById: any = accountId
    ? useResellerAccountsById(resellerId!, accountId!)
    : null
  const accountName: string = accountById?.data?.name
  let breadcrumbs: Breadcrumb[] = resellerId
    ? accountId
      ? [
          { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
          { title: 'Accounts', path: `/app/resellers/${resellerId}/accounts/` },
          {
            title: accountName!,
            path: `/app/resellers/${resellerId}/accounts/${accountId}`,
          },
          {
            title: 'Locations',
            path: `/app/resellers/${resellerId}/accounts/${accountId}/locations`,
          },
          { title: locationName!, path: '' },
        ]
      : [
          { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
          {
            title: 'Locations',
            path: `/app/resellers/${resellerId}/locations`,
          },
          { title: locationName!, path: '' },
        ]
    : [
        { title: 'Locations', path: '/app/locations' },
        {
          title: locationName!,
          path: `/app/locations/${locationId}/analytics`,
        },
      ]
  useEffect(() => {
    if (data === 'all') {
      setCurrentTab(4)
    } else if (data === 'catalog') {
      setCurrentTab(1)
    } else if (data === 'menus') {
      setCurrentTab(2)
    } else if (data === 'apps') {
      setCurrentTab(3)
    } else if (data === 'audits') {
      setCurrentTab(5)
    } else {
      setCurrentTab(0)
    }
  }, [data])
  const handleTabChange = (newValue: any) => {
    setCurrentTab(newValue)
    switch (newValue) {
      case 1:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/catalog/categories`
              : `/app/resellers/${resellerId}/locations/${locationId}/catalog/categories`
            : `/app/locations/${locationId}/catalog/categories`,
          {
            replace: true,
          }
        )
        break
      case 2:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/menus`
              : `/app/resellers/${resellerId}/locations/${locationId}/menus`
            : `/app/locations/${locationId}/menus`,
          {
            replace: true,
          }
        )
        break
      case 3:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/apps`
              : `/app/resellers/${resellerId}/locations/${locationId}/apps`
            : `/app/locations/${locationId}/apps`,
          {
            replace: true,
          }
        )
        break
      case 4:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/all`
              : `/app/resellers/${resellerId}/locations/${locationId}/all`
            : `/app/locations/${locationId}/all`,
          {
            replace: true,
          }
        )
        break
      case 5:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/audits`
              : `/app/resellers/${resellerId}/locations/${locationId}/audits`
            : `/app/locations/${locationId}/audits`,
          {
            replace: true,
          }
        )
        break
      default:
        navigate(
          resellerId
            ? accountId
              ? `/app/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/analytics`
              : `/app/resellers/${resellerId}/locations/${locationId}/analytics`
            : `/app/locations/${locationId}/analytics`,
          {
            replace: true,
          }
        )
        break
    }
  }
  return (
    <PageContainer title={locationName} breadcrumbs={breadcrumbs}>
      <Box sx={{ position: 'sticky', top: 50, zIndex: 999 }}>
        <TabNavigation
          currentTab={currentTab}
          setCurrentTab={handleTabChange}
          navigate={navigate}
          path={
            resellerId
              ? accountId
                ? `/app/resellers/${resellerId}/accounts/${accountId}/locations`
                : `/app/resellers/${resellerId}/locations`
              : `/app/locations/`
          }
          labels={tabs}
        />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {currentTab === 0 && <AnalyticsMapping />}
        {currentTab === 1 && <MappingCatalogLocation />}
        {currentTab === 2 && <Menus />}
        {currentTab === 3 && <ApplicationsPage />}
        {currentTab === 4 && <LocationOverview />}
        {currentTab === 5 && <AuditLogs />}
      </Box>
    </PageContainer>
  )
}
