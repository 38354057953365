import { Avatar } from '@mui/material'
import { AdvancedMarker } from '@vis.gl/react-google-maps'
import { FC } from 'react'
import { deepOrange } from '@mui/material/colors'

/**
 * Marker that is used to deplay the drivers location on the map
 */
export const DeliveryMapDriverMarker: FC<{
  lat: number
  lng: number
}> = (props) => {
  return (
    <>
      <AdvancedMarker position={{ lat: props.lat, lng: props.lng }}>
        <Avatar variant="square" sx={{ bgcolor: deepOrange[500] }}>
          D
        </Avatar>
      </AdvancedMarker>
    </>
  )
}
