import { useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import { CircularProgress, Alert, Box, useMediaQuery } from '@mui/material'
import {
  useResellerDeliveryById,
  useResellerDeliveryCancelMutation,
  useResellerDeliveryDispatchMutation,
  useResellerDeliveryRefreshMutation,
} from '../../../hooks'
import {
  DeliveryMap,
  DeliveryQuoteDispatchDialog,
  DeliveryCard,
} from './../../../components'

export const ResellerDeliveryById: FC = () => {
  const { resellerId, deliveryId } = useParams()
  const [selectedQuoteIdForDispatch, setSelectedQuoteIdForDispatch] = useState<
    string | undefined
  >(undefined)

  const useDeliveryOpts = { resellerId: resellerId!, deliveryId: deliveryId! }
  const delivery = useResellerDeliveryById(useDeliveryOpts, {
    refetchInterval: 10000,
  })
  const dispatch = useResellerDeliveryDispatchMutation(useDeliveryOpts)
  const refresh = useResellerDeliveryRefreshMutation(useDeliveryOpts)
  const cancel = useResellerDeliveryCancelMutation(useDeliveryOpts)
  const isMobile = useMediaQuery('(max-width: 900px)')

  /**
   * Handle Dialog Confirm
   */
  const handleConfirm = () => {
    dispatch.mutate(
      { quoteId: selectedQuoteIdForDispatch },
      {
        onSuccess: () => {
          setSelectedQuoteIdForDispatch(undefined)
          void delivery.refetch()
        },
      }
    )
  }

  if (delivery.isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (delivery.error) {
    return <Alert severity="error">{delivery.error.message}</Alert>
  }

  return (
    <>
      {selectedQuoteIdForDispatch && (
        <DeliveryQuoteDispatchDialog
          open
          onClose={() => setSelectedQuoteIdForDispatch(undefined)}
          onConfirm={handleConfirm}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          height: 'calc(100vh - 50px)',
          width: 'calc(100% + 44px)',
          overflow: 'hidden',
          mt: -3,
          ml: -3,
        }}
      >
        {/* Fullscreen Map in Background */}
        <Box
          sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, zIndex: 0 }}
        >
          <DeliveryMap delivery={delivery} />
        </Box>

        {/* Floating Card on Left */}
        <Box
          sx={{
            position: isMobile ? 'static' : 'absolute',
            top: isMobile ? 'unset' : 20,
            left: isMobile ? 'unset' : 20,
            zIndex: 1,
            width: isMobile ? '100%' : '30%',
            maxHeight: isMobile ? 'unset' : '90vh',
            overflowY: 'auto',
            boxShadow: 5,
            borderRadius: 3,
          }}
        >
          {delivery.isSuccess && (
            <DeliveryCard
              delivery={delivery}
              cancel={cancel}
              dispatch={dispatch}
              refresh={refresh}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
