import { useNavigate, useParams } from 'react-router-dom'
import {
  useResellerDeliveries,
  useResellerDeliveryCreateMutation,
} from '../../../hooks'
import { DeliveriesTable, DeliveryCreateDialog } from '../../../components'
import { FC, useState } from 'react'
import { Button } from '@mui/material'
import { useResellerLocations } from '../../../hooks/useResellerLocations'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export const ResellerDeliveries: FC = () => {
  let { resellerId } = useParams()
  const navigate = useNavigate()
  const [locationsSearch, setLocationsSearch] = useState<string | undefined>(
    undefined
  )
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const locations = useResellerLocations({
    resellerId,
    filters: { query: locationsSearch },
  })

  /**
   * Create the data query to fetch the orders
   */
  const deliveries = useResellerDeliveries(
    { filters: undefined, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  const createDelivery = useResellerDeliveryCreateMutation({
    resellerId: resellerId!,
  })

  /**
   * Handle the close of the create dialog
   */
  const handleOnCreateDialogClose = () => {
    setCreateDialogOpen(false)
  }

  const handleOnRowClick = (delivery: any) => {
    navigate(delivery.id)
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Deliveries', path: `/app/resellers/${resellerId}/deliveries` },
  ]

  return (
    <PageContainer
      title="Deliveries"
      breadcrumbs={breadcrumbs}
      toolbar={
        <Button onClick={() => setCreateDialogOpen(true)}>
          Create Delivery
        </Button>
      }
    >
      {createDialogOpen && (
        <DeliveryCreateDialog
          open
          onClose={handleOnCreateDialogClose}
          locations={locations}
          onLocationsSearch={(query) => setLocationsSearch(query)}
          createDelivery={createDelivery}
        />
      )}

      <DeliveriesTable deliveries={deliveries} onRowClick={handleOnRowClick} />
    </PageContainer>
  )
}
