import { FC } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'

/**
 *
 */
export interface ConfirmationDialogProps extends DialogProps {
  message: string
  title: string
  updateNewState: (confirm: boolean) => void
  setShowConfirmDailog: (show: boolean) => void
  setItemsNotUpdated?: any
}

/**
 * ConfirmationDialog.ts
 */
export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const {
    message,
    title,
    updateNewState,
    setShowConfirmDailog,
    setItemsNotUpdated,
    ...dialogProps
  } = props
  const intl = useIntl()
  const confirm = () => {
    updateNewState(true)
  }
  const handleCancel = () => {
    setShowConfirmDailog(false)
    setItemsNotUpdated && setItemsNotUpdated(true)
  }

  return (
    <Dialog {...dialogProps} fullWidth disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="success" onClick={() => confirm()}>
          {intl.formatMessage({ id: 'action_confirm' })}
        </Button>
        <Button onClick={() => handleCancel()}>
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
