import { Card } from '@mui/material'
import { FC, useState } from 'react'
import {
  QueryObserverSuccessResult,
  UseMutationResult,
} from 'react-query/types'
import { DeliveryQuoteDispatchDialog } from './DeliveryQuoteDispatchDialog'
import { DeliveryCardActions } from './DeliveryCardActions'
import { DeliveryCardContent } from './DeliveryCardContent'
/**
 *
 */
export interface DeliveryCardProps {
  delivery: QueryObserverSuccessResult<any, Error>
  refresh: UseMutationResult<any, unknown, any, unknown>
  dispatch: UseMutationResult<any, unknown, any, unknown>
  cancel: UseMutationResult<any, unknown, any, unknown>
}

/**
 *
 */
export const DeliveryCard: FC<DeliveryCardProps> = (props) => {
  const [selectedQuoteIdForDispatch, setSelectedQuoteIdForDispatch] = useState<
    string | undefined
  >(undefined)

  /**
   * Handle Dialog Confirm
   */
  const handleConfirm = () => {
    props.dispatch.mutate(
      { quoteId: selectedQuoteIdForDispatch },
      {
        onSuccess: () => {
          setSelectedQuoteIdForDispatch(undefined)
          void props.delivery.refetch()
        },
      }
    )
  }

  return (
    <>
      {selectedQuoteIdForDispatch && (
        <DeliveryQuoteDispatchDialog
          open
          onClose={() => setSelectedQuoteIdForDispatch(undefined)}
          onConfirm={handleConfirm}
        />
      )}

      <Card variant="elevation">
        {/* Delivery Card Content */}
        <DeliveryCardContent
          delivery={props.delivery}
          cancel={props.cancel}
          refresh={props.refresh}
          openQuoteDispatchDialog={(quote) =>
            setSelectedQuoteIdForDispatch(quote.quoteId)
          }
        />

        {/* Dynamic Card Actions */}
        <DeliveryCardActions delivery={props.delivery} cancel={props.cancel} />
      </Card>
    </>
  )
}
