import { IconButton } from '@mui/material'
interface ClickableIconProps {
  handleNavigate: (e: any) => void
  children: React.ReactNode
  disabled?: boolean
}

export const ClickableIcon = (props: ClickableIconProps) => {
  const { handleNavigate, children, disabled } = props
  return (
    <IconButton
      size="small"
      onClick={handleNavigate}
      disabled={disabled || false}
    >
      {children}
    </IconButton>
  )
}
