import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { AppLoader, AppWarningDialog, ClickableIcon } from '../../components'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAccounts, useLocationDelete, useLocations } from '../../hooks'
import { DeleteOutline, EditOutlined, NoteAdd } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { LocationEntity } from '../../types'
import { useCreateOrderSample } from '../../hooks/useCreateOrderSample'
import { toast } from 'react-toastify'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'

export function Locations() {
  const navigate = useNavigate()
  const intl = useIntl()
  const locations = useLocations()
  const orderCreateSample = useCreateOrderSample()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({} as LocationEntity)
  const locationDelete = useLocationDelete(selectedLocation?.id)
  const records = locations.data?.pages.map((value) => value.data).flat()
  const accountMutation = useAccounts()
  useEffect(() => {
    if (accountMutation.isSuccess && !accountMutation.data?.data?.length) {
      navigate('/app/accounts/new')
    }
  }, [accountMutation.isSuccess])
  useEffect(() => {
    if (
      !records?.length &&
      accountMutation.data?.data?.length &&
      !locations.isLoading
    ) {
      navigate('/app/locations/new')
    }
  }, [locations.isSuccess])

  const handleDeleteConfirmed = () => {
    locationDelete.mutate(undefined, {
      onSuccess: () => {
        setShowConfirmationDialog(false)
      },
    })
  }

  if (locations.isLoading) {
    return <AppLoader />
  }
  if (locations.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  const totalCount =
    locations.data?.pages.reduce((acc, page) => acc + page.data.length, 0) ?? 0
  const lastPageidx = (locations.data?.pages.length ?? 1) - 1
  const hasNextPage = locations.data?.pages[lastPageidx]?.hasNextPage ?? false

  const onPagePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    targetPage: number
  ) => {
    // If the page is not in the cache, fetch it
    if (!locations.data?.pages[targetPage]) {
      void locations.fetchNextPage().then(() => {
        setPage(targetPage)
      })
    } else {
      setPage(targetPage)
    }
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: 'My Account', path: '/app/dashboard' },
    { title: 'Locations', path: '/app/locations' },
  ]

  return (
    <PageContainer
      title="Locations"
      breadcrumbs={breadcrumbs}
      toolbar={
        <Button onClick={() => navigate('new')}>
          {intl.formatMessage({ id: `label_create_location` })}
        </Button>
      }
    >
      {locations.isSuccess && locations.data?.pages[0]?.data?.length === 0 ? (
        <Navigate to={'/app/locations/new'} replace />
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({ id: 'label_name' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'label_address' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'label_actions' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records?.map((locationData, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => navigate(`${locationData.id}/apps`)}
                  >
                    <TableCell>{locationData?.name}</TableCell>
                    <TableCell>{locationData?.address?.address}</TableCell>
                    <TableCell
                      onClick={(e) => e.stopPropagation()}
                      sx={{ cursor: 'default' }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'action_edit',
                          })}
                        >
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(`${locationData.id}`)
                            }
                            children={<EditOutlined />}
                          />
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'action_delete',
                          })}
                        >
                          <ClickableIcon
                            handleNavigate={() => [
                              setShowConfirmationDialog(true),
                              setSelectedLocation(locationData),
                            ]}
                            children={<DeleteOutline />}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  component={'td'}
                  colSpan={6}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={hasNextPage ? totalCount + rowsPerPage : totalCount}
                  rowsPerPageOptions={[25]}
                  onPageChange={onPagePage}
                  onRowsPerPageChange={(event) =>
                    setRowsPerPage(+event.target.value)
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      <AppWarningDialog
        loading={locationDelete.isLoading}
        name={selectedLocation?.name}
        source="location"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
    </PageContainer>
  )
}
