import {
  Paper,
  Grid,
  Box,
  Button,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAccountQueryById } from '../../hooks'
import { AppLoader } from '../../components'
import { useIntl } from 'react-intl'

export const Account = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const accounts = getAccountQueryById({ refetchOnMount: 'always' })

  const modify = (id: any) => {
    navigate(`edit/${id}`)
  }
  if (accounts?.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      {accounts?.isSuccess && (
        <Paper sx={{ marginTop: 2 }}>
          <Box sx={{ paddingX: 3, paddingY: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="h6" component="h1">
                    {intl.formatMessage({ id: 'label_account' })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Box marginLeft={1}>
                    <Button onClick={() => modify(accounts?.data?.data?.id)}>
                      {intl.formatMessage({ id: 'action_edit' })}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <List>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_name' })} {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {accounts?.data?.data?.name
                        ? accounts.data.data.name
                        : ''}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_currency' })} {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {accounts?.data?.data?.currency
                        ? accounts.data.data.currency
                        : ''}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_phone_number' })} {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {accounts?.data?.data?.phoneNumber
                        ? accounts.data.data.phoneNumber
                        : ''}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_billing_address' })}{' '}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {accounts?.data?.data?.address?.formatted_address
                        ? accounts.data.data.address.formatted_address
                        : ''}
                    </Typography>
                  </Typography>
                </ListItemText>
              </List>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  )
}
