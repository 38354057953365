import { useNavigate, useParams } from 'react-router-dom'
import {
  useResellerOrderAudits,
  useResellerOrderById,
  useResellerOrderDeliveries,
} from '../../../hooks'
import { OrderView } from '../../../components/OrderView'
import { Paper } from '@mui/material'
import { DeliveriesTable, OrderAuditLogs } from '../../../components'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export function ViewOrder() {
  const { resellerId, orderId } = useParams()
  const navigate = useNavigate()

  /**
   * Create the data query to fetch the orders
   */
  const order = useResellerOrderById(
    { resellerId: resellerId!, orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  const deliveries = useResellerOrderDeliveries(
    { orderId: orderId!, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  const logs = useResellerOrderAudits(
    { orderId: orderId!, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  const handleOnRowClick = (delivery: any) => {
    navigate(`../../deliveries/${delivery.id}`, { relative: 'path' })
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Orders', path: `/app/resellers/${resellerId}/orders` },
    { title: 'Order', path: `/app/resellers/${resellerId}/orders/${orderId}` },
  ]

  return (
    <PageContainer title="View Order" breadcrumbs={breadcrumbs}>
      <OrderView order={order} />
      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        {deliveries.isSuccess &&
          deliveries.data.pages.length > 0 &&
          deliveries.data.pages[0].data.length !== 0 && (
            <DeliveriesTable
              deliveries={deliveries}
              onRowClick={handleOnRowClick}
            />
          )}
      </Paper>

      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        <OrderAuditLogs logs={logs} order={order} />
      </Paper>
    </PageContainer>
  )
}
