import { Box, Paper, Slide, Tab, Tabs } from '@mui/material'
import { Navigate, useLocation } from 'react-router'
import { useRef, useState } from 'react'
import { useLoginMutation, useLoginOtpMutation } from '../../hooks'
import { useAuthStore } from '../../hooks'
import logo from '../../assets/logo.png'
import { useIntl } from 'react-intl'
import { AnimatedGraph } from '../../components/AnimatedGraph'
import { SignInFooter } from './SignInFooter'
import { SignInOtp } from './SignInWithOtp'
import { SignInPassword } from './SignInPassword'

export function SignIn() {
  const intl = useIntl()
  const location = useLocation()
  const [isLoggedIn] = useAuthStore((store) => [store.isLoggedIn])
  const loginMutation = useLoginMutation()
  const loginOtpMutation = useLoginOtpMutation()
  const [currentTab, setCurrentTab] = useState(0)
  const containerRef = useRef<HTMLElement>(null)

  /**
   * Redirect if the user is logged in
   */

  if (isLoggedIn) {
    if (location.state?.from === 'register') {
      return <Navigate to={'/app/accounts/new'} />
    } else {
      return <Navigate to={'/app/locations'} />
    }
  }

  return (
    <>
      <AnimatedGraph />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ width: 220, position: 'relative', bottom: '20px' }}
            src={logo}
          />
        </Box>
        <Box sx={{ maxWidth: 400 }}>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => setCurrentTab(newValue)}
            centered
          >
            <Tab label={intl.formatMessage({ id: 'label_sign_in_with_otp' })} />
            <Tab
              label={intl.formatMessage({ id: 'label_sign_in_with_password' })}
            />
          </Tabs>
          <Box ref={containerRef}>
            {currentTab === 0 && (
              <Slide
                container={containerRef.current}
                direction="right"
                in
                mountOnEnter
                unmountOnExit
              >
                <Box>
                  <SignInOtp loginMutation={loginOtpMutation} />
                </Box>
              </Slide>
            )}
            {currentTab === 1 && (
              <Slide
                container={containerRef.current}
                in
                direction="left"
                mountOnEnter
                unmountOnExit
              >
                <Box>
                  <SignInPassword loginMutation={loginMutation} />
                </Box>
              </Slide>
            )}
          </Box>
        </Box>
        <SignInFooter />
      </Box>
    </>
  )
}
