import { Dropdown } from '@mui/base/Dropdown'
import { Autocomplete, AutocompleteProps, Paper } from '@mui/material'
import { FC, useMemo } from 'react'

/**
 * Base picker props
 */
export interface BasePickerProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {}

/**
 * Return a component that uses the MUI style of component extension, however we will be setting the color to 'info'
 */
export const BasePicker: FC<AutocompleteProps<any, any, any, any>> = (
  props
) => {
  const customPaper = useMemo(
    () => (paperProps: any) => {
      return <Paper ref={paperProps.dropdownRef}>{paperProps.children}</Paper>
    },
    []
  )

  return (
    <Autocomplete
      color="info"
      size="small"
      PaperComponent={customPaper}
      {...props}
    />
  )
}
