import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import {
  MenuModifierGroupCreateEntity,
  MenuModifierGroupPatchEntity,
} from '../../../../../../../types'
import {
  useMenuModifiers,
  useMenuModifierGroupUpdate,
  useMenuModifierGroupById,
  getLocationQueryById,
  useMenuById,
} from '../../../../../../../hooks'
import { PageNavigationView } from '../../../../../../../components'

import {
  defaultMenuModifierGroupState,
  extractMenuModifierGroupPatchPayload,
} from '../../../utils'
import { ModifierGroupEditForm } from '../../../../../../../components/ItemEditForm/ModifierGroupEditForm'

export function MenuModifierGroupUpsert() {
  const navigate = useNavigate()
  const isMenuV2 = window.location.pathname.includes('menus')
  const urlMenu = isMenuV2 ? 'menus' : 'menu'
  const urlItem = isMenuV2 ? 'items' : 'item'
  const { locationId, menuId, itemId, modifierGroupId } = useParams()
  const modifierGroupUpdate = useMenuModifierGroupUpdate(
    locationId!,
    menuId!,
    modifierGroupId!
  )
  const modifierGroupById = useMenuModifierGroupById(
    locationId,
    menuId,
    modifierGroupId,
    {
      refetchOnMount: 'always',
      onSuccess: (data) => {
        data?.data && setModifierGroupState(data.data)
      },
    }
  )
  const [modifierGroupState, setModifierGroupState] = useState<
    MenuModifierGroupCreateEntity | MenuModifierGroupPatchEntity
  >(modifierGroupById?.data?.data ?? defaultMenuModifierGroupState)
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  // fetch Menu data
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const modifers = useMenuModifiers(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifers.fetchNextPage()
      }
    },
  })
  const modifierOptions = modifers.data?.pages
    .map((value) => value.data)
    .flat()
    ?.map((record) => record)
  const menuName = menuData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}`
        : `/app/locations/${locationId}/menu/${menuId}/modifierGroups`,
    },
    { label: modifierGroupState?.name, path: `` },
  ]
  const handleNavigation = () => {
    itemId && modifierGroupId
      ? isMenuV2
        ? navigate(
            `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
          )
        : navigate(
            `/app/locations/${locationId}/menu/${menuId}/${urlItem}/${itemId}`
          )
      : navigate(`/app/locations/${locationId}/menu/${menuId}/modifierGroups`)
  }
  const handleModifierGroupUpdate = (data: any) => {
    modifierGroupUpdate.mutate(extractMenuModifierGroupPatchPayload(data), {
      onSuccess: () => {
        handleNavigation()
      },
    })
  }

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />

      <Box sx={{ p: 3 }}>
        <ModifierGroupEditForm
          modifierGroupState={modifierGroupState}
          modifierOptions={modifierOptions}
          handleModifierGroupUpdate={handleModifierGroupUpdate}
          handleNavigation={handleNavigation}
          showModifierEdit={false}
        />
      </Box>
    </>
  )
}
