import { FC } from 'react'
import { Avatar, useTheme, Theme, styled } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { DeliveryDispatchedListItem } from './DeliveryDispatchedList'
import { QueryObserverSuccessResult } from 'react-query/types'

/**
 * Helper function to return the status color based on the delivery status and theme
 */
const getDispatchedStatusColorFromDeliveryDriverStatus = (
  theme: Theme,
  dispatched: any
) => {
  const deliveryDriverStatusToColorMap: Record<string, string> = {
    UNASSIGNED: theme.palette.grey[300],
    ASSIGNED: theme.palette.success.main,
    ON_ROUTE_TO_PICKUP: theme.palette.success.main,
    AT_PICKUP: theme.palette.success.main,
    ON_ROUTE_TO_DROP_OFF: theme.palette.success.main,
    AT_DROP_OFF: theme.palette.success.main,
    RETURNING: theme.palette.warning.main,
    COMPLETED: theme.palette.success.main,
    CANCELLED: theme.palette.error.main,
    RETURNED: theme.palette.warning.main,
  }

  /**
   * Find the highest percentage from the dispatched deliveries
   */
  return deliveryDriverStatusToColorMap[dispatched.driverStatus]
}

/**
 *
 */
export interface DeliveryDispatchedAvatarProps {
  delivery: QueryObserverSuccessResult<any, Error>
  dispatched: any
}

/**
 * Delivery Dispatched Popover
 */
export const DeliveryDispatchedAvatar: FC<DeliveryDispatchedAvatarProps> = (
  props
) => {
  const theme = useTheme()
  const backgroundColor = getDispatchedStatusColorFromDeliveryDriverStatus(
    theme,
    props.dispatched
  )
  const firstChar = props.dispatched.applicationName.charAt(0)

  return (
    <LightTooltip
      title={
        <DeliveryDispatchedListItem
          dispatched={props.dispatched}
          delivery={props.delivery}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
    >
      <Avatar
        key={props.dispatched.dispatchedId}
        alt={props.dispatched.applicationName}
        sx={{ width: 32, height: 32, backgroundColor }}
        onClick={(e) => {
          e.stopPropagation()
          return true
        }}
      >
        {firstChar}
      </Avatar>
    </LightTooltip>
  )
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
