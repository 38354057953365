import { Info } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuthStore } from '../../hooks'
import { useApplicationById } from '../../hooks'
import { AppLoader } from '../../components'
// @ts-ignore
import logo from '../../assets/logo.webp'
import { useState } from 'react'
import { CenteredBox } from '../../components/CenteredBox'

export const createAllowedAuthorizeUrl = (
  searchParams: URLSearchParams,
  token: string,
  accountId: string,
  locationId: string
) => {
  searchParams.set('approve', 'true')
  searchParams.set('token', token)
  searchParams.set('account_id', accountId)
  searchParams.set('location_id', locationId)

  return `${API_ENDPOINT}/oauth2/authorize?${searchParams.toString()}`
}

export const Authorize = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
  const token = useAuthStore((state) => state.token)
  const accountId = useAuthStore((state) => state.accountId)
  const [searchParams] = useSearchParams()
  const errorMessage = searchParams.get('errorMessage')
  const application = useApplicationById(searchParams.get('client_id')!)

  const [deny, setDeny] = useState(false)
  const [loading, setLoading] = useState(false)

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/',
          search: new URLSearchParams({
            back: window.location.href,
          }).toString(),
        }}
      />
    )
  }

  if (errorMessage) {
    return (
      <Box>
        <Alert severity="error">{errorMessage}</Alert>
      </Box>
    )
  }

  if (application.isLoading || loading) {
    return <AppLoader />
  }

  if (application.isError) {
    return (
      <CenteredBox>
        <Alert severity="error">We're sorry, but something went wrong.</Alert>
      </CenteredBox>
    )
  }

  const onDeny = () => {
    setDeny(true)
  }

  const onAllow = async () => {
    try {
      setLoading(true)

      await new Promise((resolve) => setTimeout(resolve, 500))

      window.location.href = createAllowedAuthorizeUrl(
        searchParams,
        token?.access_token!,
        accountId!,
        searchParams.get('location_id')!
      )
    } catch (error) {
      console.error('Error while allowing:', error)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      {deny ? (
        <Box>
          <Alert severity="error">We're sorry, but something went wrong.</Alert>
        </Box>
      ) : (
        <Dialog
          open={true}
          fullWidth
          maxWidth={'sm'}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent dividers>
            <Box>
              {application.isSuccess && (
                <Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ width: '100%', maxWidth: '176px' }}
                      src={logo}
                    />
                    <Box sx={{ marginTop: 2 }}>
                      {application.data.data.name} bridge would like to access
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                  </Box>
                  <Box>
                    <List>
                      {application.data.data.scopes.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <Info />
                          </ListItemIcon>
                          <ListItemText primary={item} />
                        </ListItem>
                      ))}
                    </List>
                    <Divider />
                    <Box sx={{ marginTop: 2 }}>
                      {application.data.data.description}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 3,
                  alignItems: 'flex-end',
                }}
              >
                <Button onClick={onDeny}>Deny</Button>
                <Box marginLeft={1}>
                  <Button color="success" onClick={onAllow}>
                    Allow
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}
