import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { FC } from 'react'
import { useAccounts, useAuthStore } from '../../hooks'
import { AppLoader } from '../AppLoader'
import { FormattedMessage } from 'react-intl'

/**
 * AccountSwitcherDialogProps
 */
export interface AccountSwitcherDialogProps extends Omit<DialogProps, 'open'> {}

/**
 * AccountSwitcherDialog component
 */
export const AccountSwitcherDialog: FC<AccountSwitcherDialogProps> = (
  props
) => {
  const accounts = useAccounts()
  const [accountId, setAccountId] = useAuthStore((t) => [
    t.accountId,
    t.setAccountId,
  ])

  const onAccountSelect = (id: string) => {
    setAccountId(id)
    props?.onClose?.({}, 'backdropClick')
  }
  return (
    <Dialog open={true} fullWidth {...props}>
      <DialogTitle>
        <FormattedMessage
          id="selectAccount"
          defaultMessage={'Select Account'}
        />
      </DialogTitle>
      <DialogContent>
        {accounts.isLoading && <AppLoader />}
        {accounts.isError && <div>Error</div>}
        {accounts.isSuccess && (
          <List>
            {accounts.data?.data.map((account: any) => (
              // <div key={account.id}>{account.name}</div>
              <ListItemButton
                selected={accountId === account.id}
                onClick={() => onAccountSelect(account.id)}
              >
                <ListItemText
                  primary={account.name}
                  secondary={account.address.formatted_address}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  )
}
