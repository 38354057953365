'use client'
import Box from '@mui/material/Box'
import Container, { ContainerProps } from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { PageHeader, PageHeaderProps } from './PageHeader'
import { ElementType, FC, ReactNode } from 'react'

export interface Breadcrumb {
  /**
   * The title of the breadcrumb segment.
   */
  title: string

  /**
   * The path the breadcrumb links to.
   */
  path: string
}

export interface PageContainerProps extends ContainerProps {
  children?: ReactNode

  /**
   * The title of the page. Leave blank to use the active page title.
   */
  title?: string

  /**
   * The breadcrumbs of the page. Leave blank to use the active page breadcrumbs.
   */
  breadcrumbs?: Breadcrumb[]

  /**
   * toolbar element
   */
  toolbar?: React.ReactNode
}

/**
 * A container component to provide a title and breadcrumbs for your pages.
 */
export const PageContainer: FC<PageContainerProps> = (props) => {
  const { breadcrumbs, title, toolbar, children, ...rest } = props

  return (
    <Container
      maxWidth="xl"
      disableGutters
      {...rest}
      sx={{ flex: 1, display: 'flex', flexDirection: 'column', ...rest.sx }}
    >
      {/* Header Slot */}
      <Stack sx={{ flex: 1, my: 2 }} spacing={2}>
        <PageHeader title={title} breadcrumbs={breadcrumbs} toolbar={toolbar} />
        {/* Actual Content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {children}
        </Box>
      </Stack>
    </Container>
  )
}
