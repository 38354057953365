import { styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const PageHeaderToolbarRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  // Ensure the toolbar is always on the right side, even after wrapping
  marginLeft: 'auto',
}))

export interface PageHeaderToolbarProps extends PropsWithChildren<{}> {}

export const PageHeaderToolbar: FC<PageHeaderToolbarProps> = ({ children }) => {
  return <PageHeaderToolbarRoot>{children}</PageHeaderToolbarRoot>
}
