import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { TabNavigation, PageNavigationView } from '../../../../components'
import { CatalogCategory } from './Category'
import { Products } from './Products'
import { Modifiers } from './Modifiers'
import { ModifierGroups } from './ModifierGroups'
import { getLocationQueryById } from '../../../../hooks'

export function MappingCatalogLocation() {
  const [currentTab, setCurrentTab] = useState(0)
  const { locationId, data } = useParams()
  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const navigate = useNavigate()
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      label: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
  ]
  useEffect(() => {
    if (data === 'categories') {
      setCurrentTab(0)
    } else if (data === 'items') {
      setCurrentTab(1)
    } else if (data === 'modifiers') {
      setCurrentTab(2)
    } else {
      setCurrentTab(3)
    }
  }, [data])
  const handleTabChange = (newValue: any) => {
    setCurrentTab(newValue)
    switch (newValue) {
      case 0:
        setCurrentTab(0)
        navigate(`/app/locations/${locationId}/catalog/categories`, {
          replace: true,
        })
        break
      case 1:
        setCurrentTab(1)
        navigate(`/app/locations/${locationId}/catalog/items`, {
          replace: true,
        })
        break
      case 2:
        setCurrentTab(2)
        navigate(`/app/locations/${locationId}/catalog/modifiers`, {
          replace: true,
        })
        break
      default:
        setCurrentTab(3)
        navigate(`/app/locations/${locationId}/catalog/modifier-group`, {
          replace: true,
        })
        break
    }
  }
  return (
    <>
      <Box sx={{ position: 'sticky', top: 50, zIndex: 999 }}>
        <PageNavigationView navigationPages={navigationPages} />
        <TabNavigation
          currentTab={currentTab}
          setCurrentTab={handleTabChange}
          navigate={navigate}
          path={`/app/locations/${locationId}/analytics`}
          labels={['Categories', 'Items', 'Modifiers', 'Modifier Group']}
        />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {currentTab === 0 && <CatalogCategory />}
        {currentTab === 1 && <Products />}
        {currentTab === 2 && <Modifiers />}
        {currentTab === 3 && <ModifierGroups />}
      </Box>
    </>
  )
}
