import { Chip } from '@mui/material'
import { FC } from 'react'

const statusColorMap: Record<
  string,
  'default' | 'primary' | 'success' | 'warning' | 'error'
> = {
  CREATED: 'default',
  IN_PROGRESS: 'success',
  COMPLETED: 'success',
  RETURNED: 'warning',
  CANCELLED: 'error',
}

const readableStatusMap: Record<string, string> = {
  CREATED: 'Created',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
}

export const DeliveryStatusLabel: FC<{
  status: string
}> = (props) => {
  const getStatusColor = (status: string) => statusColorMap[status] || 'default'
  const readableStatus = readableStatusMap[props.status] || props.status

  return (
    <Chip
      label={readableStatus}
      color={getStatusColor(props.status)}
      size="small"
      sx={{
        width: '100%',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
      }}
    />
  )
}
