import { Box, Typography } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { AppLoader, CommonTextField, LoadingButton } from '../../components'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { UseMutationResult } from 'react-query/types'
import { LoginOtpModel } from '../../model/LoginModel'
import { validatePhoneNumberLength } from 'libphonenumber-js'

export interface SignInOtpProps {
  loginMutation: UseMutationResult<any, any, any>
}

export const SignInOtp: FC<SignInOtpProps> = (props) => {
  const intl = useIntl()
  const { loginMutation } = props
  const telInputRef = useRef<HTMLInputElement>(null)
  const location = useLocation()
  const isPhoneState = location?.state?.phone
  const [isValidOTP, setValidOTP] = useState(false)
  const [isOTP, setIsOTP] = useState(isPhoneState ? true : false)
  const [phoneNumber, setPhoneNumber] = useState('' as string)
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false)
  const [loginOtpInstance] = useState(
    new LoginOtpModel({ phone: isPhoneState })
  )

  useEffect(() => {
    if (telInputRef.current) {
      telInputRef.current.focus()
    }
  }, [])

  const validateOTP = (otp: string) => {
    const otpRegex = /^[0-9]{4,6}$/
    setValidOTP(otpRegex.test(otp))
    return otpRegex.test(otp)
  }
  const handleChangeVerifyOTP = (event: ChangeEvent<HTMLInputElement>) => {
    loginOtpInstance.code = event.target.value
    setValidOTP(validateOTP(event.target.value))
  }
  const handleVerifyOTP = () => {
    loginMutation.mutate(loginOtpInstance, {
      onSuccess: () => {},
      onError: () => {},
    })
  }
  if (loginMutation.isLoading) {
    return <AppLoader />
  }
  const handleSendOTP = () => {
    setIsOTP(true)
    loginMutation.mutate(loginOtpInstance, {
      onSuccess: () => {},
      onError: () => {
        setIsOTP(false)
      },
    })
  }
  const validatePhone = (phone: string) => {
    return validatePhoneNumberLength(phone) ? false : true
  }
  const handlePhoneChange = (number: string) => {
    const phone = number.replace(/\s/g, '')
    setPhoneNumber(phone)
    loginOtpInstance.phone = phone
    setValidPhoneNumber(validatePhone(phone))
  }
  return (
    <>
      {!isOTP && (
        <>
          <Box sx={{ mt: 4 }}>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              placeholder="e.g. +1 123-456-7890"
              value={phoneNumber || ''}
              autoFocus={true}
              onChange={handlePhoneChange}
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key == 'Enter') void handleSendOTP()
              }}
              inputProps={{
                ref: telInputRef,
              }}
            />
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <LoadingButton
              disabled={!isValidPhoneNumber}
              loading={loginMutation.isLoading}
              sx={{ height: '50px' }}
              onClick={() => handleSendOTP()}
              fullWidth
              size="large"
              children={intl.formatMessage({ id: 'action_send_otp' })}
            />
          </Box>
        </>
      )}
      {isOTP && (
        <>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'label_enter_otp_for_login' })}
            </Typography>
            <CommonTextField
              type="number"
              label={intl.formatMessage({ id: 'label_enter_otp' })}
              autoFocus={true}
              disabled={loginMutation.isLoading}
              // error={!isValidOTP}
              // helperText={
              //   !isValidOTP
              //     ? `Invalid OTP. Please enter a valid 4-6 digit OTP`
              // : ' '
              // }
              onKeyDown={(event) => {
                if (event.key == 'Enter' && isValidOTP) void handleVerifyOTP()
              }}
              onChange={handleChangeVerifyOTP}
            />
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <LoadingButton
              sx={{ height: '50px' }}
              onClick={() => handleVerifyOTP()}
              loading={false}
              disabled={!isValidOTP || loginMutation.isLoading}
              fullWidth
              size="large"
              children={intl.formatMessage({ id: 'action_verify_otp' })}
            />
          </Box>
        </>
      )}
    </>
  )
}
