import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { FC } from 'react'

export interface DeliveryQuoteDispatchDialogProps extends DialogProps {
  onConfirm: () => void
}

export const DeliveryQuoteDispatchDialog: FC<
  DeliveryQuoteDispatchDialogProps
> = (props) => {
  const { onConfirm, ...rest } = props
  return (
    <Dialog fullWidth {...rest}>
      <DialogTitle>Dispatch</DialogTitle>
      <DialogContent>
        you are about to confirm the delivery dispatch. this will incur the cost
        of the delivery to your account
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
          Cancel
        </Button>
        <Button color="success" onClick={() => onConfirm()}>
          Dispatch
        </Button>
      </DialogActions>
    </Dialog>
  )
}
