import { useTheme } from '@mui/material'
import { Chart, ChartProps } from 'react-chartjs-2'

export interface BaseChartProps extends ChartProps {}

export const BaseChart = (props: BaseChartProps) => {
  const theme = useTheme()

  const options: ChartProps['options'] = {
    ...props.options,
    plugins: {
      ...props.options?.plugins,
      legend: {
        ...props.options?.plugins?.legend,
        labels: {
          color: theme.palette.text.primary,
          ...props.options?.plugins?.legend?.labels,
        },
      },
    },
    scales: {
      ...props.options?.scales,
      x: {
        ...props.options?.scales?.x,
        grid: {
          color: theme.palette.divider,
          ...props.options?.scales?.x?.grid,
        },
        ticks: {
          color: theme.palette.text.primary,
          ...props.options?.scales?.x?.ticks,
        },
      },
      y: {
        ...props.options?.scales?.y,
        grid: {
          color: theme.palette.divider,
          ...props.options?.scales?.y?.grid,
        },
        ticks: {
          color: theme.palette.text.primary,
          ...props.options?.scales?.y?.ticks,
        },
      },
    },
  }

  return <Chart {...props} options={options} />
}
