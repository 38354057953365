import { FC } from 'react'
import { ApplicationEntity, OrderEntity } from '../../../types'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { Checkbox, MenuItem, TextField } from '@mui/material'
import {
  Assignment,
  CheckBox,
  CheckBoxOutlineBlank,
  DeliveryDining,
  Fastfood,
  PointOfSale,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'

/**
 * Static list of supported order statuses
 */
const supportedCategories: ApplicationEntity['category'][] = [
  'MARKETPLACE',
  'DELIVERY',
  'OTHER',
  'POS',
]

/**
 * categoryIconMap
 */
const categoryIconMap: Record<ApplicationEntity['category'], JSX.Element> = {
  MARKETPLACE: <Fastfood />,
  DELIVERY: <DeliveryDining />,
  OTHER: <Assignment />,
  POS: <PointOfSale />,
}

/**
 * categoryNameMap
 */
const categoryNameMap: Record<ApplicationEntity['category'], string> = {
  MARKETPLACE: 'Marketplace',
  DELIVERY: 'Delivery',
  OTHER: 'Other',
  POS: 'POS',
}

/**
 *
 */
export interface ApplicationCategoryPickerProps
  extends Omit<
    BasePickerProps<ApplicationEntity['category'], false, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const ApplicationCategoryPicker: FC<ApplicationCategoryPickerProps> = (
  props
) => {
  const intl = useIntl()
  return (
    <BasePicker
      options={supportedCategories}
      disableClearable
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={'Application Cateogry'} />
      )}
      {...props}
    />
  )
}
