import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResellerAccountsById } from '../../../hooks/useResellerAccounts'
import {
  Paper,
  Button,
  Box,
  Grid,
  Typography,
  List,
  ListItemText,
} from '@mui/material'
import { FormattedPhoneNumber } from '../../../components/FormattedPhoneNumber'
import { FormattedTimeZone } from '../../../components/FormattedTimeZone'
import { useIntl } from 'react-intl'

export const ResellerAccountSettings: FC = () => {
  const { resellerId, accountId } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const accountById = useResellerAccountsById(resellerId!, accountId!, {
    onSuccess: (data: any) => {
      setAccountData(data)
    },
  })
  const [accountData, setAccountData] = useState<any>(accountById.data)

  return (
    <Paper sx={{ borderRadius: 2, marginTop: 2 }}>
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="h6" component="h1">
                {intl.formatMessage({ id: 'label_account_details' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Box marginLeft={1}>
                <Button
                  onClick={() =>
                    navigate(
                      `/app/resellers/${resellerId}/accounts/${accountId}/edit`
                    )
                  }
                >
                  {intl.formatMessage({ id: 'action_update' })}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <List>
            <ListItemText sx={{ marginTop: 1 }}>
              <Typography component="span" color="action">
                <Typography component={'span'} color={'grayText'}>
                  {intl.formatMessage({ id: 'label_name' })}
                  {' :'}
                </Typography>
                <Typography component={'span'} sx={{ marginLeft: 1 }}>
                  {accountData?.name}
                </Typography>
              </Typography>
            </ListItemText>
            <ListItemText sx={{ marginTop: 1 }}>
              <Typography component="span" color="action">
                <Typography component={'span'} color={'grayText'}>
                  {intl.formatMessage({ id: 'label_address' })}
                  {' :'}
                </Typography>
                <Typography component={'span'} sx={{ marginLeft: 1 }}>
                  {accountData?.address?.formatted_address}
                </Typography>
              </Typography>
            </ListItemText>
            {accountData?.phoneNumber && (
              <ListItemText sx={{ marginTop: 1 }}>
                <Typography component="span" color="action">
                  <Typography component={'span'} color={'grayText'}>
                    {intl.formatMessage({ id: 'label_phone_number' })}
                    {' :'}
                  </Typography>
                  <Typography component={'span'} sx={{ marginLeft: 1 }}>
                    <FormattedPhoneNumber
                      phoneNumber={accountData?.phoneNumber}
                    />
                  </Typography>
                </Typography>
              </ListItemText>
            )}
            {accountData?.currency && (
              <ListItemText sx={{ marginTop: 1 }}>
                <Typography component="span" color="action">
                  <Typography component={'span'} color={'grayText'}>
                    {intl.formatMessage({ id: 'label_currency' })}
                    {' :'}
                  </Typography>
                  <Typography component={'span'} sx={{ marginLeft: 1 }}>
                    <FormattedTimeZone value={accountData?.currency} />
                  </Typography>
                </Typography>
              </ListItemText>
            )}
          </List>
        </Box>
      </Box>
    </Paper>
  )
}
