import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { UseQueryResult } from 'react-query/types'

export interface UserApplicationConnectionsProps {
  application: UseQueryResult<any, unknown>
}

export const UserApplicationConnections: FC<
  UserApplicationConnectionsProps
> = ({ application }) => {
  // Center coming soon message
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="overline">Coming Soon</Typography>
    </Grid>
  )
}
