import { FC } from 'react'
import { Typography, Button, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useIntl } from 'react-intl'
import { CenteredBox } from '../CenteredBox'

// PageTitle Component
const PageTitle: FC<{ text: string }> = ({ text }) => (
  <Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
    {text}
  </Typography>
)

// PageSubtitle Component
const PageSubtitle: FC<{ text: string }> = ({ text }) => (
  <Typography variant="h4" gutterBottom>
    {text}
  </Typography>
)

// ErrorMessage Component
const ErrorMessage: FC<{ text: string }> = ({ text }) => (
  <Typography variant="body1" align="center" sx={{ margin: 2 }}>
    {text}
  </Typography>
)

// PageNotFound Component
export function PageNotFound() {
  const theme = useTheme()
  const intl = useIntl()

  // Intl
  const notFoundHeading = intl.formatMessage({
    id: 'misc_not_found_subheading',
  })
  const notFoundDesc = intl.formatMessage({ id: 'misc_not_found_description' })

  return (
    <CenteredBox>
      <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main' }} />
      <PageTitle text="404" />
      <PageSubtitle text={notFoundHeading} />
      <ErrorMessage text={notFoundDesc} />
      <Button
        component={Link}
        to="/"
        sx={{ marginTop: '1rem', padding: theme.spacing(1, 4) }}
      >
        Return to Home
      </Button>
    </CenteredBox>
  )
}
