import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link as StyledLink,
} from '@mui/material'
import { FC, useState } from 'react'
import { useResellerMetrics } from '../../hooks/useResellerMetrics'
import { Link, useParams } from 'react-router-dom'
import { AppLoader, GenericCard } from '../../components'
import { CenteredBox } from '../../components/CenteredBox'
import { RangeSelector } from '../../components/RangeSelector'
import { FormattedNumber, useIntl } from 'react-intl'
import { FormattedMoney } from '../../components/FormattedMoney'
import { getCurrency } from '../util'
import { BaseChart } from '../../components/BaseChart'
import { PageContainer } from '../../components/Page/PageContainer'

export const ResellerDashboard: FC = () => {
  const { resellerId } = useParams()
  const [range, setRange] = useState<string>('1m')
  const metrics = useResellerMetrics({ range, resellerId: resellerId! })
  const currency = getCurrency()

  if (metrics.isLoading) {
    return <AppLoader />
  }

  if (metrics.isError || metrics.isSuccess === false) {
    return (
      <CenteredBox>
        <Typography>
          Metrics are not currently available, please try again later.
        </Typography>
      </CenteredBox>
    )
  }

  const {
    totalUsers,
    totalAccounts,
    totalRevenue,
    totalOrders,
    totalLocationsWithOrders,
    averageOrderValue,
    filteredAccounts,
    filteredOrders,
    filteredUsers,
  } = metrics.data.aggregations
  const intl = useIntl()

  /**
   * Return the page
   */
  return (
    <PageContainer title="Reseller Dashboard">
      {/*  Global Stats */}
      <Grid container spacing={2}>
        {/* Total Signups */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Users"
            subtitle="The total number of users for your reseller group."
            helpText={intl.formatMessage({
              id: 'dashboard_totalUsers_helpText',
            })}
          >
            <Typography variant="h4">{totalUsers?.value}</Typography>
          </GenericCard>
        </Grid>
        {/* Total Accounts */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Accounts"
            subtitle="The total number of accounts for your reseller group."
            helpText={intl.formatMessage({
              id: 'dashboard_totalAccounts_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber value={totalAccounts?.value ?? 0} />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Total Locations */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Active Locations"
            subtitle="The total number of locations that have atleast one order"
            helpText={intl.formatMessage({
              id: 'dashboard_totalActiveLocations_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber value={totalLocationsWithOrders?.value ?? 0} />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Total Orders */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Orders"
            subtitle="The total number of orders for your reseller group."
            helpText={intl.formatMessage({
              id: 'dashboard_orders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber value={totalOrders?.value ?? 0} />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Revenue Generated */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Revenue"
            subtitle="The total revenue for your reseller group."
            helpText={intl.formatMessage({
              id: 'dashboard_totalRevenue_helpText',
            })}
          >
            <Typography variant="h4" component="span">
              <FormattedMoney
                value={totalRevenue?.value ? totalRevenue.value / 100 : 0}
                decimalValue={true}
                currency={currency}
              />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Average Order Value */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Average Order Value"
            subtitle="The average order value for your reseller group."
            helpText={intl.formatMessage({
              id: 'dashboard_reseller_avgOrderValue_helpText',
            })}
          >
            <Typography variant="h4" component="span">
              <FormattedMoney
                value={
                  averageOrderValue?.value ? averageOrderValue.value / 100 : 0
                }
                decimalValue={true}
                currency={currency}
              />
            </Typography>
          </GenericCard>
        </Grid>
      </Grid>
      {/* Range Selector */}
      <Box sx={{ flexGrow: 1, pt: 3 }}>
        <Box sx={{ flexGrow: 1, float: 'right' }}>
          <RangeSelector
            options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
            selected={range}
            onChange={(newValue) => setRange(newValue)}
          />
        </Box>
      </Box>
      {/* Ranged Stats / Charts */}
      <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({ id: 'dashboard_totalOrders_title' })}
            subtitle={intl.formatMessage({
              id: 'dashboard_totalOrders_subtitle',
            })}
            helpText={intl.formatMessage({
              id: 'dashboard_totalOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={filteredOrders?.doc_count ?? 0}
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({ id: 'dashboard_newAccounts_title' })}
            subtitle={intl.formatMessage({
              id: 'dashboard_newAccounts_subtitle',
            })}
            helpText={intl.formatMessage({
              id: 'dashboard_newAccounts_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={filteredAccounts?.totalAccounts?.value ?? 0}
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({ id: 'dashboard_newUsers_title' })}
            subtitle="The total number of users created within time period."
            helpText={intl.formatMessage({ id: 'dashboard_newUsers_helpText' })}
          >
            <Typography variant="h4">
              {filteredUsers?.totalUsers?.value}
            </Typography>
          </GenericCard>
        </Grid>

        {/* Create a stat box for COMPLETED, REJECTED and CANCELLED orders */}
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({
              id: 'dashboard_completedOrders_title',
            })}
            subtitle={intl.formatMessage({
              id: 'dashboard_completedOrders_subtitle',
            })}
            helpText={intl.formatMessage({
              id: 'dashboard_completedOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  filteredOrders?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'COMPLETED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({ id: 'dashboard_rejectedOrders_title' })}
            subtitle={intl.formatMessage({
              id: 'dashboard_rejectedOrders_subtitle',
            })}
            helpText={intl.formatMessage({
              id: 'dashboard_rejectedOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  filteredOrders?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'REJECTED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={intl.formatMessage({
              id: 'dashboard_cancelledOrders_title',
            })}
            subtitle={intl.formatMessage({
              id: 'dashboard_cancelledOrders_subtitle',
            })}
            helpText={intl.formatMessage({
              id: 'dashboard_cancelledOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  filteredOrders?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'CANCELLED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>

        {/* Order volume over time */}
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Order volume over time"
            subtitle="This chart shows the order volume within the selected time period."
            helpText={intl.formatMessage({
              id: 'dashboard_volumeOverTimeChart_helpText',
            })}
          >
            <BaseChart
              style={{ maxHeight: '300px' }}
              options={{ scales: { x: { type: 'timeseries' } } }}
              data={{
                datasets: [
                  {
                    label: 'Orders',
                    data: filteredOrders.orderVolumeHistogram.buckets.map(
                      (bucket: any) => ({ x: bucket.key, y: bucket.doc_count })
                    ),
                  },
                ],
              }}
              type="line"
            />
          </GenericCard>
        </Grid>
        {/* Top Locations */}
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Top Locations"
            subtitle="This table shows the top locations by revenue."
            helpText={intl.formatMessage({
              id: 'dashboard_topLocation_helpText',
            })}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell>Orders</TableCell>
                  <TableCell>Total Revenue</TableCell>
                  <TableCell>Average Order Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders?.topLocations?.buckets.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography>
                        No data available for the selected range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {filteredOrders?.topLocations?.buckets.map((bucket: any) => (
                  <TableRow key={bucket.key}>
                    <TableCell>
                      {/* {bucket.details.hits.hits[0]._source.locationName} */}
                      {bucket.details.hits.hits[0]._source.accountId ? (
                        <StyledLink
                          component={Link}
                          to={`/app/resellers/${resellerId}/accounts/${bucket.details.hits.hits[0]._source.accountId}/locations/${bucket.key}/edit`}
                        >
                          {bucket.details.hits.hits[0]._source.locationName}
                        </StyledLink>
                      ) : (
                        bucket.details.hits.hits[0]._source.locationName
                      )}
                    </TableCell>
                    <TableCell>
                      <FormattedNumber value={bucket.totalOrders.value} />
                    </TableCell>
                    <TableCell>
                      <FormattedMoney
                        value={bucket.totalRevenue.value / 100}
                        decimalValue={true}
                        currency={currency}
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMoney
                        value={bucket.averageOrderValue.value / 100}
                        decimalValue={true}
                        currency={currency}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GenericCard>
        </Grid>
      </Grid>
    </PageContainer>
  )

  // return (
  //   <>
  //     {/*  Global Stats */}
  //     <Grid container spacing={2}>
  //       {/* Total Signups */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Total Users"
  //           subtitle="The total number of users for your reseller group."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_totalUsers_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">{totalUsers?.value}</Typography>
  //         </GenericCard>
  //       </Grid>
  //       {/* Total Accounts */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Total Accounts"
  //           subtitle="The total number of accounts for your reseller group."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_totalAccounts_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber value={totalAccounts?.value ?? 0} />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       {/* Total Locations */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Active Locations"
  //           subtitle="The total number of locations that have atleast one order"
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_totalActiveLocations_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber value={totalLocationsWithOrders?.value ?? 0} />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       {/* Total Orders */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Total Orders"
  //           subtitle="The total number of orders for your reseller group."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_orders_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber value={totalOrders?.value ?? 0} />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       {/* Revenue Generated */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Total Revenue"
  //           subtitle="The total revenue for your reseller group."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_totalRevenue_helpText',
  //           })}
  //         >
  //           <Typography variant="h4" component="span">
  //             <FormattedMoney
  //               value={totalRevenue?.value ? totalRevenue.value / 100 : 0}
  //               decimalValue={true}
  //               currency={currency}
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       {/* Average Order Value */}
  //       <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title="Average Order Value"
  //           subtitle="The average order value for your reseller group."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_reseller_avgOrderValue_helpText',
  //           })}
  //         >
  //           <Typography variant="h4" component="span">
  //             <FormattedMoney
  //               value={
  //                 averageOrderValue?.value ? averageOrderValue.value / 100 : 0
  //               }
  //               decimalValue={true}
  //               currency={currency}
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //     </Grid>
  //     {/* Range Selector */}
  //     <Box sx={{ flexGrow: 1, pt: 3 }}>
  //       <Box sx={{ flexGrow: 1, float: 'right' }}>
  //         <RangeSelector
  //           options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
  //           selected={range}
  //           onChange={(newValue) => setRange(newValue)}
  //         />
  //       </Box>
  //     </Box>
  //     {/* Ranged Stats / Charts */}
  //     <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({ id: 'dashboard_totalOrders_title' })}
  //           subtitle={intl.formatMessage({
  //             id: 'dashboard_totalOrders_subtitle',
  //           })}
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_totalOrders_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber
  //               value={filteredOrders?.doc_count ?? 0}
  //               style="decimal"
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({ id: 'dashboard_newAccounts_title' })}
  //           subtitle={intl.formatMessage({
  //             id: 'dashboard_newAccounts_subtitle',
  //           })}
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_newAccounts_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber
  //               value={filteredAccounts?.totalAccounts?.value ?? 0}
  //               style="decimal"
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({ id: 'dashboard_newUsers_title' })}
  //           subtitle="The total number of users created within time period."
  //           helpText={intl.formatMessage({ id: 'dashboard_newUsers_helpText' })}
  //         >
  //           <Typography variant="h4">
  //             {filteredUsers?.totalUsers?.value}
  //           </Typography>
  //         </GenericCard>
  //       </Grid>

  //       {/* Create a stat box for COMPLETED, REJECTED and CANCELLED orders */}
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({
  //             id: 'dashboard_completedOrders_title',
  //           })}
  //           subtitle={intl.formatMessage({
  //             id: 'dashboard_completedOrders_subtitle',
  //           })}
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_completedOrders_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber
  //               value={
  //                 filteredOrders?.orderStatusVolume?.buckets.find(
  //                   (bucket: any) => bucket.key === 'COMPLETED'
  //                 )?.doc_count ?? 0
  //               }
  //               style="decimal"
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({ id: 'dashboard_rejectedOrders_title' })}
  //           subtitle={intl.formatMessage({
  //             id: 'dashboard_rejectedOrders_subtitle',
  //           })}
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_rejectedOrders_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber
  //               value={
  //                 filteredOrders?.orderStatusVolume?.buckets.find(
  //                   (bucket: any) => bucket.key === 'REJECTED'
  //                 )?.doc_count ?? 0
  //               }
  //               style="decimal"
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>
  //       <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
  //         <GenericCard
  //           title={intl.formatMessage({
  //             id: 'dashboard_cancelledOrders_title',
  //           })}
  //           subtitle={intl.formatMessage({
  //             id: 'dashboard_cancelledOrders_subtitle',
  //           })}
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_cancelledOrders_helpText',
  //           })}
  //         >
  //           <Typography variant="h4">
  //             <FormattedNumber
  //               value={
  //                 filteredOrders?.orderStatusVolume?.buckets.find(
  //                   (bucket: any) => bucket.key === 'CANCELLED'
  //                 )?.doc_count ?? 0
  //               }
  //               style="decimal"
  //             />
  //           </Typography>
  //         </GenericCard>
  //       </Grid>

  //       {/* Order volume over time */}
  //       <Grid item xs={12} md={12} lg={12}>
  //         <GenericCard
  //           title="Order volume over time"
  //           subtitle="This chart shows the order volume within the selected time period."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_volumeOverTimeChart_helpText',
  //           })}
  //         >
  //           <BaseChart
  //             style={{ maxHeight: '300px' }}
  //             options={{ scales: { x: { type: 'timeseries' } } }}
  //             data={{
  //               datasets: [
  //                 {
  //                   label: 'Orders',
  //                   data: filteredOrders.orderVolumeHistogram.buckets.map(
  //                     (bucket: any) => ({ x: bucket.key, y: bucket.doc_count })
  //                   ),
  //                 },
  //               ],
  //             }}
  //             type="line"
  //           />
  //         </GenericCard>
  //       </Grid>
  //       {/* Top Locations */}
  //       <Grid item xs={12} md={12} lg={12}>
  //         <GenericCard
  //           title="Top Locations"
  //           subtitle="This table shows the top locations by revenue."
  //           helpText={intl.formatMessage({
  //             id: 'dashboard_topLocation_helpText',
  //           })}
  //         >
  //           <Table>
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell>Location</TableCell>
  //                 <TableCell>Orders</TableCell>
  //                 <TableCell>Total Revenue</TableCell>
  //                 <TableCell>Average Order Value</TableCell>
  //               </TableRow>
  //             </TableHead>
  //             <TableBody>
  //               {filteredOrders?.topLocations?.buckets.length === 0 && (
  //                 <TableRow>
  //                   <TableCell colSpan={4} align="center">
  //                     <Typography>
  //                       No data available for the selected range.
  //                     </Typography>
  //                   </TableCell>
  //                 </TableRow>
  //               )}
  //               {filteredOrders?.topLocations?.buckets.map((bucket: any) => (
  //                 <TableRow key={bucket.key}>
  //                   <TableCell>
  //                     {/* {bucket.details.hits.hits[0]._source.locationName} */}
  //                     {bucket.details.hits.hits[0]._source.accountId ? (
  //                       <StyledLink
  //                         component={Link}
  //                         to={`/app/resellers/${resellerId}/accounts/${bucket.details.hits.hits[0]._source.accountId}/locations/${bucket.key}/edit`}
  //                       >
  //                         {bucket.details.hits.hits[0]._source.locationName}
  //                       </StyledLink>
  //                     ) : (
  //                       bucket.details.hits.hits[0]._source.locationName
  //                     )}
  //                   </TableCell>
  //                   <TableCell>
  //                     <FormattedNumber value={bucket.totalOrders.value} />
  //                   </TableCell>
  //                   <TableCell>
  //                     <FormattedMoney
  //                       value={bucket.totalRevenue.value / 100}
  //                       decimalValue={true}
  //                       currency={currency}
  //                     />
  //                   </TableCell>
  //                   <TableCell>
  //                     <FormattedMoney
  //                       value={bucket.averageOrderValue.value / 100}
  //                       decimalValue={true}
  //                       currency={currency}
  //                     />
  //                   </TableCell>
  //                 </TableRow>
  //               ))}
  //             </TableBody>
  //           </Table>
  //         </GenericCard>
  //       </Grid>
  //     </Grid>
  //   </>
  // )
}
