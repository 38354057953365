import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query/types'
import { ApplicationCard } from '../../../components'
import { useIntl } from 'react-intl'
import { AuthToken } from '../../../hooks'
import {
  Apps,
  DeliveryDining,
  PointOfSale,
  Storefront,
  Terminal,
} from '@mui/icons-material'

export interface ApplicationCategory {
  displayName: string
  icon: JSX.Element | null
}

/**
 * Categories and their corresponding icons and tester functions
 */
export const supportedCategories: Record<string, ApplicationCategory> = {
  all: { displayName: 'All Applications', icon: <Apps /> },
  pos: { displayName: 'Point of Sale', icon: <PointOfSale /> },
  marketplace: { displayName: 'Marketplace', icon: <Storefront /> },
  delivery: { displayName: 'Delivery', icon: <DeliveryDining /> },
  other: { displayName: 'Other', icon: <Terminal /> },
}

/**
 * The props for the Applications component.
 */
export interface ApplicationsViewProps {
  applications: UseInfiniteQueryResult<any, unknown>
  accountId: string
  locationId: string
  token: AuthToken | undefined
  handleDeleteApp: (app: any) => void
}

/**
 * Applications
 */
export const Applications: FC<ApplicationsViewProps> = (props) => {
  const intl = useIntl()
  const [selectedCategory, setSelectedCategory] = useState<string>('all')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const categoryDisplayName = supportedCategories[selectedCategory].displayName

  /**
   * Get a list of applications that have been filtered by the selected category
   */
  const allApplications = (props.applications.data?.pages || [])
    .map((page) => page.data)
    .flat()

  const filteredApplications = allApplications
    // Filter out applications based on the selected category
    .filter(
      (app: any) =>
        selectedCategory === 'all' ||
        app.category.toLowerCase() === selectedCategory
    )
    //Filter out applications based on the search query
    .filter((app: any) =>
      searchQuery.length > 2
        ? app.name.toLowerCase().includes(searchQuery.toLowerCase())
        : true
    )
    .sort((a: any, b: any) => {
      if (a.isEnabled === b.isEnabled) {
        return a.name.localeCompare(b.name)
      }

      return a.isEnabled ? -1 : 1
    })

  console.log(props.token)

  return (
    <Paper>
      {/* Without using the tabs componet create a grid where we can have a sidebar and a content area to the right */}
      <Grid container spacing={2}>
        <Grid item xs={3} md={4} lg={2}>
          <List>
            <ListSubheader>Categories</ListSubheader>
            {Object.entries(supportedCategories).map(([key, value]) => (
              <ListItemButton
                key={key}
                selected={selectedCategory === key}
                onClick={() => setSelectedCategory(key)}
              >
                {value.icon ? <ListItemIcon>{value.icon}</ListItemIcon> : null}
                <ListItemText primary={value.displayName} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={9} md={8} lg={10}>
          <Grid container padding={2}>
            <Grid item xs={12}>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {categoryDisplayName}
                </Typography>
                <TextField
                  label="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Toolbar>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            spacing={2}
            sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}
          >
            {filteredApplications.map((app: any) => (
              <Grid
                item
                md={11}
                lg={6}
                sx={{ display: 'flex', position: 'relative' }}
                key={app.id}
              >
                <ApplicationCard
                  app={app}
                  locationId={props.locationId}
                  disabled={
                    app.description.toLowerCase().indexOf('coming soon') !== -1
                  }
                  token={props.token}
                  accountId={props.accountId}
                  isDelete={app.isEnabled}
                  handleDeleteApp={props.handleDeleteApp}
                />
              </Grid>
            ))}

            {filteredApplications.length === 0 && (
              <Grid item xs={12}>
                <Typography>
                  {intl.formatMessage({ id: 'description_no_available_apps' })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
