import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { UseQueryResult } from 'react-query/types'

export interface UserApplicationAnalyticsProps {
  application: UseQueryResult<any, unknown>
}

export const UserApplicationAnalytics: FC<UserApplicationAnalyticsProps> = ({
  application,
}) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="overline">Coming Soon</Typography>
    </Grid>
  )
}
