import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { FC } from 'react'
import {
  QueryObserverSuccessResult,
  UseMutationResult,
} from 'react-query/types'

export interface DeliveryCancelDialogProps extends DialogProps {
  delivery: QueryObserverSuccessResult<any, Error>
  cancel: UseMutationResult<any, unknown, any, unknown>
}

export const DeliveryCancelDialog: FC<DeliveryCancelDialogProps> = (props) => {
  const { delivery, cancel, ...rest } = props

  const handleCancelClick = () => {
    cancel.mutate(
      {},
      {
        onSuccess: () => {
          void delivery.refetch()
          props.onClose?.({}, 'backdropClick')
        },
      }
    )
  }

  return (
    <Dialog fullWidth {...rest}>
      <DialogTitle>Delivery Cancel</DialogTitle>
      <DialogContent>
        Are you sure you want to cancel this delivery?
      </DialogContent>
      <DialogActions>
        <Button
          disabled={cancel.isLoading}
          onClick={() => props.onClose?.({}, 'backdropClick')}
        >
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() => handleCancelClick()}
          disabled={cancel.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
