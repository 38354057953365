import { useNavigate, useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useLocations } from '../../hooks/useLocation'
import { DeliveriesTable, DeliveryCreateDialog } from '../../components'
import {
  useAccountDeliveries,
  useAccountDeliveryCreateMutation,
} from '../../hooks/useAccountDeliveries'
import { useAuthStore } from '../../hooks'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'

export const AccountDeliveries: FC = () => {
  let { resellerId } = useParams()
  const navigate = useNavigate()
  const [locationsSearch, setLocationsSearch] = useState<string | undefined>(
    undefined
  )
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const locations = useLocations({
    resellerId,
    filters: { query: locationsSearch },
  })

  /**
   * Create the data query to fetch the orders
   */
  const accountId = useAuthStore((state) => state.accountId)!
  const deliveries = useAccountDeliveries(
    { accountId },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  const createDelivery = useAccountDeliveryCreateMutation({
    accountId,
  })

  /**
   * Handle the close of the create dialog
   */
  const handleOnCreateDialogClose = () => {
    setCreateDialogOpen(false)
  }

  const handleOnRowClick = (delivery: any) => {
    navigate(delivery.id)
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: 'My Account', path: '/app/dashboard' },
    { title: 'Deliveries', path: '/app/deliveries' },
  ]

  return (
    <PageContainer title="Deliveries" breadcrumbs={breadcrumbs}>
      {createDialogOpen && (
        <DeliveryCreateDialog
          open
          onClose={handleOnCreateDialogClose}
          locations={locations}
          onLocationsSearch={(query) => setLocationsSearch(query)}
          createDelivery={createDelivery}
        />
      )}

      {/* Action Bar that alighs the action buttons along the right side */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
        <Button onClick={() => setCreateDialogOpen(true)}>
          Create Delivery
        </Button>
      </Box>

      <DeliveriesTable deliveries={deliveries} onRowClick={handleOnRowClick} />
    </PageContainer>
  )
}
