import {
  Grid,
  InputAdornment,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { Close, SearchOutlined } from '@mui/icons-material'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { CardWithImage } from '../CardWithImage'
import { CommonTextField } from '../CommonTextField'
import { StyledButtonContainerForTable } from '../MUIStyledComponents'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMenuModifierGroupCreate, useModifierGroups } from '../../hooks'
import { MenuModifierGroupCreateEntity } from '../../types'
import { AppLoader } from '../AppLoader'
import { validateMenuModifierGroupEntity } from '../../pages/Locations/CardSelect/Menu/utils'

interface ModifierGroupListTableProps {
  locationId: string
  handleClose: (text: boolean) => void
}

export const ModifierGroupListTable: FC<ModifierGroupListTableProps> = (
  props
) => {
  const { menuId } = useParams()
  const intl = useIntl()

  const [selectedModifierGroupIndex, setSelectedModifierGroupIndex] = useState<
    number | null
  >()
  const [searchedVal, setSearchedVal] = useState('')

  const catalogModifierGroup = useModifierGroups(props.locationId!)

  const modifierGroupCreateMutation = useMenuModifierGroupCreate(
    props.locationId!,
    menuId
  )

  // const modifierCreateMutation = useMenuModifierCreate(
  //   props.locationId!,
  //   menuId
  // )

  const modifierGroupList = catalogModifierGroup.data?.pages
    ?.map((value) => value.data)
    .flat()
  const [menuModifierGroupState, setMenuModifierGroupState] = useState<
    MenuModifierGroupCreateEntity[]
  >([])
  const isValid = useMemo(
    () =>
      menuModifierGroupState
        .map((menu) => validateMenuModifierGroupEntity(menu))
        .every(Boolean, menuModifierGroupState),
    [menuModifierGroupState]
  )

  const selectedModifierGroup = (
    value: any,
    index: any,
    isSelected: boolean
  ) => {
    setSelectedModifierGroupIndex(index)
    if (isSelected) {
      setMenuModifierGroupState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]
        // Add a new item to the array
        newState.push({
          name: value.name,
          catalogModifierGroupId: value.id,
          description: value.description || '',
          modifiers: [],
          maxPermitted: value.maxPermitted || 0,
          minPermitted: value.minPermitted || 0,
        })

        return newState
      })
    } else {
      setMenuModifierGroupState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]

        // Find the index of the item to remove
        const index = newState.findIndex(
          (item) => item.catalogModifierGroupId === value.id
        )

        // Remove the item from the array
        newState.splice(index, 1)
        return newState
      })
    }
  }

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }
  if (modifierGroupCreateMutation.isSuccess) {
    props.handleClose(false)
  }

  if (catalogModifierGroup.isLoading) {
    return <AppLoader />
  }
  const handleSubmit = async () => {
    for (const menuModifierGroup of menuModifierGroupState) {
      //   const ids = [] as any
      //   const modifierPromises = menuModifierGroup.modifiers.map((modifier) => {
      //     const data = {
      //       catalogModifierId: modifier.id,
      //       name: modifier.name,
      //       price: modifier.price,
      //       description: '',
      //     }
      //     return modifierCreateMutation
      //       .mutateAsync(data)
      //       .then((result) => {
      //         ids.push(result.data && result.data.id)
      //       })
      //       .catch((error) => {
      //         // Handle error if needed
      //         console.error('Error creating modifier:', error)
      //       })
      //   })

      // Wait for all modifier creation promises to resolve
      // await Promise.all(modifierPromises)

      // Now you can proceed with modifierGroupCreateMutation
      modifierGroupCreateMutation.mutate(menuModifierGroup, {
        onSuccess: () => {
          props.handleClose(false)
        },
        onError: () => {},
      })
    }
  }

  return (
    <>
      <DialogTitle sx={{ p: 0 }} id="customized-dialog-title">
        {intl.formatMessage({ id: 'action_add_modifier_group' })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.handleClose(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 0 }} dividers>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <CommonTextField
              autoFocus
              placeholder="Search for modifierGroups..."
              onChange={handleChangeText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {modifierGroupList
            ?.filter(
              (row) =>
                !searchedVal.length ||
                (row as any)?.name
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchedVal.toString().toLowerCase())
            )
            ?.map((product, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <CardWithImage
                  onClickCard={selectedModifierGroup}
                  data={product}
                  isRedirect={false}
                  onDelete={() => {}}
                  isEditable={false}
                  isItem={true}
                  multiSelectItems={true}
                  index={index}
                  indexState={selectedModifierGroupIndex}
                />
              </Grid>
            ))}
        </Grid>
        {catalogModifierGroup.hasNextPage && (
          <StyledButtonContainerForTable
            display="flex"
            justifyContent="center"
            py={2}
          >
            <Button
              onClick={() => catalogModifierGroup.fetchNextPage()}
              disabled={catalogModifierGroup.isFetching}
            >
              {catalogModifierGroup.isFetching
                ? `${intl.formatMessage({ id: 'status_loading' })}`
                : `${intl.formatMessage({ id: 'action_load_more' })}`}
            </Button>
          </StyledButtonContainerForTable>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          disabled={!isValid || modifierGroupCreateMutation.isLoading}
          onClick={handleSubmit}
          color="success"
        >
          {intl.formatMessage({ id: 'action_create' })}
        </Button>
      </DialogActions>
    </>
  )
}
