import { TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'

/**
 * CommonTextField component
 * @param props {TextFieldProps}
 * @returns {JSX.Element}
 */
export const CommonTextField: FC<TextFieldProps> = (props) => {
  return <TextField fullWidth {...props} />
}
