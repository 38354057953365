import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { api } from '../services/api'

export interface UserResellerOrderProps {
  resellerId: string
  filters?: {
    query?: string
    status?: string
    fulfillmentTypes?: string
    locationIds?: string[]
    locationNames?: string[]
    id?: any
  }
}

export const useResellerOrders = (
  props: UserResellerOrderProps,
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { resellerId, filters } = props
  const baseKey = ['resellers', resellerId, 'orders', filters]
  if (props.filters && props.filters.query) {
    baseKey.push(props.filters.query)
  }
  return useInfiniteQuery(
    baseKey,
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/orders`, {
          params: {
            ...props.filters,
            nextPageKey: ctx.pageParam,
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageKey
      },
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerOrderById = (
  props: { resellerId: string; orderId: string },
  options: Omit<UseQueryOptions<any, Error, any, any>, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()
  const { resellerId, orderId } = props

  return useQuery(
    ['resellers', resellerId, 'orders', orderId],
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerOrderAudits = (
  props: { resellerId: string; orderId: string },
  options: Omit<UseQueryOptions<any, Error, any, any>, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()
  const { resellerId, orderId } = props

  return useInfiniteQuery(
    ['resellers', resellerId, 'orders', orderId, 'audits'],
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/orders/${orderId}/audits`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerOrderDeliveries = (
  props: { resellerId: string; orderId: string },
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { resellerId, orderId } = props

  return useInfiniteQuery(
    ['resellers', resellerId, 'orders', orderId, 'deliveries'],
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/orders/${orderId}/deliveries`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerLocationOrders = (
  props: { resellerId: string; accountId: string; locationId: string },
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { resellerId, accountId, locationId } = props
  const key = [
    'resellers',
    resellerId,
    'accounts',
    accountId,
    'locations',
    locationId,
    'orders',
  ]
  return useInfiniteQuery(
    key,
    (ctx) =>
      api
        .get(
          `/v1/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}/orders`,
          {
            params: {
              nextPageKey: ctx.pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(ctx.signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageKey
      },
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!token?.access_token,
    }
  )
}
