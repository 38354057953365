import { Box, Button, Grid, InputAdornment, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { EmailOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
import logo from '../../assets/logo.png'
import { CommonTextField, HomeCarousel } from '../../components'
import { useIntl } from 'react-intl'

export const ResetPassword = () => {
  const intl = useIntl()
  const [isEmailValid, setEmailValid] = useState(true)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
    setEmailValid(validateEmail(value))
  }
  const isResetEnabled = email !== '' && isEmailValid
  const validateEmail = (email: string): boolean => {
    return emailRegex.test(email)
  }
  const handleChangeReset = () => {
    navigate('/')
  }

  return (
    <Grid container spacing={0} style={{ minHeight: '100vh' }}>
      <Grid item sm={7} md={7} display={{ xs: 'none', sm: 'block' }}>
        <HomeCarousel />
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        sx={{
          paddingLeft: {
            xs: 2,
            lg: 10,
          },
          paddingRight: {
            xs: 2,
            lg: 10,
          },
          background: 'white',
        }}
      >
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ width: 176, position: 'relative', bottom: '55px' }}
              src={logo}
            />
          </Box>
          <Box>
            <Typography variant="h5" component="h5">
              {intl.formatMessage({ id: 'action_forgot_password' })}
            </Typography>
            <Box sx={{ marginTop: 4 }}>
              <CommonTextField
                label={intl.formatMessage({ id: 'label_email' })}
                value={email}
                onChange={handleEmailChange}
                error={!isEmailValid}
                onKeyDown={(event) => {
                  if (event.key == 'Enter' && isResetEnabled)
                    handleChangeReset()
                }}
                helperText={
                  !isEmailValid
                    ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
                    : ' '
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <Button
                onClick={handleChangeReset}
                sx={{ height: '50px' }}
                disabled={!isResetEnabled}
                fullWidth
                size="large"
              >
                {intl.formatMessage({ id: 'action_reset_password' })}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 4,
              }}
            >
              <Typography
                variant="body1"
                component={'span'}
                color={'primary'}
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
              >
                {intl.formatMessage({ id: 'misc_back_to_login' })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
