import { Paper } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { DeliveriesTable, OrderAuditLogs } from '../../components'
import { useOrderAudit, useOrderByIdNew, useOrderDeliveries } from '../../hooks'

// v1
import { OrderView } from '../../components/OrderView'

export function OrderDetails() {
  const { orderId } = useParams()
  const navigate = useNavigate()
  /**
   * Order Loader
   */
  const order = useOrderByIdNew(
    { orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  const deliveries = useOrderDeliveries(
    { orderId: orderId!, locationId: order.data?.data.locationId },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  /**
   * Audit Logs Laoder
   */
  const logs = useOrderAudit(
    { orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  const handleOnRowClick = (delivery: any) => {
    navigate(`/app/deliveries/${delivery.id}`)
  }

  return (
    <>
      <OrderView order={order} />
      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        {deliveries.isSuccess &&
          deliveries.data.pages.length > 0 &&
          deliveries.data.pages[0].data.length !== 0 && (
            <DeliveriesTable
              deliveries={deliveries}
              onRowClick={handleOnRowClick}
            />
          )}
      </Paper>
      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        <OrderAuditLogs logs={logs} order={order} />
      </Paper>
    </>
  )
}
