import {
  Paper,
  CardContent,
  TableContainer,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useUserApplications } from '../../hooks'
import { FormattedDate } from 'react-intl'
import { CopyToClipboard } from '../../components/CopyToClipboard'
import { AddNewIcon } from '../../components'
import { Article } from '@mui/icons-material'
import { PageContainer } from '../../components/Page/PageContainer'

interface Column {
  id: 'name' | 'id' | 'category' | 'createdAt' | 'actions' | 'delete'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: any) => JSX.Element
}
const columns: Column[] = [
  { id: 'category', label: 'Type', minWidth: 70 },
  { id: 'name', label: 'Name', minWidth: 170 },
  {
    id: 'id',
    label: 'ID',
    minWidth: 100,
    format: (value) => <CopyToClipboard value={value}>{value}</CopyToClipboard>,
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 170,
    format: (value) => (
      <FormattedDate
        value={new Date(value)}
        year="numeric"
        month="long"
        day="2-digit"
      />
    ),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    format: (value) => (
      <Box>
        {/* Audit Log Action */}
        <IconButton size="small">
          <Article />
        </IconButton>
      </Box>
    ),
  },
]

export function Developer() {
  const navigate = useNavigate()
  const applications = useUserApplications()

  if (applications.isLoading) return <div>Loading...</div>

  return (
    <PageContainer
      title="My Applications"
      toolbar={<AddNewIcon handleNavigate={() => navigate('new')} />}
    >
      {applications.data?.data.length === 0 ? (
        <Paper sx={{ marginTop: 2, marginBottom: 5 }}>
          <CardContent>
            <Typography align="center" style={{ marginTop: 15 }}>
              Hey, it seems you do not have any applications yet. Click here to
              create your first application.
            </Typography>
          </CardContent>
        </Paper>
      ) : (
        <Paper sx={{ marginTop: 5, marginBottom: 5 }}>
          <CardContent>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ background: 'white' }}>
                  {applications.data.data.map((app: any, index: number) => {
                    return (
                      <TableRow onClick={() => navigate(app.id)}>
                        <TableCell>{app.category}</TableCell>
                        <TableCell>{app.name}</TableCell>
                        <TableCell>
                          <CopyToClipboard value={app.id}>
                            {app.id}
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell>
                          <FormattedDate
                            value={new Date(app.createdAt)}
                            year="numeric"
                            month="long"
                            day="2-digit"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation()
                              navigate(`${app.id}/audits`)
                            }}
                          >
                            <Article />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Paper>
      )}
    </PageContainer>
  )
}
