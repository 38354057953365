import { FC } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { Chip, MenuItem, TextField } from '@mui/material'
import { getCountryDataList, ICountryData } from 'countries-list'

/**
 * Global instance of the immutable country data list
 */
const countryDataList = getCountryDataList()

/**
 *
 */
export interface CountryPickerProps
  extends Omit<
    BasePickerProps<ICountryData, true, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const CountryPicker: FC<CountryPickerProps> = (props) => {
  return (
    <BasePicker
      options={countryDataList}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.iso2 === value.iso2}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.name}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.multiple ? 'Select Countries' : 'Select Country'}
        />
      )}
      {...props}
    />
  )
}
