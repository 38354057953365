import {
  InfoOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Radio,
  TextField,
  Tooltip,
  ButtonGroup,
  Switch,
  Collapse,
  Divider,
} from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { CommonTextField, ServiceAvailabilityInput } from '../../../components'
import { SelectTimezone } from '../../../components/SelectTimezone'
import { fetchTimeZoneData, findIdByName, findNameById } from '../../util'
import {
  generateTimePeriods,
  validateEmail,
  validateFirstName,
  validateLocationEntity,
  validateResellerLocationEntity,
} from '../utils'
import { useResellerAccounts } from '../../../hooks/useResellerAccounts'
import { useParams } from 'react-router-dom'
import {
  ApplicationPicker,
  GoogleAddressPicker,
} from '../../../components/Pickers'
import { BasePicker } from '../../../components/Pickers/BasePicker'
import { useAccountLocationApplications, useAuthStore } from '../../../hooks'
import countryToCurrency from 'country-to-currency'

const iconRadio = <RadioButtonUnchecked fontSize="small" />
const iconRadioChecked = <RadioButtonChecked fontSize="small" />
// Function to convert formatted time period string to seconds
const convertTimePeriodToSeconds = (value: any) => {
  if (value === 'Disable') return 0

  const [hoursPart, minutesPart] = value.split(' (hours) ')
  const hours = parseInt(hoursPart) || 0
  const minutes = minutesPart ? parseInt(minutesPart.split(' ')[0]) : 0

  return hours * 3600 + minutes * 60
}
export const LocationEditForm = (props: any) => {
  const {
    locationId,
    handleLocationChange,
    myInstance,
    setMyInstance,
    isAdminAccess,
    autoCompletePeriod,
    setAutoCompletePeriod,
    handleAddreesState,
    timeZoneFetchError,
    handleTimeZoneData,
    onCancelClick,
    showAccounts,
  } = props
  const intl = useIntl()
  const currencies = ['GBP', 'USD', 'EUR', 'NZD', 'AUD', 'CAD']
  const { resellerId } = useParams()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const applications = useAccountLocationApplications(accountId!, locationId!)
  const timePeriods = generateTimePeriods()
  const accounts: any = showAccounts
    ? useResellerAccounts({ resellerId }!, {
        onSuccess(data: any) {
          const d = data.pages.length
          if (data.pages[d - 1].hasNextPage) {
            void accounts.fetchNextPage()
          }
        },
      })
    : null
  const accountsList = accounts?.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const accountNames = accountsList?.map((account: any) => account.name)
  const isValid = showAccounts
    ? validateResellerLocationEntity(myInstance)
    : validateLocationEntity(myInstance)
  const selectedAccountName = myInstance?.accountId
    ? findNameById(myInstance?.accountId, accountsList)
    : null

  const [isValidFirstName, setValidFirstName] = useState(true)
  const [isEmailValid, setEmailValid] = useState(true)
  const [enabled, setEnabled] = useState(false)

  const handleChangeAccount = (e: any, value: string) => {
    const id = findIdByName(value, accountsList)
    setMyInstance((state: any) => ({
      ...state,
      accountId: id,
    }))
  }
  const handleRemoveAddress = () => {
    setMyInstance((state: any) => ({
      ...state,
      address: {
        address: '',
      },
    }))
  }
  const handleChangeLocationName = (event: ChangeEvent<HTMLInputElement>) => {
    setMyInstance((state: any) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleChangefirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value?.trimStart()
    setValidFirstName(validateFirstName(firstName?.trim()))
    setMyInstance((state: any) => ({
      ...state,
      firstName: firstName,
    }))
  }
  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setMyInstance((state: any) => ({
      ...state,
      lastName: event.target.value?.trim(),
    }))
  }
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value?.trim()
    setEmailValid(validateEmail(email))
    setMyInstance((state: any) => ({
      ...state,
      email: email,
    }))
  }
  const handleChangePhoneNumber = (number: any) => {
    const num = number.replace(/\s/g, '')
    setMyInstance((state: any) => ({
      ...state,
      phoneNumber: num,
    }))
  }

  const handleCurrencyChange = (event: any, value: any) => {
    const currencyValue = event.target.textContent
    setMyInstance((state: any) => ({
      ...state,
      currency: currencyValue,
    }))
  }

  const handleChangeTimezone = (timezone: string) => {
    setMyInstance((state: any) => ({
      ...state,
      timezone,
    }))
  }
  const handleCheckBoxChange = (event: any) => {
    handleAddreesState(event.target.checked)
  }
  const handleChangeTimePeriod = (event: any, value: any) => {
    setAutoCompletePeriod(value)
    if (value === 'Disable') {
      setMyInstance((state: any) => ({
        ...state,
        autoCloseOrders: false,
        autoCloseOrdersAfter: undefined,
      }))
    } else {
      const timeSelected = convertTimePeriodToSeconds(value)
      setMyInstance((state: any) => ({
        ...state,
        autoCloseOrders: true,
        autoCloseOrdersAfter: timeSelected,
      }))
    }
  }
  const handleLocationAddress = async (
    place: google.maps.places.PlaceResult
  ) => {
    const timezoneData = await fetchTimeZoneData({
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    })

    handleTimeZoneData(timezoneData)
    const countryComponent = place.address_components?.find((component) =>
      component.types.includes('country')
    )
    const countryCode: any = countryComponent?.short_name || ''
    const currencyCode: any = countryToCurrency[countryCode] || ''
    setMyInstance((state: any) => ({
      ...state,
      address: {
        address: place.formatted_address,
        googlePlaceId: place.place_id,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
        countryCode,
      },
      currency: currencyCode,
    }))
  }

  const onAvailabilityChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      businessHours: availability,
    }))
  }

  // Handle the syncing of collection and delivery service availabilities
  const onAvailabilityCollectionAndDeliveryChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        collection: availability,
        delivery: availability, // Sync with collection
      },
    }))
  }

  // Handle only collection availability changes
  const onAvailabilityCollectionChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        collection: availability,
        ...(enabled && {
          delivery: availability,
        }), // Sync only if enabled
      },
    }))
  }

  // Handle only delivery availability changes
  const onAvailabilityDeliveryChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        delivery: availability,
      },
    }))
  }

  // Toggle the sync between collection and delivery
  const handleLocationEnabled = (event: any) => {
    const isChecked = event.target.checked
    setEnabled(isChecked) // Update the local enabled state

    // Sync collection and delivery availabilities if checked
    if (isChecked) {
      setMyInstance((state: any) => ({
        ...state,
        availability: {
          ...state.availability,
          delivery: state.availability.collection, // Sync delivery with collection
        },
      }))
    }
  }

  return myInstance ? (
    <>
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <Box>
              <Typography variant="h5">
                {!locationId
                  ? `${intl.formatMessage({ id: 'action_create' })}`
                  : `${intl.formatMessage({ id: 'action_update' })}`}{' '}
                {intl.formatMessage({ id: 'label_location' })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Configure location details
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <ButtonGroup>
              <Button onClick={onCancelClick}>
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
              <Button
                onClick={handleLocationChange}
                disabled={!isValid}
                color="success"
              >
                {locationId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          {!isAdminAccess && !locationId ? (
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked={true} />}
                  label={intl.formatMessage({
                    id: 'label_auto_address_fetch',
                  })}
                />
                <Tooltip
                  title={intl.formatMessage({
                    id: 'label_auto_address_fetch_description',
                  })}
                >
                  <InfoOutlined fontSize="small" color="info" />
                </Tooltip>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_location_name' })}
              autoFocus={true}
              onChange={handleChangeLocationName}
              value={myInstance?.name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              value={myInstance?.phoneNumber || ''}
              placeholder="e.g. +1 123-456-7890"
              onChange={handleChangePhoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                color="info"
                size="medium"
                sx={{ width: '100%' }}
                limitTags={1}
                id="order_auto_complete_time_period"
                options={timePeriods}
                onChange={handleChangeTimePeriod}
                value={autoCompletePeriod}
                disableClearable
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: 0 }}>
                    <Radio
                      color="info"
                      icon={iconRadio}
                      checkedIcon={iconRadioChecked}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'label_auto_complete_orders',
                    })}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'label_auto_complete_orders_description',
                          })}
                        >
                          <InfoOutlined fontSize="small" color="info" />
                        </Tooltip>
                      ),
                      sx: {
                        '& .MuiInputBase-root': {
                          borderBottom: 'none', // This removes the underline
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          {showAccounts && (
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  color="info"
                  size="medium"
                  sx={{ width: '100%' }}
                  limitTags={1}
                  id="accountsList"
                  disabled={!showAccounts}
                  options={accountNames || []}
                  onChange={handleChangeAccount}
                  value={selectedAccountName || ''}
                  disableClearable
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                      <Radio
                        color="info"
                        icon={iconRadio}
                        checkedIcon={iconRadioChecked}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: 'action_select_account',
                      })}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <SelectTimezone
              myInstance={myInstance}
              onChange={(v) => handleChangeTimezone(v.target.value)}
              errorOnAutoFetch={timeZoneFetchError}
              disableEnable={
                locationId != null && !isAdminAccess ? true : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={showAccounts ? 6 : 4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_first_name' })}
              onChange={handleChangefirstName}
              value={myInstance?.firstName || ''}
              error={!isValidFirstName}
              helperText={
                !isValidFirstName
                  ? `${intl.formatMessage({ id: 'misc_name_min_length' })}`
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={showAccounts ? 6 : 4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_last_name' })}
              onChange={handleChangeLastName}
              value={myInstance?.lastName || ''}
            />
          </Grid>
          {/* <Grid item xs={12} sm={showAccounts ? 6 : 4}>
            <Autocomplete
              value={myInstance?.currency ?? ''}
              disableClearable
              options={currencies}
              getOptionLabel={(currency: any) => currency}
              onChange={handleCurrencyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: 'action_select_currency' })}
                  placeholder={intl.formatMessage({
                    id: 'action_select_currency',
                  })}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={showAccounts ? 6 : 4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_email' })}
              placeholder="example@domain.com"
              onChange={handleChangeEmail}
              value={myInstance?.email || ''}
              error={!isEmailValid}
              helperText={
                !isEmailValid
                  ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
                  : ' '
              }
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <GoogleAddressPicker
              onPlaceSelected={handleLocationAddress}
              disabled={locationId != null && !isAdminAccess ? true : false}
              disableClearable={false}
              size="medium"
              textFieldProps={{
                label: myInstance?.address?.address ? '' : undefined,
                placeholder:
                  myInstance?.address?.address ||
                  intl.formatMessage({
                    id: 'label_billing_address',
                  }),
              }}
            />
            {/* <AutoCompleteAddress
              disableEnable={
                locationId != null && !isAdminAccess ? true : false
              }
              placeholderName={intl.formatMessage({
                id: 'label_billing_address',
              })}
              formattedAddress={myInstance?.address?.address || ''}
              handleChangeAddress={handleLocationAddress}
              removeAddress={handleRemoveAddress}
            /> */}
          </Grid>

          <Grid item xs={12} spacing={2}>
            <Typography variant="h5">Store vailability</Typography>
            <Typography variant="body2" color="textSecondary">
              Configure the stores opening and closing times
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {myInstance?.businessHours?.length ? (
            <>
              <Grid item xs={12} sm={6}>
                <ServiceAvailabilityInput
                  availabilities={myInstance?.businessHours}
                  onChange={onAvailabilityChange}
                  isMandatory={true}
                  title={intl.formatMessage({ id: 'label_business_hours' })}
                  setLocationState={setMyInstance}
                  fullWidth={true}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabled} // Use local `enabled` state
                      onChange={handleLocationEnabled} // Toggle local state
                    />
                  }
                  label="Use the same service availability for both collection and delivery."
                />
              </Grid>
              {!enabled ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <ServiceAvailabilityInput
                      availabilities={myInstance?.availability?.collection}
                      onChange={onAvailabilityCollectionChange}
                      isMandatory={true}
                      title={'Collection Hours'}
                      type={'location'}
                      setLocationState={setMyInstance}
                      fullWidth={true}
                      enableDefault={locationId ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ServiceAvailabilityInput
                      availabilities={myInstance?.availability?.delivery}
                      onChange={onAvailabilityDeliveryChange}
                      isMandatory={true}
                      title={'Delivery Hours'}
                      type={'location'}
                      setLocationState={setMyInstance}
                      fullWidth={true}
                      enableDefault={locationId ? false : true}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6}>
                  <ServiceAvailabilityInput
                    availabilities={myInstance?.availability?.collection}
                    onChange={onAvailabilityCollectionAndDeliveryChange}
                    isMandatory={true}
                    title={'Delivery & Collection Hours'}
                    type={'location'}
                    setLocationState={setMyInstance}
                    fullWidth={true}
                    enableDefault={locationId ? false : true}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h5">Delivery Settings</Typography>
            <Typography variant="body2" color="textSecondary">
              Configure the delivery settings for this location
            </Typography>
          </Grid>

          {/* Enable auto create delivery when self delivery is received */}
          <Grid item xs={9}>
            <Typography variant="body1">Auto Create Deliveries</Typography>
            <Typography variant="body2" color="textSecondary">
              When enabled, all orders marked as SELF_DELIVERY will will have a
              corresponding delivery created automatically.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <Switch
              checked={myInstance?.autoCreateDeliveryOnDeliveryOrder}
              onChange={(e) =>
                setMyInstance((state: any) => ({
                  ...state,
                  autoCreateDeliveryOnDeliveryOrder: e.target.checked,
                }))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Enable auto dispatch of newly created deliveries */}
          <Grid item xs={9}>
            <Typography variant="body1">Auto Dispatch Deliveries</Typography>
            <Typography variant="body2" color="textSecondary">
              When enabled, deliveries automatically created will be dispatched
              based on the dispatch settings, if an delivery is being created
              manually, it will pre-enable the dispatch option within the create
              delivery form.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <Switch
              checked={myInstance?.deliveryAutoDispatchEnabled}
              onChange={(e) =>
                setMyInstance((state: any) => ({
                  ...state,
                  deliveryAutoDispatchEnabled: e.target.checked,
                }))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* deliveryDefaultFailureAction, possible options are DISPOSE, RETURN */}
          <Grid item xs={9}>
            <Typography variant="body1">Default Failure Action</Typography>
            <Typography variant="body2" color="textSecondary">
              The default action to take when a driver is unable to deliver the
              package, by default we request the driver dispose of the package,
              if this option is enabled, the driver will be requested to return
              the package to the location, this may occur additional changes.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <BasePicker
              fullWidth
              options={['DISPOSE', 'RETURN']}
              defaultValue={'DISPOSE'}
              value={myInstance?.deliveryDefaultFailureAction}
              renderInput={(params) => (
                <TextField {...params} label="Default Failure Action" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* deliveryAutoDispatchSelectionStrategy, Options are RANK or PRICE, When RANK is enabled the user will be able to order the delivery application in the form below */}
          <Grid item xs={9}>
            <Typography variant="body1">Quote Selection Strategy</Typography>
            <Typography variant="body2" color="textSecondary">
              When enabled, the delivery selection will be based on the price of
              the delivery, the lowest price delivery will be selected first.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <BasePicker
              fullWidth
              disableClearable
              options={['RANK', 'PRICE']}
              value={myInstance?.deliveryAutoDispatchSelectionStrategy}
              onChange={(e, value) =>
                setMyInstance((state: any) => ({
                  ...state,
                  deliveryAutoDispatchSelectionStrategy: value,
                }))
              }
              defaultValue={'PRICE'}
              renderInput={(params) => (
                <TextField {...params} label="Delivery Selection Strategy" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Specific settings for when the mode is RANK */}
          <Grid item xs={9}>
            <Typography variant="body1">Provider Rank</Typography>
            <Typography variant="body2" color="textSecondary">
              When the delivery selection strategy is set to RANK, the provider
              rank will be used to determine the which provider will be auto
              selected when creating a delivery.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <ApplicationPicker
              query={applications}
              filterOptions={(options) =>
                options.filter(
                  (option) => option.category === 'DELIVERY' && option.isEnabled
                )
              }
              noOptionsText="No applications installed"
              fullWidth
              disableClearable
              disableCloseOnSelect
              multiple={true}
              value={applications.data?.pages
                .map((page) =>
                  page.data?.filter((app: any) =>
                    myInstance?.deliveryAutoDispatchSelectionRanking?.includes(
                      app.id
                    )
                  )
                )
                .flat()}
              disabled={
                myInstance?.deliveryAutoDispatchSelectionStrategy !== 'RANK'
              }
              onChange={(e, value): any[] => {
                console.log({ value })
                return setMyInstance((state: any) => ({
                  ...state,
                  deliveryAutoDispatchSelectionRanking: value.map(
                    ({ id }: any) => id
                  ),
                }))
              }}
              renderInput={(params) => (
                <TextField {...params} label="Provider Rank" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* deliveryAutoDispatchFailoverEnabled, this allows the system to auto re-assign the delivery to a secondary provider when the first provider fails to send a driver */}
          <Grid item xs={9}>
            <Typography variant="body1">Automated Failover</Typography>
            <Typography variant="body2" color="textSecondary">
              If a provider fails to dispatch a cuirrer within the allowed grace
              period, the system will automatically re-assign the delivery to a
              secondary provider (if available).
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <Switch
              defaultChecked={false}
              checked={myInstance?.deliveryAutoDispatchFailoverEnabled}
              onChange={(e) =>
                setMyInstance((state: any) => ({
                  ...state,
                  deliveryAutoDispatchFailoverEnabled: e.target.checked,
                }))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* deliveryAutoDispatchFailoverGraceTime, The number of minutes between 5 and 15  */}
          <Grid item xs={9}>
            <Typography variant="body1">Failover Grace Time</Typography>
            <Typography variant="body2" color="textSecondary">
              The number of minutes between 5 and 15 that the system will wait
              before re-assigning a delivery to a secondary provider.
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <BasePicker
              fullWidth
              options={[5, 10, 15]}
              value={myInstance?.deliveryAutoDispatchFailoverGraceTime}
              getOptionLabel={(option) => `${option} minutes`}
              defaultValue={5}
              disableClearable
              disabled={!myInstance?.deliveryAutoDispatchFailoverEnabled}
              onChange={(e, value) =>
                setMyInstance((state: any) => ({
                  ...state,
                  deliveryAutoDispatchFailoverGraceTime: value,
                }))
              }
              renderInput={(params) => (
                <TextField {...params} label="Failover Grace Time" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  ) : null
}
