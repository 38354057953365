import { FC, PropsWithChildren } from 'react'
import { ThemeProvider as BaseThemeProvider } from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { darkTheme, lightTheme } from './poshub'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

/**
 * Supported Theme Modes
 */
export type ThemeMode = 'light' | 'dark' | 'system'

/**
 * Theme Store Interface
 */
export interface ThemeStore {
  theme?: ThemeMode
  setTheme: (mode: ThemeMode) => void
}

/**
 * Theme Store
 */
export const useThemeStore = create<ThemeStore>()(
  persist<ThemeStore>((set) => ({ setTheme: (theme) => set({ theme }) }), {
    name: 'theme-storage',
  })
)

/**
 * Theme Provider
 */
export interface ThemeProviderProps {}

/**
 * ThemeProvider
 */
export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = (
  props
) => {
  const isDarkPrefered = useMediaQuery('(prefers-color-scheme: dark)')
  const [theme] = useThemeStore((state) => [state.theme])
  let selectedTheme = lightTheme

  switch (theme) {
    case 'light':
      selectedTheme = lightTheme
      break
    case 'dark':
      selectedTheme = darkTheme
      break
    default:
      selectedTheme = isDarkPrefered ? darkTheme : lightTheme
      break
  }

  /**
   * Get the system color mode (light or dark)
   */
  return (
    <BaseThemeProvider theme={selectedTheme}>
      <CssBaseline enableColorScheme={true} />
      {props.children}
    </BaseThemeProvider>
  )
}
