import { FC } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// import { Link as ToolpadLink } from '../shared/Link'
// import { getItemTitle } from '../shared/navigation'
// import { useActivePage } from '../useActivePage'
import { PageHeaderToolbar } from './PageHeaderToolbar'
import type { Breadcrumb } from './PageContainer'
import { styled } from '@mui/material'

/**
 *
 */
export const PageContentHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: theme.spacing(1),
  gap: theme.spacing(2),
}))

export interface PageHeaderProps {
  /**
   * The title of the page. Leave blank to use the active page title.
   */
  title?: string
  /**
   * The breadcrumbs of the page. Leave blank to use the active page breadcrumbs.
   */
  breadcrumbs?: Breadcrumb[]

  /**
   * The components used for the toolbar
   */
  toolbar?: React.ReactNode
}

/**
 * A header component to provide a title and breadcrumbs for your pages.
 */
export const PageHeader: FC<PageHeaderProps> = ({
  breadcrumbs,
  title,
  toolbar,
}) => {
  return (
    <Stack>
      <Breadcrumbs maxItems={2} itemsBeforeCollapse={1} itemsAfterCollapse={1}>
        {breadcrumbs?.map((item, index) => {
          const isLast = index === breadcrumbs.length - 1
          if (isLast) {
            return (
              <Typography key={item.path} color="text.primary">
                {item.title}
              </Typography>
            )
          }
          return (
            <Link
              key={item.path}
              component={RouterLink}
              sx={{ marginLeft: 0 }}
              underline="hover"
              color="inherit"
              variant="caption"
              to={item.path}
            >
              {item.title}
            </Link>
          )
        })}
      </Breadcrumbs>
      <PageContentHeader>
        {title && <Typography variant="h4">{title}</Typography>}
        {toolbar && <PageHeaderToolbar>{toolbar}</PageHeaderToolbar>}
      </PageContentHeader>
    </Stack>
  )
}
