import { OrdersTable } from '../../components/OrdersTable'
import { OrderFilter } from './OrderFilterDrawer'
import { useQueryString } from '../../hooks/useQueryString'
import { useNavigate } from 'react-router-dom'
import { useAccountOrders, useLocations } from '../../hooks'
import { useState } from 'react'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'

export function Orders() {
  // Use the useQueryString hook to get the query parameters from the URL as state
  // to use with the order filtering, the order filter will update hte url and the url will update
  // the orders table
  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())
  const [locationsSearch, setLocationsSearch] = useState<string | undefined>(
    undefined
  )
  const locations = useLocations({
    filters: { query: locationsSearch },
  })

  const breadcrumbs: Breadcrumb[] = [
    { title: 'My Account', path: '/app/account' },
    { title: 'Orders', path: '/app/account/orders' },
  ]

  return (
    <PageContainer title="Orders" breadcrumbs={breadcrumbs}>
      <OrderFilter
        onApply={(data: any) => setQuery(data)}
        onReset={() => setQuery({})}
        queryParams={queryParams}
        locationsQuery={locations}
        onLocationsSearch={(query: string) => setLocationsSearch(query)}
      />
      <OrdersPage queryParams={queryParams} />
    </PageContainer>
  )
}

const OrdersPage = ({ queryParams }: { queryParams: any }) => {
  const filters = { ...queryParams }
  /**
   * Create the data query to fetch the orders
   */
  const orders = useAccountOrders(
    { filters },
    { refetchInterval: 10000, keepPreviousData: true }
  )
  return <OrdersTable orders={orders} />
}
