import { OrdersTable } from '../../../components/OrdersTable'
import { OrderFilter } from '../../../pages/Orders/OrderFilterDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import { useResellerOrders } from '../../../hooks'
import { useQueryString } from '../../../hooks/useQueryString'
import { useResellerLocations } from '../../../hooks/useResellerLocations'
import { useState } from 'react'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export function ResellerOrders() {
  let [queryParams, setSearchParams] = useQueryString(
    window.location,
    useNavigate()
  )
  const { resellerId } = useParams()
  const [query, setQuery] = useState(queryParams.query)
  const locations = useResellerLocations({
    resellerId: resellerId!,
    filters: { query },
  })

  const breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Orders', path: `/app/resellers/${resellerId}/orders` },
  ]

  return (
    <PageContainer title="Orders" breadcrumbs={breadcrumbs}>
      <OrderFilter
        onApply={(data: any) => setSearchParams(data)}
        onReset={() => setSearchParams({})}
        queryParams={queryParams}
        locationsQuery={locations}
        onLocationsSearch={(query: string) => setQuery(query)}
      />
      <OrdersPage queryParams={queryParams} />
    </PageContainer>
  )
}

const OrdersPage = ({ queryParams }: { queryParams: any }) => {
  const { resellerId } = useParams()
  const filters = { ...queryParams }
  /**
   * Create the data query to fetch the orders
   */
  const orders = useResellerOrders(
    { filters, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  return <OrdersTable orders={orders} />
}
