import { FC, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { AppLoader, AppWarningDialog } from '../../../components'
import {
  useAccountLocationApplications,
  useDeleteApplicationConnectionById,
} from '../../../hooks/useApplication'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAuthStore } from '../../../hooks'
import { Applications } from './Applications'

export interface ApplicationsViewProps {}
export interface AppDataProps {
  data: any
}

/**
 * Applications
 */
export const ApplicationsPage: FC<ApplicationsViewProps> = () => {
  const intl = useIntl()
  const { locationId } = useParams()
  const token = useAuthStore((state) => state.token)
  const [accountId] = useAuthStore((t) => [t.accountId])
  const applications = useAccountLocationApplications(accountId!, locationId!)
  const appDeleteMutation = useDeleteApplicationConnectionById()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedApp, setSelectedApp] = useState({} as AppDataProps)
  const [applicationsData, setApplicationsData] = useState([] as any)

  const allApplications = applications.data?.pages
    .map((page) => page.data)
    .flat()

  useEffect(() => {
    if (allApplications) {
      let appData: any[] = []
      allApplications.map((item: any) => {
        const data = {
          ...item,
          isVisible: true,
        }
        appData.push(data)
      })
      setApplicationsData(appData)
    }
  }, [applications.data])

  // This function is used to set the visibility of the application instentlly in UI
  const setVisibilityHandler = (app: any) => {
    const index = applicationsData.findIndex(
      (item: any) => item.id === app.data.id
    )
    if (index !== -1) {
      applicationsData[index].isVisible = !applicationsData[index].isVisible
    }
  }
  const handleDeleteApp = (app: any) => {
    setShowConfirmationDialog(true)
    setSelectedApp({ data: { ...app } })
  }
  const handleDeleteConfirmed = () => {
    setShowConfirmationDialog(false)
    setVisibilityHandler(selectedApp)
    appDeleteMutation.mutate(
      { locationId: locationId!, appId: selectedApp.data.id },
      {
        onError: () => {
          setVisibilityHandler(selectedApp)
        },
      }
    )
  }
  const handleDeleteCancel = () => {
    setShowConfirmationDialog(false)
  }
  /**
   * Show Loader
   */
  if (applications.isLoading) {
    return <AppLoader />
  }
  if (applications.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  /**
   * V2
   */
  return (
    <>
      <Applications
        applications={applications}
        locationId={locationId!}
        token={token}
        accountId={accountId!}
        handleDeleteApp={handleDeleteApp}
      />
      {showConfirmationDialog && (
        <AppWarningDialog
          name={selectedApp?.data?.name}
          source="Applications"
          open={showConfirmationDialog}
          onConfirm={handleDeleteConfirmed}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  )
}
