import { FC, ReactElement } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import {
  Home,
  UTurnLeft,
  CheckCircle,
  PersonSearch,
  PersonPin,
  LocalShipping,
  Store,
} from '@mui/icons-material'

type DriverStatus =
  | 'UNASSIGNED'
  | 'ASSIGNED'
  | 'ON_ROUTE_TO_PICKUP'
  | 'AT_PICKUP'
  | 'ON_ROUTE_TO_DROP_OFF'
  | 'AT_DROP_OFF'
  | 'RETURNING'
  | 'COMPLETED'

const driverStatusIcons: Record<
  DriverStatus,
  { icon: ReactElement; label: string }
> = {
  UNASSIGNED: { icon: <PersonSearch />, label: 'Unassigned' },
  ASSIGNED: { icon: <PersonPin />, label: 'Assigned' },
  ON_ROUTE_TO_PICKUP: { icon: <LocalShipping />, label: 'On Route to Pickup' },
  AT_PICKUP: { icon: <Store />, label: 'At Pickup' },
  ON_ROUTE_TO_DROP_OFF: {
    icon: <LocalShipping />,
    label: 'On Route to Dropoff',
  },
  AT_DROP_OFF: { icon: <Home />, label: 'At Drop-off' },
  RETURNING: { icon: <UTurnLeft />, label: 'Returning' },
  COMPLETED: { icon: <CheckCircle />, label: 'Completed' },
}

const driverStatusKeys = Object.keys(driverStatusIcons) as DriverStatus[]

interface DeliveryStatusDotsProps {
  status: DriverStatus
}

const TimelineContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
})

const Line = styled(Box)({
  flexGrow: 1,
  height: 2,
  backgroundColor: '#ccc',
  zIndex: 0,
})

export const DeliveryStatusDots: FC<DeliveryStatusDotsProps> = (props) => {
  return (
    <TimelineContainer>
      {driverStatusKeys.map((status, index) => {
        const isActive = driverStatusKeys.indexOf(props.status) >= index
        const isCurrentActive = props.status === status
        const { icon, label } = driverStatusIcons[status]

        if (status === 'RETURNING' && props.status !== 'RETURNING') return null
        return (
          <>
            <Tooltip
              placement="top"
              title={<Typography>{label}</Typography>}
              arrow
              sx={{
                width: isCurrentActive ? 36 : 20,
                height: isCurrentActive ? 36 : 20,
              }}
              color={isActive ? 'success' : 'secondary'}
            >
              {icon}
            </Tooltip>
            {index < driverStatusKeys.length - 1 && <Line />}
          </>
        )
      })}
    </TimelineContainer>
  )
}
