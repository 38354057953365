import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from '@mui/material'
import { CommonTextField } from '../CommonTextField'
import { useIntl } from 'react-intl'
import { ChangeEvent, useState } from 'react'
import { RenderListView } from '../../pages/MenuV2/dialogs/ListView'
import { useNavigate, useParams } from 'react-router-dom'
import { MenuModifierGroupPatchEntity } from '../../types'
import {
  validateMinMaxValues,
  validationCheckUpdateforModifierGroup,
} from '../../pages/Locations/CardSelect/Menu/utils'

export const ModifierGroupEditForm = (props: any) => {
  const {
    modifierGroupState,
    modifierOptions,
    handleModifierGroupUpdate,
    handleNavigation,
    showModifierEdit,
  } = props
  const intl = useIntl()
  const { itemId, modifierGroupId } = useParams()
  const [updatedState, setUpdatedState] =
    useState<MenuModifierGroupPatchEntity>({})
  const navigate = useNavigate()
  const [valid, setValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const selectedModifiers = (
    updatedState?.modifiers ??
    modifierGroupState.modifiers ??
    []
  )?.map((modifierId: any) => {
    const selectedModifiers = modifierOptions?.find(
      (option: any) => option.id === modifierId
    )
    return selectedModifiers
  })

  function validationCheckUpdate(object: any) {
    const validationResults = validationCheckUpdateforModifierGroup(object)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate(newObject)
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      updatedState.minPermitted = inputValue !== '' ? parseInt(inputValue) : 0
      updatedState.maxPermitted =
        updatedState.maxPermitted ?? modifierGroupState?.maxPermitted
      validateAndSet(updatedState)
      const minMaxValidation = validateMinMaxValues(
        parseInt(inputValue),
        updatedState.maxPermitted ?? modifierGroupState?.maxPermitted
      )
      if (!minMaxValidation) {
        setErrorMessage('Minimum cannot be greater than maximum')
      } else {
        setErrorMessage('')
      }
    }
  }
  const handleMaximumChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      updatedState.maxPermitted = inputValue !== '' ? parseInt(inputValue) : 0
      updatedState.minPermitted =
        updatedState.minPermitted ?? modifierGroupState?.minPermitted
      validateAndSet(updatedState)
      const minMaxValidation = validateMinMaxValues(
        updatedState.minPermitted ?? modifierGroupState?.minPermitted,
        parseInt(inputValue)
      )
      if (!minMaxValidation) {
        setErrorMessage('Minimum cannot be greater than maximum')
      } else {
        setErrorMessage('')
      }
    }
  }
  const handleModifierChange = (_: any, newValues: any) => {
    updatedState.modifiers = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }

  const handleModifierNavigation = (id: string) => {
    const currentUrl = location.pathname // Get the current URL path
    let newUrl = `${currentUrl}/modifier/${id}`
    // Use navigate to change the URL
    navigate(newUrl)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          {modifierGroupId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_modifier_group' })}
        </Typography>
        <Box>
          <Button onClick={() => handleNavigation()} sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleModifierGroupUpdate(updatedState)}
            disabled={!valid}
            color="success"
          >
            {itemId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_name',
              })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? modifierGroupState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              limitTags={2}
              disableCloseOnSelect
              options={modifierOptions ? modifierOptions : []}
              value={
                modifierOptions?.filter((option: any) =>
                  updatedState?.modifiers
                    ? updatedState?.modifiers?.includes(option.id)
                    : modifierGroupState.modifiers?.includes(option.id)
                ) || []
              }
              onChange={handleModifierChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_modifiers',
                  })}`}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({
                id: 'label_min_required',
              })}
              onChange={handleMinimumChange}
              value={
                updatedState?.minPermitted ??
                (modifierGroupState?.minPermitted || 0)
              }
              error={errorMessage !== '' ? true : false}
              helperText={errorMessage ? errorMessage : ' '}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({
                id: 'label_max_permitted',
              })}
              onChange={handleMaximumChange}
              value={
                updatedState.maxPermitted ??
                (modifierGroupState?.maxPermitted || 0)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({
                id: 'label_description',
              })}
              onChange={handleDescriptionChange}
              value={
                updatedState?.description ?? modifierGroupState?.description
              }
              rows={2}
              multiline
            />
          </Grid>
          {showModifierEdit ? (
            (updatedState?.modifiers?.length !== 0 ??
              modifierGroupState?.modifiers?.length !== 0) &&
            modifierOptions?.length ? (
              <RenderListView
                listOptions={selectedModifiers}
                handleNavigation={handleModifierNavigation}
                title={intl.formatMessage({
                  id: 'label_modifiers',
                })}
              />
            ) : null
          ) : null}
        </Grid>
      </Box>
    </>
  )
}
