/**
 * @fileoverview This component is used to display the application avatar.
 */
import { Avatar, AvatarProps, styled } from '@mui/material'

/**
 * Slightly larger avatar for the card.
 */
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  borderRadius: theme.spacing(1),
  filter:
    theme.palette.mode === 'dark' ? 'brightness(.8) contrast(1.2)' : 'none',
}))

/**
 *
 */
export interface CardAvatarProps extends AvatarProps {}

/**
 * @fileoverview CardAvatar component.
 * This component is used to render the application avatar.
 */
export const CardAvatar = (props: CardAvatarProps) => {
  return <StyledAvatar {...props} />
}
