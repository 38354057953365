import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { initParticlesEngine, Particles } from '@tsparticles/react'
import { loadFull } from 'tsparticles'
import { useTheme } from '@mui/material'

export interface AnimatedGraphProps {}

/**
 * AnimatedGraph Component, renders an animated graph over a background image that is injected into the component as children
 */
export const AnimatedGraph: FC<PropsWithChildren<AnimatedGraphProps>> = () => {
  const [init, setInit] = useState(false)
  const AnimatedGraphParticlesMemoized = useMemo(
    () => <AnimatedGraphParticles />,
    [init]
  )

  // this should be run only once per application lifetime
  useEffect(() => {
    void initParticlesEngine((engine) => loadFull(engine)).then(() =>
      setInit(true)
    )
  }, [])

  if (!init) {
    return null
  }

  /**
   * Return a container where we can add both the particles and the children, the container
   * should be setup in a way where the particles are rendered on top of the children (z-index), this
   * is done by setting the position to relative and the children to absolute
   */
  return AnimatedGraphParticlesMemoized
}

export const AnimatedGraphParticles: FC = () => {
  const theme = useTheme()
  return (
    <Particles
      options={{
        autoPlay: true,
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'window',
          events: {
            onClick: { enable: true, mode: 'push' },
            resize: { enable: true, delay: 0.5 },
            onHover: {
              enable: true,
              mode: ['absorber'],
              parallax: { enable: true, force: 95, smooth: 10 },
            },
          },
        },
        particles: {
          color: {
            value: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
          },
          move: {
            center: { x: 50, y: 50, mode: 'percent', radius: 0 },
            enable: true,
            gravity: {
              acceleration: 9.81,
              enable: false,
              inverse: false,
              maxSpeed: 50,
            },
            path: {
              clamp: true,
              delay: { value: 0 },
              enable: false,
              options: {},
            },
            outModes: {
              default: 'out',
              bottom: 'out',
              left: 'out',
              right: 'out',
              top: 'out',
            },
            random: false,
            size: false,
            speed: 2,
            spin: {
              acceleration: 0,
              enable: false,
            },
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fill: {},
            },
            vibrate: false,
            warp: false,
          },
          number: {
            limit: { mode: 'delete', value: 100 },
            value: 50,
          },
          opacity: {
            value: { min: 0.1, max: 0.5 },
            animation: {
              enable: true,
              speed: 0.2,
              sync: true,
              mode: 'auto',
              startValue: 'random',
              destroy: 'none',
            },
          },
          shape: { close: true, fill: true, type: 'circle' },
          size: {
            value: { min: 1, max: 10 },
            animation: {
              enable: true,
              sync: false,
              mode: 'auto',
              startValue: 'random',
              destroy: 'none',
            },
          },
          destroy: {
            mode: 'none',
            split: {
              count: 1,
              factor: { value: 3 },
              rate: { value: { min: 4, max: 9 } },
              sizeOffset: true,
            },
          },
          links: {
            enable: true,
            color: { value: theme.palette.mode === 'dark' ? '#fff' : '#000' },
            distance: 250,
            triangles: { enable: false, frequency: 5 },
            opacity: 0.3,
            width: 0.5,
            warp: true,
          },
        },
        pauseOnBlur: true,
        pauseOnOutsideViewport: true,
      }}
    />
  )
}
