import { FC } from 'react'
import home from '../../assets/left-digital.jpg'

export interface HomeCarouselProps {}
export const HomeCarousel: FC<HomeCarouselProps> = () => {
  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${home})`,
        }}
      ></div>
    </>
  )
}
