import { FC } from 'react'
import { ApplicationEntity } from '../../../types'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { MenuItem, TextField } from '@mui/material'

/**
 * Static list of supported order statuses
 */
const supportedScopes: ApplicationEntity['scopes'] = [
  'accounts.read',
  'accounts.write',
  'locations.read',
  'locations.write',
  'orders.read',
  'orders.write',
  'user.read',
  'user.write',
]

/**
 *
 */
export interface ApplicationScopesPickerProps
  extends Omit<
    BasePickerProps<ApplicationEntity['scopes'][0], true, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const ApplicationScopesPicker: FC<ApplicationScopesPickerProps> = (
  props
) => {
  return (
    <BasePicker
      options={supportedScopes}
      multiple
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={'Application Scopes'} />
      )}
      {...props}
    />
  )
}
