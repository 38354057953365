import { FC, useState, ChangeEvent } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  TextField,
  Grid,
  FormControl,
  FormHelperText,
  Collapse,
} from '@mui/material'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { ApplicationCategoryPicker } from '../../../components/Pickers/ApplicationCategoryPicker'
import { ApplicationCreateEntity } from '../../../types'
import { ApplicationScopesPicker } from '../../../components/Pickers/ApplicationScopePicker'
import { CountryPicker } from '../../../components/Pickers'

/**
 * Define a list of supported country codes
 */
const allowedCountryCodes: Array<string> = [
  'US',
  'CA',
  'MX',
  'GB',
  'AU',
  'NZ',
  'FR',
  'DE',
  'IT',
  'ES',
  'NL',
]

/**
 * DeliveryCreateDialogProps interface
 */
export interface DeliveryCreateDialogProps extends DialogProps {
  profile: UseQueryResult<any, unknown>
  createApplication: UseMutationResult<any, unknown, any>
}

/**
 * UserApplicationCreateDialog component
 */
export const UserApplicationCreateDialog: FC<DeliveryCreateDialogProps> = ({
  profile,
  createApplication,
  onClose,
  ...other
}) => {
  const [selectedCountries, setSelectedCountries] = useState<any[]>([])
  // Local form state
  const [form, setForm] = useState<ApplicationCreateEntity>({
    name: '',
    description: '',
    category: 'MARKETPLACE', // or empty default: ''
    catalogSyncUrl: undefined,
    menuPublicationUrl: undefined,
    scopes: [],
    redirectUris: [],
    webhookEndpoint: undefined,
    allowedCountries: [],
    getDeliveryQuoteUrl: undefined,
    cancelDeliveryUrl: undefined,
    dispatchDeliveryQuoteUrl: undefined,
    getDeliveryUrl: undefined,
  })

  // Common handler for text fields
  const handleChange =
    (field: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prev) => ({ ...prev, [field]: event.target.value }))
    }

  const handleClose = () => {
    onClose?.({}, 'escapeKeyDown')
  }

  const handleCreate = async () => {
    createApplication.mutate(form, {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} {...other}>
      <DialogTitle>Create Application</DialogTitle>

      <DialogContent dividers>
        {/* Form Layout with Location Picker */}
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <ApplicationCategoryPicker
                value={form.category}
                onChange={(event, value) =>
                  setForm((prev) => ({ ...prev, category: value! }))
                }
              />
              <FormHelperText>
                Select the type of application you are creating, this will
                determine what features are available to your application.
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* 
          <Grid item xs={12}>
            <Divider>Application Details</Divider>
          </Grid> */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                label="Application Name"
                placeholder='e.g. "My Awesome App"'
                value={form.name}
                onChange={handleChange('name')}
              />
              <FormHelperText>
                The name of your application, e.g Acme Connector
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                label="Description"
                multiline
                minRows={2}
                value={form.description}
                onChange={handleChange('description')}
              />
              <FormHelperText>
                A brief description of your application
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Supported Countries */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <CountryPicker
                multiple
                value={selectedCountries}
                onChange={(_, value) => {
                  setSelectedCountries(value)
                  setForm((prev) => ({
                    ...prev,
                    allowedCountries: value.map((v) => v.iso2) as any,
                  }))
                }}
                filterOptions={(options, { inputValue }) =>
                  options
                    .filter((v) => allowedCountryCodes.indexOf(v.iso2) !== -1)
                    .filter((o) =>
                      o.name.toLowerCase().includes(inputValue.toLowerCase())
                    )
                }
              />
              <FormHelperText>
                A comma separated list of supported countries, if left empty all
                countries are supported
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Scopes */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <ApplicationScopesPicker
                value={form.scopes}
                onChange={(event, value) =>
                  setForm((prev) => ({ ...prev, scopes: value! }))
                }
              />
              <FormHelperText>
                Select the scopes your application requires, these scopes
                determine what data your application can access.
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Webhook Endpoint */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Webhook Endpoint"
                placeholder="http://example.com"
                value={form.webhookEndpoint}
                onChange={handleChange('webhookEndpoint')}
              />
              <FormHelperText>
                This endpoint will be used to receive all webhook events for
                connected locations, the types of webhook events you will
                receive depends on the application type and scopes you have
                requested.
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Marketplace Callbacks */}
          {form.category === 'MARKETPLACE' && (
            <Grid item xs={12}>
              <Collapse in={true}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    label="Menu Publication URL"
                    placeholder="http://example.com/callback/menu-publication"
                    value={form.menuPublicationUrl}
                    onChange={handleChange('menuPublicationUrl')}
                  />
                  <FormHelperText>
                    This API Will be called synchronously when a location
                    publishes a menu to your application
                  </FormHelperText>
                </FormControl>
              </Collapse>
            </Grid>
          )}

          {/* Catalog Sync Callbacks */}
          {form.category === 'POS' && (
            <Grid item xs={12}>
              <Collapse in={true}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    label="Catalog Pull URL"
                    placeholder="http://example.com"
                    value={form.catalogSyncUrl}
                    onChange={handleChange('catalogSyncUrl')}
                  />
                  <FormHelperText>
                    We will call this endpoint to pull the entire catalog from
                    your POS, this endpoint should return a Catalog Import
                    Structure
                  </FormHelperText>
                </FormControl>
              </Collapse>
            </Grid>
          )}

          {/* Delivery Callbacks */}
          {form.category === 'DELIVERY' && (
            <>
              <Grid item xs={12}>
                <Collapse in={true}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Get Delivery Quote"
                      placeholder="http://example.com/callback/get-quote"
                      value={form.getDeliveryQuoteUrl}
                      onChange={handleChange('getDeliveryQuoteUrl')}
                    />
                    <FormHelperText>
                      This endpoint will be called when a location requests a
                      delivery quote for a delivery job
                    </FormHelperText>
                  </FormControl>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={true}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Dispatch Quote"
                      placeholder="http://example.com/callback/dispatch-quote"
                      value={form.dispatchDeliveryQuoteUrl}
                      onChange={handleChange('dispatchDeliveryQuoteUrl')}
                    />
                    <FormHelperText>
                      If a quote is selected on our platform we will call this
                      endpoint to to confirm the delivery job
                    </FormHelperText>
                  </FormControl>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={true}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Get Delivery"
                      placeholder="http://example.com/callback/get-delivery"
                      value={form.getDeliveryUrl}
                      onChange={handleChange('getDeliveryUrl')}
                    />
                    <FormHelperText>
                      We will call this endpoint to get the latest details for a
                      dispatched delivery
                    </FormHelperText>
                  </FormControl>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={true}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Cancel Delivery"
                      placeholder="http://example.com/callback/cancel-delivery"
                      value={form.cancelDeliveryUrl}
                      onChange={handleChange('cancelDeliveryUrl')}
                    />
                    <FormHelperText>
                      We will call this endpoint to cancel a dispatched delivery
                    </FormHelperText>
                  </FormControl>
                </Collapse>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={createApplication.isLoading}>
          Cancel
        </Button>
        <Button onClick={handleCreate} disabled={createApplication.isLoading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
