import { useMutation, UseMutationResult } from 'react-query'

/**
 * options
 */
export interface UseTwoPhaseUploadOptions {
  /**
   * The mitation that needs to be called with the file metadata to get a signed url
   */
  useSignedUrlMutation: UseMutationResult<any, unknown, any, any>
}

/**
 * useTwoPhaseUpload
 *
 * This hook is used to upload files in two phases: first, a mutation call should be made to the api endpoint that produces a signed url response,
 * then a PUT request should be made to the signed url to upload the file.
 */
export const useTwoPhaseUpload = (options: UseTwoPhaseUploadOptions) => {
  const { useSignedUrlMutation } = options

  return useMutation<Response, any, { file: File }>(
    ({ file }: { file: File }) => {
      const method = 'PUT'
      const headers = { 'Content-Type': file.type }
      const originalFilename = file.name
      const contentType = file.type
      const contentSize = file.size

      return useSignedUrlMutation
        .mutateAsync({ originalFilename, contentType, contentSize })
        .then((response) =>
          fetch(response.data.data, { method, body: file, headers })
        )
    }
  )
}
