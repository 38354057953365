import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { MoreTime, Delete } from '@mui/icons-material'
import { ServiceAvailability } from '../../types'
import { useIntl } from 'react-intl'
import { ClickableIcon } from '../ClickableIcon'

//Default time periods
const defaultTimePeriods = {
  startTime: '17:00',
  endTime: '23:00',
}

/**
 * Service Availability Component Input Props
 */
export interface ServiceAvailabilityInputProps {
  /**
   * List of Service Availabilities that will be pre-rendered
   */
  availabilities?: ServiceAvailability

  /**
   * On Change handler, triggered whenever the user changes the availabilities
   */
  onChange: (availabilities: ServiceAvailability) => void

  /** isMandatory is the to consider this as mandatory field */
  isMandatory?: boolean
  title?: string
  setLocationState?: any
  fullWidth?: boolean
  type?: any
  enableDefault?: boolean
}

/**
 * Component that renders a list of ServiceAvailabilityDay components, one for each day of the week
 * allowing the user to select the days and time ranges for the service.
 */
export const ServiceAvailabilityInput: FC<ServiceAvailabilityInputProps> = (
  props
) => {
  const intl = useIntl()
  const {
    availabilities,
    setLocationState,
    title,
    isMandatory,
    fullWidth,
    type,
    enableDefault = true,
  } = props

  const map: Record<string, ServiceAvailability[number]['timePeriods']> = {
    MONDAY: [{ ...defaultTimePeriods }],
    TUESDAY: [{ ...defaultTimePeriods }],
    WEDNESDAY: [{ ...defaultTimePeriods }],
    THURSDAY: [{ ...defaultTimePeriods }],
    FRIDAY: [{ ...defaultTimePeriods }],
    SATURDAY: [{ ...defaultTimePeriods }],
    SUNDAY: [{ ...defaultTimePeriods }],
  }
  const [previousDayTimings, setPreviousDayTimings] = useState<
    ServiceAvailability[number]['timePeriods']
  >([])
  useEffect(() => {
    if (!availabilities?.length && setLocationState && enableDefault) {
      if (type === 'location') {
        setLocationState((state: any) => ({
          ...state,
          availability: {
            collection: [
              {
                weekday: 'MONDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'TUESDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'WEDNESDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'THURSDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'FRIDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'SATURDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'SUNDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
            ],
            delivery: [
              {
                weekday: 'MONDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'TUESDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'WEDNESDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'THURSDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'FRIDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'SATURDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
              {
                weekday: 'SUNDAY',
                timePeriods: [{ ...defaultTimePeriods }],
              },
            ],
          },
        }))
      } else if (type === 'menu') {
        setLocationState((state: any) => ({
          ...state,
          serviceAvailability: [
            {
              weekday: 'MONDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'TUESDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'WEDNESDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'THURSDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'FRIDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'SATURDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'SUNDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
          ],
        }))
      } else {
        setLocationState((state: any) => ({
          ...state,
          businessHours: [
            {
              weekday: 'MONDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'TUESDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'WEDNESDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'THURSDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'FRIDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'SATURDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
            {
              weekday: 'SUNDAY',
              timePeriods: [{ ...defaultTimePeriods }],
            },
          ],
        }))
      }
    }
  }, [])
  /**
   * Update the local map with the availabilities passed in
   * We do this to ensure that we render the correct time periods for each day of the week
   * even when we are passed in partial availabilities as input
   */
  const availableDays = new Set(availabilities?.map((value) => value.weekday))

  availabilities?.forEach((value) => {
    map[value.weekday] = value.timePeriods
  })

  Object.keys(map).forEach((day) => {
    if (!availableDays.has(day as ServiceAvailability[number]['weekday'])) {
      map[day] = []
    }
  })
  useEffect(() => {
    availabilities?.map((value: any) => {
      if (value?.timePeriods && value?.timePeriods?.length > 0) {
        setPreviousDayTimings([...value?.timePeriods])
      }
    })
  }, [])

  /**
   * Update the availabilities and trigger the onChange handler
   */
  const updateAvailability = (
    weekday: ServiceAvailability[number]['weekday'],
    timePeriods: ServiceAvailability[number]['timePeriods']
  ) => {
    const result: ServiceAvailability[number][] = []

    for (const key in map) {
      const value = map[key]

      result.push({
        weekday: key as ServiceAvailability[number]['weekday'],
        timePeriods: key === weekday ? timePeriods : value,
      })
    }

    props.onChange(result)
  }

  /**
   * Render the list of ServiceAvailabilityDay components
   */
  return (
    <List
      subheader={
        <ListSubheader
          sx={{ padding: 0, position: 'relative', backgroundColor: 'inherit' }}
        >
          {title
            ? title
            : `${intl.formatMessage({
                id: 'label_service_availability',
              })}${' '}`}
          {isMandatory && '*'}
        </ListSubheader>
      }
    >
      {/* Map each day of the week to the ServiceAvailabilityDay component */}
      {Object.keys(map).map((value) => {
        const key = value as ServiceAvailability[number]['weekday']
        return (
          <ServiceAvailabilityDay
            key={key}
            weekday={key}
            availability={map[key!]}
            onChange={(periods) => updateAvailability(key, periods)}
            previousDayTimings={previousDayTimings!}
            setPreviousDayTimings={setPreviousDayTimings}
            fullWidth={props.fullWidth}
          />
        )
      })}
    </List>
  )
}

/**
 * Service Availability Day Component Props, used to render a single generic day within
 */
export interface ServiceAvailabilityDayProps {
  weekday: ServiceAvailability[number]['weekday']
  availability?: ServiceAvailability[number]['timePeriods']
  onChange: (timePeriods: ServiceAvailability[number]['timePeriods']) => void
  previousDayTimings: ServiceAvailability[number]['timePeriods']
  setPreviousDayTimings: (
    timePeriods: ServiceAvailability[number]['timePeriods']
  ) => void
  fullWidth?: boolean
}

export const ServiceAvailabilityDay: FC<ServiceAvailabilityDayProps> = (
  props
) => {
  /**
   * When the user toggles the enabled switch, we reset the availability to either an empty array when turning off
   * or a single time period from 6am to 10pm when turning on.
   */
  const onEnabledChange = (
    event: React.MouseEvent | ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = (event.target as HTMLInputElement).checked

    let newTimings: any[]
    if (isChecked) {
      newTimings =
        props.previousDayTimings && props.previousDayTimings.length > 0
          ? [...props.previousDayTimings]
          : [{ ...defaultTimePeriods }]
    } else {
      newTimings = []
    }

    props.onChange?.(newTimings)
  }

  /**
   * Handle the user clicking the add new button, we add a new time period to the end of the list
   */
  const onAddNew = () => {
    const result = props.availability ? props.availability : []
    let dynamicStart_time =
      props?.availability && props?.availability.length > 0
        ? props.availability[props.availability?.length - 1].startTime
        : defaultTimePeriods?.startTime
    let dynamicEnd_time =
      props?.availability && props?.availability?.length > 0
        ? props.availability[props.availability?.length - 1].endTime
        : defaultTimePeriods?.endTime
    result.push({ startTime: dynamicStart_time, endTime: dynamicEnd_time })
    props.onChange(result)
    props.setPreviousDayTimings(result)
  }

  /**
   * handle the user clicking remove, we remove the time period at the given index
   */
  const onRemove = (index: number) => {
    const result = [...(props.availability || [])]
    result.splice(index, 1)
    props.onChange(result)
    props.setPreviousDayTimings(result)
  }

  /**
   * Handle when an existing time period is changed, we update the time period at the given index
   */
  const onTimePeriodsChange = (
    index: number,
    startTime: string,
    endTime: string
  ) => {
    const result = [...(props.availability || [])]
    result[index] = { startTime: startTime, endTime: endTime }
    props.onChange(result)
    props.setPreviousDayTimings(result)
  }

  /**
   * Helper flag to determine if the switch is on or off is wether or not the availability array is empty
   */
  const isEnabled = props.availability && props.availability.length > 0
  return (
    <>
      <ListItem disablePadding>
        <ListItemIcon>
          <Switch checked={isEnabled} onChange={onEnabledChange} />
        </ListItemIcon>
        <ListItemText primary={props.weekday} onClick={onEnabledChange} />
      </ListItem>
      {/* Map each availabilty to the ServiceAvailabilityTimePeriods component */}
      {props.availability?.map((value, index) => (
        <ServiceAvailabilityTimePeriods
          key={index}
          period={value}
          onChange={(startTime, endTime) =>
            onTimePeriodsChange(index, startTime, endTime)
          }
          onRemove={
            props.availability && index < props.availability?.length
              ? () => onRemove(index)
              : undefined
          }
          onAddNew={
            index + 1 === props.availability?.length ? onAddNew : undefined
          }
          fullWidth={props.fullWidth}
        />
      ))}
    </>
  )
}

/**
 * Service Availability Time Periods Component Props
 */
export interface ServiceAvailabilityTimePeriodsProps {
  period: { startTime?: string; endTime?: string }
  onAddNew?: () => void
  onRemove?: () => void
  onChange: (startTime: string, endTime: string) => void
  fullWidth?: boolean
}

/**
 * Component that renders a single time period, with start and end time pickers
 */
export const ServiceAvailabilityTimePeriods: FC<
  ServiceAvailabilityTimePeriodsProps
> = (props) => {
  return (
    <ListItem>
      <ListItemText inset sx={{ p: 0 }}>
        {props.period && (
          <TimePickerPair
            onAddNew={props.onAddNew}
            onRemove={props.onRemove}
            startTime={props.period.startTime}
            endTime={props.period.endTime}
            onChange={props.onChange}
            fullWidth={props.fullWidth}
          />
        )}
      </ListItemText>
    </ListItem>
  )
}

/**
 * Time Picker Pair Component Props
 */
export interface TimePickerPairProps {
  startTime?: string
  endTime?: string
  onChange: (startTime: string, endTime: string) => void
  onRemove?: () => void
  onAddNew?: () => void
  fullWidth?: boolean
}

/**
 * Component that renders a pair of time pickers, one for start time and one for end time
 */
export const TimePickerPair: FC<TimePickerPairProps> = (props) => {
  const startDate = new Date()
  const endDate = new Date()
  const [hours, minutes] = props.startTime?.split(':') || []
  const [endHours, endMinutes] = props.endTime?.split(':') || []
  startDate.setHours(parseInt(hours || '0'))
  startDate.setMinutes(parseInt(minutes || '0'))
  endDate.setHours(parseInt(endHours || '0'))
  endDate.setMinutes(parseInt(endMinutes || '0'))

  const onStartTimeChange = (date: Date | null) => {
    if (!date || !props.onChange) {
      props.onChange('17:00', props.endTime || '23:00')
      return
    }
    let hours: any = ('0' + date.getHours()).slice(-2)
    let minutes: any = ('0' + date.getMinutes()).slice(-2)
    hours = isNaN(hours) ? 17 : hours
    minutes = isNaN(minutes) ? 0 : minutes
    const newStartTime = `${hours}:${minutes}`
    props.onChange(newStartTime, props.endTime || '23:00')
  }

  const onEndTimeChange = (date: Date | null) => {
    if (!date || !props.onChange) {
      props.onChange(props.startTime || '17:00', '23:00')
      return
    }
    let hours: any = ('0' + date.getHours()).slice(-2)
    let minutes: any = ('0' + date.getMinutes()).slice(-2)
    hours = isNaN(hours) ? 23 : hours
    minutes = isNaN(minutes) ? 0 : minutes
    const newEndTime = `${hours}:${minutes}`
    props.onChange(props.startTime || '17:00', newEndTime)
  }

  return (
    <Grid container>
      <Grid item xs={0} sm={0.3}></Grid>
      <Grid item xs={4.5} sm={props.fullWidth ? 4 : 2.5}>
        <TimePicker
          label="Start Time"
          views={['hours', 'minutes']}
          ampm={true}
          value={startDate}
          onChange={onStartTimeChange}
          slotProps={{ textField: { size: 'small' } }}
          sx={{ margin: '5px' }}
        />
      </Grid>
      <Grid item xs={4.5} sm={props.fullWidth ? 4 : 2.5}>
        <TimePicker
          label="End Time"
          views={['hours', 'minutes']}
          ampm={true}
          value={endDate}
          onChange={onEndTimeChange}
          slotProps={{ textField: { size: 'small' } }}
          sx={{ margin: '5px' }}
        />
      </Grid>
      <Grid item xs={3} sm={props.fullWidth ? 2 : 1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            height: '100%',
            alignItems: 'center',
          }}
        >
          {props.onAddNew && (
            <ClickableIcon
              handleNavigate={props.onAddNew}
              children={<MoreTime />}
            />
          )}
          {props.onRemove && (
            <ClickableIcon
              handleNavigate={props.onRemove}
              children={<Delete />}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
