import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useMenuDelete, useMenus } from '../../../../hooks'
import {
  StyledButtonContainerForTable,
  AppLoader,
  NoRecordFound,
  AppWarningDialog,
  MenuSyncDialog,
  ItemsWarningDialog,
  ClickableIcon,
  CreateButtonFlexEnd,
} from '../../../../components'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { shortenText } from '../../../util'
import { MenuEntity } from '../../../../types'
import { FormattedDateTime } from '../../../../components/FormattedDateTime'

export function Menus() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId } = useParams()
  const menu = useMenus(locationId!)
  const menuDelete = useMenuDelete(locationId!)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [syncDialogId, setSyncDialogId] = useState<string | undefined>(
    undefined
  )
  const [selectedMenu, setSelectedMenu] = useState({} as MenuEntity)
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const records = menu.data?.pages?.map((value) => value.data).flat()

  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 && setShowWarningDialog(true)
    setSyncDialogId(undefined)
  }
  const onCancelItemsWarning = () => {
    setShowWarningDialog(false)
    setDataAfterPublish(undefined)
  }

  const handleDeleteConfirmed = () => {
    menuDelete.mutate(selectedMenu.id, {
      onSuccess: () => {
        setShowConfirmationDialog(false)
      },
      onError: () => {
        setShowConfirmationDialog(false)
      },
    })
  }

  if (menu.isLoading || menuDelete.isLoading) {
    return <AppLoader />
  }
  if (menu.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }
  return (
    <>
      <CreateButtonFlexEnd
        handleClick={() => navigate(`/app/locations/${locationId}/menu/new`)}
        buttonType="menu"
      />
      {menu.isSuccess && !records?.length ? (
        <NoRecordFound title="menu" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_fulfilment_type' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_created_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((menuData, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => {
                        navigate(
                          `/app/locations/${locationId}/menus/${menuData.id}/`
                        )
                      }}
                    >
                      <TableCell>
                        {menuData.name?.length > 25 ? (
                          <Tooltip title={menuData.name}>
                            <Typography variant="body2">
                              {shortenText(menuData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          menuData.name
                        )}
                      </TableCell>
                      <TableCell>
                        {menuData.fulfillmentTypes
                          .slice(0, 3)
                          .map((data, index) => {
                            return (
                              <Fragment key={index}>
                                <Chip label={data} sx={{ margin: 0.3 }} />
                                {index === 2 &&
                                  menuData.fulfillmentTypes.length > 3 && (
                                    <Chip label="..." sx={{ margin: 0.3 }} />
                                  )}
                              </Fragment>
                            )
                          })}
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={menuData.createdAt} />
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <ClickableIcon
                              handleNavigate={() => {
                                navigate(
                                  `/app/locations/${locationId}/menu/${menuData.id}`
                                )
                              }}
                              children={<EditOutlined />}
                            />
                            <ClickableIcon
                              handleNavigate={() => [
                                setShowConfirmationDialog(true),
                                setSelectedMenu(menuData),
                              ]}
                              children={<DeleteOutlined />}
                            />
                          </Box>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          >
                            <Button
                              color="success"
                              sx={{ alignSelf: 'flex-end' }}
                              onClick={() => {
                                setSyncDialogId(menuData.id)
                              }}
                            >
                              {intl.formatMessage({ id: 'action_publish' })}
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {menu.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => menu.fetchNextPage()}
                disabled={menu.isFetching}
              >
                {menu.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </>
      )}
      <AppWarningDialog
        name={selectedMenu.name}
        source="menu"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
      {syncDialogId && (
        <MenuSyncDialog
          menuId={syncDialogId}
          onClose={handleOnClosePublishDialog}
        />
      )}
      {showWarningDialog && (
        <ItemsWarningDialog
          dataAfterPublish={dataAfterPublish}
          onCancelItemsWarning={onCancelItemsWarning}
        />
      )}
    </>
  )
}
