import { EditOutlined } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Button,
  Collapse,
} from '@mui/material'
import { FC, useState, useEffect, useMemo } from 'react'
import { useItemsUpdateWhileUpdateMenu } from '../../hooks'
import { ShowOnlineCategoryView } from './components/ShowOnlineview'
import { TruncateName } from './components/TruncateName'
import { ConfirmationDialog } from './dialogs/ConfirmationDialog'
import { Delete as RemoveIcon } from '@mui/icons-material'
import { MenuCategoryItems } from './menuCategoryItems'
import { useIntl } from 'react-intl'
import { StyledGridContainer } from './components'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface MenuCategoriesProps {
  locationId: string
  menuId: string
  onItemAdd: (categoryId: string) => any
  onEditItemById: (itemId: string) => any
  onDeleteCategoryById: (categoryId: string) => any
  onEditCategoryById: (categoryId: string) => any
  setSelectedCatId?: any
  categoryUpdateMutation: any
  selectedCatId?: string | null
  onDeleteItemById: (itemId: string) => any
  openCreateCategory: () => any
  openCatalogItems: () => any
  categoriesState: any
  setSelectedProduct: (val: any) => void
  setItemsState: (val: any) => void
  itemsState: any
  highlightStyle: (prod: any, index: any, type: any) => any
  showUndoSnackbar: any
  performDeletion: any
}
export const MenuCategories: FC<MenuCategoriesProps> = (props: any) => {
  const {
    locationId,
    menuId,
    onEditItemById,
    onItemAdd,
    onDeleteCategoryById,
    categoryUpdateMutation,
    setSelectedCatId,
    selectedCatId,
    onEditCategoryById,
    onDeleteItemById,
    openCreateCategory,
    categoriesState,
    setSelectedProduct,
    itemsState,
    highlightStyle,
    showUndoSnackbar,
    performDeletion,
    openCatalogItems,
  } = props
  const intl = useIntl()
  const itemUpdateMutation = useItemsUpdateWhileUpdateMenu(locationId!, menuId!)
  const [categoryUpdateState, setCategoryUpdateState] = useState<any>({})
  const [itemsNotUpdated, setItemsNotUpdated] = useState<boolean>(false)
  const [lastEditedCategoryId, setLastEditedCategoryId] = useState<
    string | null
  >(null)

  const sortedCategories = categoriesState?.sort(
    (a: any, b: any) => a.position - b.position
  )
  const [expandedCategoryId, setExpandedCategoryId] = useState<string | null>(
    null
  )
  useEffect(() => {
    const savedExpandedCategoryId = localStorage.getItem('expandedCategoryId')
    if (savedExpandedCategoryId) {
      setExpandedCategoryId(savedExpandedCategoryId)
    } else if (lastEditedCategoryId) {
      setExpandedCategoryId(lastEditedCategoryId)
      setLastEditedCategoryId(null)
    } else {
      setExpandedCategoryId(sortedCategories?.[0]?.id)
    }
  }, [sortedCategories])

  const [showConfirmDailog, setShowConfirmDailog] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const selectedCategoryItems = useMemo(() => {
    return itemsState?.filter((item: any) =>
      item?.categories?.includes(selectedCatId)
    )
  }, [selectedCatId, itemsState])

  useEffect(() => {
    if (isConfirmed) {
      selectedCategoryItems?.map((item: any) => {
        itemUpdateMutation.mutate(
          {
            itemData: { showOnline: categoryUpdateState.showOnline },
            itemId: item.id,
          },
          {
            onSuccess: () => {
              setShowConfirmDailog(false)
              setIsConfirmed(false)
              setSelectedCatId(null)
            },
          }
        )
      })
    }
  }, [isConfirmed])

  useEffect(() => {
    selectedCatId && categoryUpdateMutation.mutate(categoryUpdateState)
  }, [selectedCatId])
  const handleShowOnline = (showOnline: boolean, catId: string) => {
    setShowConfirmDailog(true)
    setSelectedCatId(catId)
    setCategoryUpdateState({ showOnline: showOnline })
  }

  const handleToggleCategory = (categoryId: any) => {
    setExpandedCategoryId(expandedCategoryId === categoryId ? null : categoryId)
  }

  const handleItemEdit = (item: any) => {
    setLastEditedCategoryId(item.categories?.[0])
    localStorage.setItem('expandedCategoryId', item.categories?.[0])
    onEditItemById(item.id)
  }

  return (
    <>
      <Box sx={{ pt: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            pb: 2,
          }}
        >
          <Button onClick={() => openCatalogItems()}>
            {intl.formatMessage({ id: 'action_choose_from_catalog' })}
          </Button>
          <Button onClick={() => openCreateCategory()}>
            {intl.formatMessage({ id: 'action_create_category' })}
          </Button>
        </Box>

        {sortedCategories?.map((category: any, index: any) => {
          return (
            <StyledGridContainer
              container
              key={category.id}
              pb={4}
              sx={highlightStyle(category, index, 'category')}
            >
              <Grid item xs={12} pb={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  onClick={() => handleToggleCategory(category.id)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="h6">
                      {TruncateName(category.name)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: 'flex' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ShowOnlineCategoryView
                      data={category}
                      handleShowOnline={handleShowOnline}
                      isLabel={true}
                    />
                    <Tooltip title="Edit Category">
                      <IconButton
                        onClick={() => onEditCategoryById(category.id)}
                      >
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Category">
                      <IconButton
                        onClick={() => {
                          onDeleteCategoryById(category.id)
                          setSelectedProduct(category)
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      onClick={() => handleToggleCategory(category.id)}
                    >
                      {expandedCategoryId === category.id ? (
                        <Tooltip title="Collapse">
                          <ExpandLessIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Expand">
                          <ExpandMoreIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Collapse
                in={expandedCategoryId === category.id}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%' }}
              >
                <MenuCategoryItems
                  category={category}
                  onItemEdit={(item: any) => handleItemEdit(item)}
                  onItemAdd={(categoryId) => onItemAdd(categoryId)}
                  items={itemsState}
                  onDeleteItem={(item: any) => onDeleteItemById(item.id)}
                  setSelectedProduct={(val) => setSelectedProduct(val)}
                  highlightStyle={highlightStyle}
                  showUndoSnackbar={showUndoSnackbar}
                  performDeletion={performDeletion}
                />
              </Collapse>
            </StyledGridContainer>
          )
        })}
      </Box>
      {showConfirmDailog && (
        <ConfirmationDialog
          open
          title="Confirmation"
          message="Would you like to change the showonline for all the items inside this category?"
          updateNewState={setIsConfirmed}
          setShowConfirmDailog={setShowConfirmDailog}
          setItemsNotUpdated={setItemsNotUpdated}
        />
      )}
    </>
  )
}
