import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getLocationQueryById,
  useCatalogCategory,
  useCategory,
  useCategoryById,
  useCategoryUpdate,
  useItemById,
  useMenuById,
  useMenuItems,
  useProducts,
} from '../../hooks'
// import { EditItemDialog } from './dialogs/EditItemMenuV2'
import { AddItemDialog } from './dialogs/AddItemDialog'
import { AddCategoryDialog } from './dialogs/AddCategoryDialog'
import { ChooseCatalogItemsDialog } from './dialogs/ChooseCatalogItemsDialog'

// Import the icons
import { DeleteConfirmationDialog } from './dialogs/DeleteCategoryDialog'
import { MenuHeader } from './menuHeader'
import { MenuCategories } from './menuCategory'
import {
  handleDeleteConfirmed,
  handleUndo,
  highlightedGridStyle,
  handleSnackbarClose,
  afterSnackBarClosed,
} from '../util'
import {
  AppLoader,
  ItemsWarningDialog,
  // MenuSwitch,
  MenuSyncDialog,
  PageNavigationView,
  UndoSnackbar,
} from '../../components'
import { Box } from '@mui/material'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * Menu Manager V2
 */
export const MenuV2: FC = () => {
  /**
   * Using the params, get the current lcoationId and menuId we are working with
   */
  const { locationId, menuId } = useParams()
  const navigate = useNavigate()
  const menu = useMenuById(locationId!, menuId!)

  //fetching the menu items and categories
  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })
  const items = useMenuItems(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void items.fetchNextPage()
      }
      if (data?.pages) {
        setItemsState(data.pages.map((page: any) => page.data).flat())
      }
    },
  })
  const itemsData = items.data?.pages.map((page) => page.data).flat()
  const categoriesData = categories.data?.pages
    .map((page: any) => page.data)
    .flat()
  const [itemsState, setItemsState] = useState<any>(itemsData)
  const [categoriesState, setCategoriesState] = useState<any>([])
  const [deletedIndex, setDeletedIndex] = useState(-1)
  const [deletedList, setDeletedList] = useState<any>([])
  const [deletedItem, setDeletedItem] = useState<any>()
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [productDelete, setProductDelete] = useState<any>()
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [itemError, setItemError] = useState(false)
  const [catError, setCatError] = useState(false)

  useEffect(() => {
    catError && void categories.refetch()
    itemError && void items.refetch()
  }, [catError, itemError])

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
  ]
  // fetching the catalog products and categories
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })
  const catalogCategories = useCatalogCategory(locationId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogCategories.fetchNextPage()
      }
    },
  })

  const [selectedCatId, setSelectedCatId] = useState<string | null>(null)
  const categoryUpdateMutation = useCategoryUpdate(
    locationId!,
    menuId!,
    selectedCatId!
  )

  /**
   * State for the dialogs
   */
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false)
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | undefined>()
  const [deleteItemId, setDeleteItemId] = useState<string | undefined>(
    undefined
  )
  const [editCategoryId, setEditCategoryId] = useState<string | undefined>()
  const [addItemToCategoryId, setAddItemToCategoryId] = useState<
    string | undefined
  >()
  const [selectedCategoryData, setSelectedCategoryData] =
    useState<any>(undefined)
  const [selectedItemData, setSelectedItemData] = useState<any>(undefined)
  const [syncDialogId, setSyncDialogId] = useState<string | undefined>(
    undefined
  )
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const getCategoryDetailsById = useCategoryById(
    locationId,
    menuId,
    editCategoryId
  ).data?.data
  const [editItemId, setEditItemId] = useState<string | undefined>(undefined)
  const [chooseCatalogItemsDialogOpen, setChooseCatalogItemsDialogOpen] =
    useState(false)
  const getItemDetailsById = useItemById(locationId!, menuId!, editItemId!, {
    onSuccess: (data) => {
      if (data?.data) {
        let existingData = data.data
        existingData['price'] = data.data?.price! / 100
        setSelectedItemData(existingData)
      }
    },
  })
  useEffect(() => {
    editItemId && void getItemDetailsById.refetch()
  }, [editItemId])
  useEffect(() => {
    if (editCategoryId) {
      setSelectedCategoryData({
        name: getCategoryDetailsById?.name,
        description: getCategoryDetailsById?.description,
        showOnline: getCategoryDetailsById?.showOnline,
      })
    }
  }, [getCategoryDetailsById])

  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 && setShowWarningDialog(true)
    setSyncDialogId(undefined)
  }
  const onCancelItemsWarning = () => {
    setShowWarningDialog(false)
    setDataAfterPublish(undefined)
  }
  const handleEditCategoryDialogClose = () => {
    setEditCategoryId(undefined)
    setSelectedCategoryData(undefined)
  }

  const [type, setType] = useState<'category' | 'item'>('item')
  const sortedCategories = categoriesData?.sort(
    (a: any, b: any) => a.position - b.position
  )
  const onDeleteConfirmation = () => {
    deleteCategoryId !== undefined
      ? setDeleteCategoryId(undefined)
      : setDeleteItemId(undefined) //to close the dialog box
    void handleDeleteConfirmed({
      setCategoriesState,
      categoriesState,
      setShowUndoSnackbar,
      deletedList,
      setDeletedList,
      setDeletedIndex,
      setDeletedItem,
      selectedProduct,
      itemsState,
      setItemsState,
      type,
    })
  }

  const onUndo = () => {
    handleUndo({
      setShowUndoSnackbar,
      setDeletedList,
      deletedList,
      deletedProduct: deletedItem,
      categoriesState,
      deletedIndex,
      setCategoriesState,
      setItemsState,
      itemsState,
      setHighlightedRow,
      type,
    })
  }
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  const highlightStyle = (product: any) => {
    return highlightedGridStyle(product, highlightedRow, deletedItem)
  }
  const performDeletion = (reason: any = 'external') => {
    handleSnackbarClose(reason, setShowUndoSnackbar)
    afterSnackBarClosed({
      setShowUndoSnackbar,
      deletedProduct: deletedItem,
      productDelete,
      type,
      setItemError: () => setItemError(!itemError),
      setCatError: () => setCatError(!catError),
    })
  }
  useEffect(() => {
    setCategoriesState(sortedCategories)
  }, [sortedCategories?.length, catError])
  /**
   * Show the loader
   */
  if (!menu.isSuccess || !categories.isSuccess || !items.isSuccess) {
    return null
  }
  if (menu.isLoading || categories.isLoading || items.isLoading) {
    return <AppLoader />
  }
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 50,
          zIndex: 999,
          borderRadius: '10px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          backgroundColor: '#ffffff',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageNavigationView navigationPages={navigationPages} />
          {/* <MenuSwitch version="v2" /> */}
        </Box>
        {/* Add Category Dialog */}
        {(addCategoryDialogOpen || editCategoryId) && (
          <AddCategoryDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            onClose={() =>
              editCategoryId
                ? handleEditCategoryDialogClose()
                : setAddCategoryDialogOpen(false)
            }
            items={items}
            selectedCategoryData={selectedCategoryData}
            setSelectedCategoryData={setSelectedCategoryData}
            selectedCatId={editCategoryId}
            setCategoriesState={setCategoriesState}
            categoriesState={categoriesState}
            setEditCategoryId={setEditCategoryId}
            setSelectedCatId={setSelectedCatId}
          />
        )}
        {/* Choose Items from Catalog Dialog */}
        {chooseCatalogItemsDialogOpen && (
          <ChooseCatalogItemsDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={addItemToCategoryId!}
            onClose={() => setChooseCatalogItemsDialogOpen(false)}
            products={catalogProducts}
            categories={catalogCategories}
          />
        )}
        {/* Delete Category Dialog */}
        {(deleteCategoryId !== undefined || deleteItemId !== undefined) && (
          <DeleteConfirmationDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={deleteCategoryId! || deleteItemId!}
            onClose={() =>
              deleteCategoryId !== undefined
                ? setDeleteCategoryId(undefined)
                : setDeleteItemId(undefined)
            }
            type={deleteCategoryId !== undefined ? 'category' : 'item'}
            setProductDelete={(val) => setProductDelete(val)}
            onDeleteConfirmation={() => onDeleteConfirmation()}
          />
        )}
        {/* Add Item Dialog */}
        {addItemToCategoryId !== undefined && (
          <AddItemDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={addItemToCategoryId!}
            onClose={() => setAddItemToCategoryId(undefined)}
            products={catalogProducts}
            categories={catalogCategories}
          />
        )}
        {/* Edit Item Dialog */}
        {/* {console.log(selectedCatItemData, 'catalogProductData')}
        {editItemId !== undefined && (
          <EditItemDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            itemId={editItemId! || undefined}
            onClose={() => {
              editItemId
                ? setEditItemId(undefined)
                : setAddItemToCategoryId(undefined)
              setSelectedItemData(undefined)
            }}
            itemData={selectedItemData || undefined}
            catalogProductData={selectedCatItemData || undefined}
          />
        )} */}
        {/* Header */}
        <MenuHeader
          locationId={locationId!}
          menuId={menuId!}
          onClickPublish={() => setSyncDialogId(menuId!)}
        />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {/* Categories and Items */}
        <MenuCategories
          locationId={locationId!}
          menuId={menuId!}
          onDeleteCategoryById={(categoryId) => {
            setType('category')
            setDeleteCategoryId(categoryId)
          }}
          onEditCategoryById={(categoryId) => setEditCategoryId(categoryId)}
          onEditItemById={(itemId) => {
            setEditItemId(itemId)
            navigate(`items/${itemId}/edit`)
          }}
          onItemAdd={(categoryId) => setAddItemToCategoryId(categoryId)}
          setSelectedCatId={setSelectedCatId}
          categoryUpdateMutation={categoryUpdateMutation}
          selectedCatId={selectedCatId}
          openCreateCategory={() => setAddCategoryDialogOpen(true)}
          onDeleteItemById={(itemId) => {
            setType('item')
            setDeleteItemId(itemId)
          }}
          categoriesState={categoriesState}
          setSelectedProduct={(val) => setSelectedProduct(val)}
          setItemsState={(val) => setItemsState(val)}
          itemsState={itemsState}
          highlightStyle={highlightStyle}
          showUndoSnackbar={showUndoSnackbar}
          performDeletion={performDeletion}
          openCatalogItems={() => setChooseCatalogItemsDialogOpen(true)}
        />
        {syncDialogId && (
          <MenuSyncDialog
            menuId={syncDialogId}
            onClose={handleOnClosePublishDialog}
          />
        )}
        {showWarningDialog && (
          <ItemsWarningDialog
            dataAfterPublish={dataAfterPublish}
            onCancelItemsWarning={onCancelItemsWarning}
          />
        )}
        <UndoSnackbar
          {...{
            showUndoSnackbar,
            setShowUndoSnackbar: (val: any) => setShowUndoSnackbar(val),
            deletedProduct: deletedItem,
            onUndo: () => onUndo(),
            performDeletion,
          }}
        />
      </Box>
    </>
  )
}

// const MenuCategoryItems: FC<{
//   categories: any[]
//   onItemEdit: (item: any) => void
//   onItemAdd: (categoryId: string) => void
// }> = ({ categories, onItemEdit, onItemAdd }) => {
//   const onDragEnd = (result: any) => {
//     // Check if the item was dropped outside the droppable area
//     if (!result.destination) return

//     // Reorder the categories based on the drag and drop result
//     const reorderedCategories = Array.from(categories)
//     const [movedCategory] = reorderedCategories.splice(result.source.index, 1)
//     reorderedCategories.splice(result.destination.index, 0, movedCategory)

//     // Update the position key for categories based on their new order
//     reorderedCategories.forEach((category, index) => {
//       category.position = index + 1 // Assuming position starts from 1
//     })

//     // Update the state with the new order of categories
//     setCategories(reorderedCategories)
//   }

//   return (
//     <DragDropContext onDragEnd={onDragEnd}>
//       <Droppable droppableId="categories" direction="vertical">
//         {(provided) => (
//           <div ref={provided.innerRef} {...provided.droppableProps}>
//             {categories.map((category, index) => (
//               <Draggable
//                 key={category.id}
//                 draggableId={category.id}
//                 index={index}
//               >
//                 {(provided) => (
//                   <div
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                   >
//                     <ItemCard onClick={() => props.onItemEdit(item)}>
//                       <Box
//                         sx={{
//                           display: 'flex',
//                           flexDirection: 'column',
//                         }}
//                       >
//                         <ItemCardMedia
//                           image={
//                             item.imageUrl ?? 'https://placehold.co/600x400'
//                           }
//                           title={item.name}
//                         />
//                       </Box>
//                       <ItemCardContent>
//                         <Typography variant="subtitle1">
//                           {truncatedDataName}
//                         </Typography>
//                         <Typography variant="subtitle2" flexWrap={'wrap'}>
//                           {item.description?.substring(0, 120)}
//                         </Typography>
//                         <FormattedNumber
//                           value={item.price! / 1000}
//                           style="currency"
//                           currency="USD"
//                         />
//                       </ItemCardContent>
//                     </ItemCard>
//                   </div>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </DragDropContext>
//   )
// }

// const MenuCategories: FC<{
//   categories: any[]
//   onItemEdit: (item: any) => void
//   onItemAdd: (categoryId: string) => void
// }> = ({ categories, onItemEdit, onItemAdd }) => {
//   return (
//     <Box sx={{ pt: 5 }}>
//       {categories.map((category) => (
//         <Grid container key={category.id}>
//           {/* Render category header */}
//           <Typography variant="h4">{category.name}</Typography>

//           {/* Render category items */}
//           <MenuCategoryItems
//             category={category}
//             onItemEdit={onItemEdit}
//             onItemAdd={onItemAdd}
//           />
//         </Grid>
//       ))}
//     </Box>
//   )
// }
