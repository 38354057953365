import { useEffect, useCallback, useState, useRef } from 'react'

/**
 * Debounce a value
 * @param value The value to debounce
 * @param milliSeconds The number of milliseconds to debounce the value
 * @returns The debounced value
 */
export const useDebounce = (value: any, milliSeconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), milliSeconds)
    return () => clearTimeout(timer)
  }, [value, milliSeconds])

  return debouncedValue
}

/**
 * Debounce a callback
 * @param callback - The callback to debounce
 * @param milliSeconds - The number of milliseconds to debounce the callback
 * @returns The debounced callback
 */
export const useDebouncedCallback = (
  callback: (...args: any[]) => void,
  milliSeconds: number
) => {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<NodeJS.Timeout>()

  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current)
        callback(...args)
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(later, milliSeconds)
    },
    [callback, milliSeconds]
  )
}
