import { FC, useState } from 'react'
import { useResellerUsers } from '../../../hooks/useResellerUsers'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLoader, UsersTable } from '../../../components'
import { ResellerSearch } from '../ResellerSearch'
import { useQueryString } from '../../../hooks/useQueryString'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export const ResellerUsers: FC = () => {
  const { resellerId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL] = useQueryString(window.location, navigate)
  const [filters, setFilters] = useState(queryParamURL as any)
  const users = useResellerUsers(
    { resellerId: resellerId!, filters: filters! },
    { refetchOnMount: 'always' }
  )

  let breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Users', path: `/app/resellers/${resellerId}/users` },
  ]

  if (users.isLoading) {
    return <AppLoader />
  }

  return (
    <PageContainer
      title="Users"
      breadcrumbs={breadcrumbs}
      toolbar={
        <ResellerSearch setFilters={setFilters} isLoading={users.isFetching} />
      }
    >
      <UsersTable users={users} filters={filters} />
    </PageContainer>
  )
}
