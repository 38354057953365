import { FC } from 'react'
import { QueryPicker, QueryPickerProps } from '../QueryPicker'
import { Avatar, Box, Checkbox, TextField, Typography } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

export interface ApplicationPickerProps
  extends QueryPickerProps<any, boolean, boolean, boolean> {}

/**
 * Location picker component
 * @returns
 */
export const ApplicationPicker: FC<ApplicationPickerProps> = (props) => {
  return (
    <QueryPicker
      noOptionsText={'No applications found'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      {...props}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0 }} key={option.id}>
          <Checkbox
            size="small"
            icon={
              <Avatar
                sx={{ width: 24, height: 24, mr: 1 }}
                src={option.image}
              />
            }
            checkedIcon={
              <Avatar
                src={option.image}
                // Add green circle around the avatar
                sx={{
                  width: 24,
                  height: 24,
                  mr: 1,
                  backgroundColor: 'green',
                  borderRadius: '50%',
                  border: '2px solid white',
                }}
              />
            }
            sx={{ marginRight: 0, mb: 1 }}
            checked={selected}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">{option.name}</Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={'Select applications'} />
      )}
    />
  )
}
