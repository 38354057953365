import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { toast } from 'react-toastify'
import { isValidString } from '../pages/util'

export interface UseOrdersOptions {
  filters?: {
    query?: string
    status?: string
    fulfillmentTypes?: string
    locationIds?: string[]
    locationNames?: string[]
    id: any
  }
}

export const useAccountOrders = (
  props: UseOrdersOptions,
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { accountId } = useAuthStore()
  const baseKey = ['accounts', accountId, 'orders', props?.filters]
  if (props.filters && props.filters.query) {
    baseKey.push(props.filters.query)
  }
  /**
   *
   */
  return useInfiniteQuery(
    baseKey,
    (ctx) =>
      api
        .get(`/v1/accounts/${accountId}/orders`, {
          params: {
            ...props.filters,
            nextPageKey: ctx.pageParam,
            // ...(props.filters?.query && { query: props.filters?.query }),
            // ...(isValidString(props.filters?.status) && {
            //   status: props.filters?.status,
            // }),
            // ...(isValidString(props.filters?.fulfillmentTypes) && {
            //   fulfillmentType: props.filters?.fulfillmentTypes,
            // }),
            // ...(props.filters?.locationIds && {
            //   locationIds: props.filters?.locationIds,
            // }),
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageKey
      },
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!token?.access_token,
    }
  )
}

export const useOrderAudit = (
  props: { orderId: any },
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const { orderId } = props
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const key = ['accounts', accountId, 'orders', orderId, 'audits']

  return useInfiniteQuery(
    key,
    ({ signal, pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/orders/${orderId}/audits`,

          {
            params: { nextPageKey: pageParam },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      ...options,
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!orderId,
    }
  )
}

export const useOrderDeliveries = (
  props: { orderId: string; locationId: string },
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const { orderId, locationId } = props
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const key = [
    'accounts',
    accountId,
    'orders',
    'locations',
    locationId,
    orderId,
    'deliveries',
  ]

  return useInfiniteQuery(
    key,
    ({ signal, pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/orders/${orderId}/deliveries`,
          {
            params: { nextPageKey: pageParam },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      ...options,
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!orderId,
    }
  )
}

export const useOrderById = (
  props: { orderId: any },
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { orderId } = props
  const { accountId: accId } = useAuthStore()
  const key = ['accounts', accId, 'orders', orderId]
  const path = `/v1/accounts/${accId}/orders/${orderId}`

  return useInfiniteQuery(
    key,
    (ctx) =>
      api
        .get(path, {
          params: { nextPageKey: ctx.pageParam },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      keepPreviousData: true,
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accId && !!orderId,
    }
  )
}

export const useOrderByIdNew = (
  props: { orderId: any },
  options: Omit<UseQueryOptions<any, Error, any, any>, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()
  const { orderId } = props
  const { accountId: accId } = useAuthStore()
  const key = ['accounts', accId, 'orders', orderId]
  const path = `/v1/accounts/${accId}/orders/${orderId}`

  return useQuery(
    key,
    (ctx) =>
      api
        .get(path, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      keepPreviousData: true,
      staleTime: 10 * 1000,
      enabled: !!token?.access_token && !!accId && !!orderId,
    }
  )
}

export const useOrderStatusMutation = (status: string, locationId: any) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  const { accountId } = useAuthStore()

  return useMutation(
    (orderId: string) =>
      api
        .patch(
          `/v1/accounts/${accountId}/locations/${locationId}/orders/${orderId}`,
          { status },
          { headers: { Authorization: `Bearer ${token?.access_token}` } }
        )
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        void queryClient.refetchQueries([
          'accounts',
          data.data.accountId,
          'locations',
          data.data.locationId,
          'orders',
        ]),
          toast.success('Completed')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
