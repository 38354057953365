import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLoader, Column, GenericTable } from '../../../components'
import { useResellerLocations } from '../../../hooks/useResellerLocations'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { useQueryString } from '../../../hooks/useQueryString'
import { ResellerSearch } from '../ResellerSearch'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component(row) {
      return <Typography variant="caption">{row?.name}</Typography>
    },
  },
  {
    id: 'address',
    label: 'Address',
    component(row) {
      return <Typography variant="caption">{row?.address?.address}</Typography>
    },
  },
  {
    id: 'timezone',
    label: 'Timezone',
    component: (row) => (
      <Typography variant="caption">{row?.timezone}</Typography>
    ),
  },
]

export const ResellerLocations: FC = () => {
  const { resellerId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL] = useQueryString(window.location, navigate)
  const [filters, setFilters] = useState(queryParamURL as any)
  const locations = useResellerLocations(
    { resellerId: resellerId!, filters: filters! },
    { refetchOnMount: 'always' }
  )
  let breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Locations', path: `/app/resellers/${resellerId}/locations` },
  ]
  return (
    <>
      <PageContainer
        title="Locations"
        breadcrumbs={breadcrumbs}
        toolbar={
          <ResellerSearch
            setFilters={setFilters}
            isLoading={locations.isLoading}
          />
        }
      >
        {locations.isLoading ? (
          <AppLoader />
        ) : (
          <GenericTable
            columns={columns}
            query={locations}
            noDataMessage="No accounts found"
            enableDelete={false}
            tableType="location"
            filters={filters}
            // handleDelete={handleDeleteClick}
          />
        )}
      </PageContainer>
    </>
  )
}
