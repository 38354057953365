import { FC } from 'react'
import { Grid, Box, Typography, Chip, Divider } from '@mui/material'
import { UseMutationResult, UseQueryResult } from 'react-query/types'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { ImageUpload } from '../../../components'

export interface UserApplicationOverviewProps {
  application: UseQueryResult<any, unknown>
  imageUploadMutation: UseMutationResult<any, unknown, { file: File }, unknown>
}

export const UserApplicationOverview: FC<UserApplicationOverviewProps> = ({
  application,
  imageUploadMutation,
}) => {
  /**
   * Generate teh authotize url based on the pauth {application.data} details
   */
  const searchParams = new URLSearchParams()
  searchParams.set('client_id', application.data.id)
  searchParams.set('response_type', 'code')
  searchParams.set('scope', application.data.scopes?.join(' '))
  searchParams.set('redirect_uri', application.data.redirectUris[0])
  const authorizeUrl = `${API_ENDPOINT}/oauth2/authorize?${searchParams.toString()}`

  /**
   * Handle Image Upload
   */
  const onImageChange = (image: File | null) => {
    console.log('Image Changed:', image)
    image ? imageUploadMutation.mutate({ file: image }) : null
  }

  return (
    <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
      {/* TOP: Logo + App Info + Details */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* LEFT: Logo */}
          <Grid item xs={12} md={2}>
            <ImageUpload
              onImageChange={onImageChange}
              height="150px"
              existingImage={
                application.data.imageUrl ?? application.data.image
              }
            />
            {/* <img src={application.data.image} style={{ width: '100%', height: 'auto' }} /> */}
          </Grid>

          {/* RIGHT: Name, Category, Description, then Details */}
          <Grid item xs={12} md={10}>
            {/* App Name, Category, Description */}
            <Typography variant="h5">{application.data.name}</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {application.data.description}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Box
              component="table"
              sx={{
                width: '100%',
                borderCollapse: 'collapse',
                'td, th': { py: 0.5, verticalAlign: 'top' },
              }}
            >
              <Box component="tbody">
                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Client Type
                  </Box>
                  <Box component="td">{application.data.category}</Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Client ID
                  </Box>
                  <Box component="td">
                    <CopyToClipboard value={application.data.id}>
                      {application.data.id}
                    </CopyToClipboard>
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Client Secret
                  </Box>
                  <Box component="td">
                    <CopyToClipboard value={application.data.secret}>
                      {application.data.secret}
                    </CopyToClipboard>
                  </Box>
                </Box>

                {/* Allowed Grants */}
                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Allowed Grants
                  </Box>
                  <Box component="td">
                    {application.data.allowedGrants.map((grant: string) => (
                      <Chip key={grant} label={grant} sx={{ mr: 1 }} />
                    ))}
                  </Box>
                </Box>

                {/* Scopes */}
                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Allowed Scopes
                  </Box>
                  <Box component="td">
                    {application.data.scopes.map((scope: string) => (
                      <Chip key={scope} label={scope} sx={{ mr: 1 }} />
                    ))}
                  </Box>
                </Box>

                {/* Redirect URIs */}
                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Redirect URIs
                  </Box>
                  <Box component="td">
                    {application.data.redirectUris?.map((uri: string) => (
                      <CopyToClipboard value={uri} key={uri}>
                        {uri}
                      </CopyToClipboard>
                    ))}
                  </Box>
                </Box>

                {/* Delivery Callbacks */}
                {application.data.category === 'DELIVERY' && (
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                      Get Quote
                    </Box>
                    <Box component="td">
                      {application.data.getDeliveryQuoteUrl}
                    </Box>
                  </Box>
                )}

                {/* Authorize URL */}
                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Authorize URL
                  </Box>
                  <Box component="td">
                    <CopyToClipboard value={authorizeUrl}>
                      Authorize URL
                    </CopyToClipboard>
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={{ pr: 2, fontWeight: 'bold' }}>
                    Created At
                  </Box>
                  <Box component="td">
                    {new Date(application.data.createdAt).toLocaleString()}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
