import { useNavigate } from 'react-router-dom'
import { FC, useState } from 'react'
import { IconButton } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import {
  useProfile,
  useUserApplicationCreateMutation,
  useUserApplications,
} from '../../hooks'
import { PageContainer } from '../../components/Page/PageContainer'
import { UserApplicationsTable } from './components/UserApplicationTable'
import { UserApplicationCreateDialog } from './components/UserApplicationCreateDialog'

export const DeveloperApplications: FC = () => {
  const navigate = useNavigate()
  const profile = useProfile()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const apps = useUserApplications()
  const createApplication = useUserApplicationCreateMutation()

  const onRowClick = (application: any) => {
    navigate(application.id)
  }

  const toolbar = (
    <IconButton
      onClick={() => setCreateDialogOpen(true)}
      children={<AddOutlined />}
    />
  )

  return (
    <>
      {createDialogOpen && (
        <UserApplicationCreateDialog
          profile={profile}
          createApplication={createApplication}
          open
          onClose={() => setCreateDialogOpen(false)}
        />
      )}
      <PageContainer title="My Applications" toolbar={toolbar}>
        <UserApplicationsTable applications={apps} onRowClick={onRowClick} />
      </PageContainer>
    </>
  )
}
