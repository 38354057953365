import {
  Paper,
  Box,
  Grid,
  Button,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppLoader } from '../../components'
import { useProfile } from '../../hooks'
import { useIntl } from 'react-intl'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'

export function Profile() {
  const navigate = useNavigate()
  const intl = useIntl()
  const profile = useProfile({ refetchOnMount: 'always' })
  const handleUpdate = () => {
    navigate('edit')
  }
  if (profile.isLoading) {
    return <AppLoader />
  }

  const breadcrumbs: Breadcrumb[] = [
    { title: 'My Account', path: '/app/dashboard' },
    { title: 'Profile', path: '/app/profile/me' },
  ]

  return (
    <PageContainer
      title={intl.formatMessage({ id: 'label_profile' })}
      breadcrumbs={breadcrumbs}
      toolbar={
        <Button onClick={handleUpdate}>
          {intl.formatMessage({ id: 'action_edit' })}
        </Button>
      }
    >
      {profile?.data && (
        <Paper>
          <Box sx={{ paddingX: 3, paddingY: 2 }}>
            <Box>
              <List>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_full_name' })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {profile?.data?.data?.firstName} {''}
                      {profile?.data?.data?.lastName}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_email' })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {profile?.data?.data?.email}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_language' })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {profile?.data?.data?.language}
                    </Typography>
                  </Typography>
                </ListItemText>
              </List>
            </Box>
          </Box>
        </Paper>
      )}
    </PageContainer>
  )
}
