import { FC } from 'react'

import { UseQueryResult } from 'react-query/types'
import { AuditsTable } from '../../../components/AuditsTable'
import { useUserApplicationAudits } from '../../../hooks'

export interface UserApplicationAuditsProps {
  application: UseQueryResult<any, unknown>
}

export const UserApplicationAudits: FC<UserApplicationAuditsProps> = ({
  application,
}) => {
  const audits = useUserApplicationAudits(application.data.id)
  return <AuditsTable audits={audits} />
}
